import { ordersAPI } from "../../apis/orders";

const initialState = {
  loading: false,
  success: false,
  loaded: false,
  error: false,
  order: null,
};

const loading = "orders/addOrder/loading";
const loaded = "orders/addOrder/loaded";
const error = "orders/addOrder/error";
const clean = "orders/addOrder/cleanUp";

export const submitOrder = (orderValues) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const getFormData = (values) => {
      const formData = new FormData();

      const valuesKeys = Object.keys(values).filter((item) => item !== "files");
      valuesKeys.forEach((key) => formData.append(key, values[key]));

      const files = values.files;

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      }
      return formData;
    };
    const data = getFormData(orderValues);

    const response = await ordersAPI.post("/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({
      type: loaded,
      payload: { order: response.data.data.order },
    });
  } catch (err) {
    dispatch({
      type: error,
      payload: err,
    });
  }
};

export const cleanUp = () => (dispatch) => {
  dispatch({ type: clean });
};

const addOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, loading: true };
    case loaded:
      return {
        ...state,
        success: true,
        loaded: true,
        loading: false,
        order: action.payload.order,
      };
    case error:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case clean:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default addOrderReducer;
