import React from "react";

import ForgotForm from "./Form";

import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export const Forgot = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper
        sx={(theme) => ({
          padding: theme.spacing(4),
          marginBottom: "1em",
          marginTop: theme.spacing(2),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Avatar
          sx={(theme) => ({
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
          })}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h4" component="h1">
          {"Probleme bei der Anmeldung?"}
        </Typography>
        <Typography variant="body1">
          {
            "Geben Sie bitte Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link, mit dem Sie sich wieder in Ihr Konto zu gelangen."
          }
        </Typography>
        <ForgotForm />
      </Paper>
    </Container>
  );
};

export default Forgot;
