import React from "react";
import { Field, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { changePass, cleanUp } from "../../../redux/ducks/accountSettings";

// import Link from "../../shared/Link";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { openSnack } from "../../../redux/ducks/snackbar";

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    sx={{ width: "100%", maxWidth: "30ch", mb: "8px" }}
    type="password"
    label={label}
    placeholder={label}
    error={!!touched && !!invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const ChangePassForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { loading, loaded, error } = useSelector(
    (s) => s.accountSettings.changePass
  );
  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp("cp"));
    }
  }, [dispatch, error]);
  React.useEffect(() => {
    if (!!loaded) {
      dispatch(openSnack("Passwort wurde erfolgreich geändert"));
      dispatch(cleanUp("cp"));
    }
  }, [dispatch, loaded]);
  return (
    <form
      onSubmit={handleSubmit((values) => {
        dispatch(changePass(values));
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          //   justifyContent: "center",
          alignItems: "center",
          mb: "32px",
          mt: "32px",
        }}
      >
        <Field
          name="oldPass"
          component={renderTextField}
          label="Altes Passwort"
          variant="outlined"
          placeholder="Altes Passwort"
          autoComplete="current-password"
        />
        <Field
          name="newPass"
          component={renderTextField}
          label="Neues Passwort"
          variant="outlined"
          placeholder="Neues Passwort"
          autoComplete="new-password"
        />
        <Field
          name="newPassConfirm"
          component={renderTextField}
          label="Neues Passwort Wiederholung"
          variant="outlined"
          placeholder="Wiederholung des neuen Passworts"
          autoComplete="new-password"
        />
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            // md={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button variant="contained" type="submit" disabled={loading}>
                Passwort aktualisieren
              </Button>
            </Grid>
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Link to={"/forgot"}>Ich habe mein Passwort vergessen</Link>
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["oldPass", "newPass", "newPassConfirm"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld";
    }
  });

  if (values.newPassConfirm !== values.newPass) {
    errors.newPassConfirm = "Passwort stimmt nicht überein";
  }

  if (!values.newPass?.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)) {
    errors.newPass =
      "Ihr Passwort muss 6-20 Zeichen, mindestens einen Großbuchstaben, mindestens einen Kleinbuchstaben, und mindestens eine Ziffer enthalten.";
  }
  return errors;
};

export default reduxForm({
  form: "ChangePassForm", // a unique identifier for this form
  validate,
})(ChangePassForm);
