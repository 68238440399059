import React from "react";
import Typography from "@mui/material/Typography";

function SubArticle({ title, children }) {
  return (
    <React.Fragment>
      <Typography variant="h5" component="h3" sx={{ mt: 5 }}>
        {title}
      </Typography>
      {children}
    </React.Fragment>
  );
}

export default SubArticle;
