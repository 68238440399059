import { contactAPI } from "../../apis/contact";
import { reset } from "redux-form";

const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

const loading = "contact/loading";
const loaded = "contact/loaded";
const error = "contact/error";
const clean = "contact/cleanUp";

export const sendContactReq = (values) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await contactAPI.post("/", values);
    if (response.data.status === "success") {
      dispatch({ type: loaded });
      dispatch(reset("ContactForm"));
    } else {
      dispatch({ type: error, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: error, payload: e.response?.data || e });
  }
};

export const cleanUp = () => {
  return { type: clean };
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case loaded:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };

    case clean:
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export default contactReducer;
