import React from "react";
import SubArticle from "./SubArticle";
import Typography from "@mui/material/Typography";

function B1() {
  return (
    <SubArticle title="(1) Erläuterung der Funktion">
      <Typography>
        Informationen zu unseren Unternehmen und den von uns angebotenen
        Leistungen erhalten Sie insbesondere unter https://www.order-scout.com
        samt den dazugehörigen Unterseiten (nachfolgend gemeinsam: "Webseiten").
        Bei einem Besuch unserer Webseiten können personenbezogene Daten von
        Ihnen verarbeitet werden.
      </Typography>
    </SubArticle>
  );
}

export default B1;
