const initialState = {
  open: false,
  accepted: false,
};
const open = "cookieDialog/open";
const close = "cookieDialog/close";
const accept = "cookieDialog/accept";

export const openCookieDialog = () => {
  return { type: open };
};
export const closeCookieDialog = () => {
  return { type: close };
};
export const acceptCookies = () => {
  return { type: accept };
};

const cookieConsentDialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case open:
      return {
        ...state,
        open: true,
      };
    case close:
      return {
        ...state,
        open: false,
      };
    case accept:
      return {
        ...state,
        open: false,
        accepted: true,
      };
    default:
      return state;
  }
};

export default cookieConsentDialogReducer;
