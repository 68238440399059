import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import CompanyContact from "./CompanyContact";
import OfferDetails from "./OfferDetails";
import OrderSummary from "./OrderSummary";
import { getOffer } from "../../../redux/ducks/offerView";

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: "nowrap",
  },
  row: {
    padding: theme.spacing(1),
  },
  loadingDiv: {
    minHeight: "500px",
    marginTop: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

const OfferView = () => {
  let { id: offerId } = useParams();
  let isReceiver = null;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { offer, loaded, loading, error } = useSelector(
    (state) => state.offerView
  );

  const company = useSelector((state) => state.auth.company._id);
  if (offer) {
    if (company === offer.receivingCompany._id) {
      isReceiver = true;
    } else {
      isReceiver = false;
    }
  }
  React.useEffect(() => {
    dispatch(getOffer(offerId));
  }, [dispatch]);

  return (    
    <div>        
    {loading && !offer &&(
      <div className={classes.loadingDiv}>
        <CircularProgress />
      </div>
    )}
    {error && (
      <div className={classes.loadingDiv}>
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
      </div>
    )}
    {loaded && offer && (
      <Grid container direction="column" className={classes.container}>
      <Grid item className={classes.row}>
        <OrderSummary offer={offer} />
      </Grid>
      <Grid item className={classes.row} container>
        <Grid xl={4} lg={6} xs={12} item style={{ padding: "10px" }}>
          <CompanyContact offer={offer} isReceiver={isReceiver} />
        </Grid>
        <Grid xl={8} lg={6} xs={12} item style={{ padding: "10px" }}>
          <OfferDetails offer={offer} />
        </Grid>
      </Grid>
    </Grid>
    )}
  </div>    
  );
};

export default OfferView;
