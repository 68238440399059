import { usersAPI } from "../../apis/users";
import socket from "../../helpers/socket";

const initialState = {
  loading: false,
  loaded: false,
  // success: false,
  error: null,
  invalid: false,
};

const loading = "users/login/loading";
const loaded = "users/login/loaded";
const authLogin = "auth/login";
const error = "users/login/error";

export const login = (values) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await usersAPI.post("/login", values);
    const user = response.data.data.user;
    const userID = user._id;
    const company = user.company;
    const isVerified = user.isVerified;
    const subscription = user.subscription;
    dispatch({
      type: loaded,
    });
    localStorage.setItem("user", userID);
    localStorage.setItem("company", company._id);
    socket.connect();
    dispatch({
      type: authLogin,
      payload: { userID, company, isVerified, subscription },
    });
  } catch (e) {
    dispatch({
      type: error,
      payload: e.response?.data || e,
    });
  }
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, loading: true, loaded: false, error: null };
    case loaded:
      return {
        ...state,
        // success: true,
        loaded: true,
        loading: false,
        error: null,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        // success: false,
      };
    // case "users/login/error/invalid":
    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     error: action.payload,
    //     // invalid: true,
    //     // success: false,
    //   };
    // case "auth/logout":
    //   return {
    //     ...state,
    //     ...initialState,
    //   };
    default:
      return state;
  }
};

export default loginReducer;
