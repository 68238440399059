import React from "react";
import SubArticle from "./SubArticle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const b2List = [
  "der Seite, von der aus die Seite angefordert wurde (sog. Referrer-URL)",
  "dem Name und URL der angeforderten Seite",
  "dem Datum und der Uhrzeit des Aufrufs",
  "der Beschreibung des Typs, Sprache und Version des verwendeten Webbrowsers",
  "der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar ist",
  "der übertragenen Datenmenge",
  "dem Betriebssystem",
  "der Meldung, ob der Aufruf erfolgreich war (Zugriffsstatus/Http-Statuscode)",
  "der GMT-Zeitzonendifferenz",
];

const b2List2 = [
  "die Seite, von der aus die Seite angefordert wurde (sog. Referrer-URL)",
  "das Datum und die Uhrzeit des Aufrufs",
  "die Beschreibung des Typs des verwendeten Webbrowsers",
  "die IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar ist",
  "die E-Mail-Adresse",
  "das Datum und die Uhrzeit der Anmeldung und Bestätigung",
];

function A3() {
  return (
    <SubArticle title="(2) Verarbeitete personenbezogene Daten">
      <Typography>
        Bei der informatorischen Nutzung der Webseiten werden die folgenden
        Kategorien personenbezogener Daten von uns erhoben, gespeichert und
        weiterverarbeitet: "Protokolldaten": Wenn Sie unsere Webseiten besuchen,
        wird auf unserem Webserver temporär und anonymisiert ein sogenannter
        Protokolldatensatz (sog. Server-Logfiles) gespeichert. Dieser besteht
        aus:
      </Typography>
      <List>
        {b2List.map((article, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText>{article}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Typography>
        "Kontaktformulardaten": Bei Nutzung von Kontaktformularen werden die
        dadurch übermittelten Daten verarbeitet (z.B. Geschlecht, Name und
        Vorname, Anschrift, Firma, E-Mail-Adresse und der Zeitpunkt der
        Übermittlung). Neben der rein informatorischen Nutzung unserer Webseite
        bieten wir das Abonnement unseres Newsletters an, mit dem wir Sie über
        aktuelle Entwicklungen im Wirtschaftsrecht und Veranstaltungen
        informieren. Wenn Sie sich für unseren Newsletter anmelden, werden die
        folgenden "Newsletterdaten" von uns erhoben, gespeichert und
        weiterverarbeitet:
      </Typography>
      <List>
        {b2List2.map((article, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText>{article}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Typography>
        Wir weisen Sie darauf hin, dass wir bei Versand des Newsletters Ihr
        Nutzerverhalten auswerten. Für diese Auswertung beinhalten die
        versendeten E-Mails sogenannte Web-Beacons bzw. Tracking-Pixel, die
        Ein-PixelBilddateien darstellen, die auf unserer Website gespeichert
        sind. Für die Auswertungen verknüpfen wir die vorstehend genannten Daten
        und die Web-Beacons mit Ihrer E-Mail-Adresse und einer individuellen ID.
        Auch im Newsletter enthaltene Links enthalten diese ID. Die Daten werden
        ausschließlich pseudonymisiert erhoben, dh. die IDs werden also nicht
        mit Ihren weiteren persönlichen Daten verknüpft, eine direkte
        Personenbeziehbarkeit wird ausgeschlossen.
      </Typography>
    </SubArticle>
  );
}

export default A3;
