import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Root = styled(Typography)(({ theme }) => ({
  marginBottom: "3rem",
  marginLeft: "2rem",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "1rem",
  },
}));

function PageTitle(props) {
  return (
    <Root variant="h3" component="h1">
      {props.text}
    </Root>
  );
}

export default PageTitle;
