import React from "react";
// MUI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
  wrapper: {
    justifyContent: "space-around",
  },
  h2: {
    fontSize: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
  },
}));
function Section2({ id, manufacturer }) {
  const classes = useStyles();
  return (
    <section className={classes.root} id={id}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Typography variant="h2" color="primary">
              {!manufacturer
                ? "CNC-Aufträge Online Ausschreiben"
                : "CNC Aufträge online finden"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            container
            direction="column"
            className={classes.wrapper}
            spacing={2}
          >
            <Grid item>
              <Typography>
                {!manufacturer
                  ? "Digitalisieren Sie Ihren CNC-Einkaufsprozess von Ausschreibung bis Bestellung"
                  : "Sie wollen mit wenigen Klicks passende CNC-Aufträge finden?"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {!manufacturer
                  ? "Profitieren Sie von Künstlicher Intelligenz bei Lieferantenauswahl und Budgetierung"
                  : "Sie wollen immer die volle Auslastung der Produktionskapazitäten Ihres Unternehmens sicherstellen? Und Sie wollen keine lukrativen Angebote verpassen, weil Ihre Produktion gerade ausgelastet ist?"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {!manufacturer
                  ? "Erhöhen Sie Liefertreue und Marge von Prototypen- bis Serienfertigung"
                  : "Mit Order-Scout finden Sie neue Aufträge und Geschäftskontakte für Ihre CNC-Fertigung und den 3D-Druck. In Sekundenschnelle liefert Ihnen die CNC-Plattform online passende Ausschreibungen, zum Beispiel für Fräs-, Dreh- oder Drehfräsarbeiten mit allen relevanten Auftragsinformationen."}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Section2;
