import axios from "axios";

export const ordersAPI = axios.create({
  baseURL: "/api/orders",
  validateStatus: (status) => {
    return status < 500;
  },
  // withCredentials: false,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  // },
});
