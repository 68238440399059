import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { makeStyles } from "@mui/styles";

// MUI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { openSnack } from "../../../redux/ducks/snackbar";
import { closeOfferDialog, cleanUp } from "../../../redux/ducks/orderView";
import Form from "./Form";

// const useStyles = makeStyles((theme) => ({}));

const SendOfferDialog = () => {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { open, loaded, error, loading } = useSelector(
    (state) => state.orderView.offerDialog
  );

  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp(true));
    }
  }, [dispatch, error]);

  React.useEffect(() => {
    if (!!loaded) {
      dispatch(openSnack("Angebot erfolgreich gesendet"));
      dispatch(cleanUp(true));
    }
  }, [dispatch, loaded]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(closeOfferDialog());
      }}
    >
      <DialogTitle>{"Angebot Senden"}</DialogTitle>
      <DialogContent>
        <Form loading={loading} />
      </DialogContent>
    </Dialog>
  );
};

export default SendOfferDialog;
