import React from "react";

import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Redirect } from "react-router-dom";

// import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { createCheckOut, cleanUp } from "../../../redux/ducks/unsubscribed";
import { openSnack } from "../../../redux/ducks/snackbar";

function StripeFailure() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { checkoutURL, loading, error } = useSelector((s) => s.unsubscribed);
  const subscription = useSelector((s) => s.auth.subscription);
  React.useEffect(() => {
    if (checkoutURL) {
      window.location.href = checkoutURL;
    }
  }, [checkoutURL]);

  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, error]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "40vh",
      }}
    >
      {subscription && <Redirect to="/companies/my" />}
      <Alert icon={false} severity="error" sx={{ maxWidth: "60em" }}>
        <Typography>
          Der Zahlungsversuch ist fehlgeschlagen. Bitte versuchen Sie es erneut.
        </Typography>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(createCheckOut({ origin: window.location.origin }));
            }}
          >
            {!loading ? (
              "Weiter zur Kasse"
            ) : (
              <CircularProgress color="inherit" size={30} />
            )}
          </Button>
        </div>
      </Alert>
    </div>
  );
}

export default StripeFailure;
