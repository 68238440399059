import React from "react";
import SubArticle from "./SubArticle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const b5List = [
  "Dienstleister für den Betrieb unserer Webseite und die Verarbeitung der durch die Systeme gespeicherten oder übermittelten Daten (zB für Rechenzentrumsleistungen, Zahlungsabwicklungen, IT-Sicherheit). Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b oder lit. f DSGVO, soweit es sich nicht um Auftragsverarbeiter handelt;",
  "Staatliche Stellen/Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung erforderlich ist. Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. c DSGVO;",
  "Zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen (zB Auditoren, Banken, Versicherungen, Rechtsberater, Aufsichtsbehörden, Beteiligte bei Unternehmenskäufen oder der Gründung von Gemeinschaftsunternehmen). Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b oder lit. f DSGVO.",
];

function B5() {
  return (
    <SubArticle title="(5) Übermittlung personenbezogener Daten an Dritte; Rechtfertigungsgrundlage">
      <Typography>
        Folgende Kategorien von Empfängern, bei denen es sich im Regelfall um
        Auftragsverarbeiter handelt (siehe dazu A.(7)), erhalten ggf. Zugriff
        auf Ihre personenbezogenen Daten:
      </Typography>
      <List>
        {b5List.map((article, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText sx={{ ml: { xs: "-2rem", sm: "0" } }}>
                {article}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Typography>
        Zu den Gewährleistungen eines angemessenen Datenschutzniveaus bei einer
        Weitergabe der Daten in Drittländer siehe A.(8). Darüber hinaus geben
        wir Ihre personenbezogenen Daten nur an Dritte weiter, wenn Sie nach
        Art. 6 Abs. 1 S. 1 lit. a DSGVO eine ausdrückliche Einwilligung dazu
        erteilt haben.
      </Typography>
    </SubArticle>
  );
}

export default B5;
