import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { CircularProgress } from "@mui/material";

export const AuthRoute = (props) => {
  const { component: Component, ...rest } = props;
  const { auth } = props;
  const location = useLocation();
  const prev = location.state?.from.pathname;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.isAuthenticated) {
          return <Component {...props} />;
        } else {
          return (
            <React.Fragment>
              {auth.company && auth.company.industryRole ? (
                <>
                  <Redirect
                    to={{
                      pathname: prev
                        ? prev
                        : auth.company.industryRole === "Fertiger"
                        ? "/orders/search"
                        : "/orders/new",
                    }}
                  />
                </>
              ) : (
                <CircularProgress />
              )}
            </React.Fragment>
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth;
  return { auth };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
