import axios from "axios";

const offersAPI= axios.create({
  baseURL: `/api/offers`,
  validateStatus: (status) => {
    return status < 500;
  },
  // withCredentials: false,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  // },
});

export default offersAPI;