import { usersAPI } from "../../apis/users";
import { reset } from "redux-form";
import socket from "../../helpers/socket";
const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

const loading = "users/resetPW/loading";
const loaded = "users/resetPW/loaded";
const error = "users/resetPW/error";
const clean = "users/resetPW/clean";

export const resetPW = (values) => async (dispatch) => {
  dispatch({ type: loading });

  try {
    const response = await usersAPI.post(`/reset/${values.token}`, values);
    const { user, company, isVerified, subscription } = response.data.data;
    if (response.data.status === "success") {
      dispatch({ type: loaded });
      dispatch(reset("ResetPWForm"));
      localStorage.setItem("user", user);
      localStorage.setItem("company", company._id);
      socket.connect();
      dispatch({
        type: "auth/login",
        payload: { userID: user, company, isVerified, subscription },
      });
    } else {
      dispatch({ type: error, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: error, payload: e.response?.data || e });
  }
};
export const cleanUp = () => {
  return { type: clean };
};

const resetPWReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, loading: true, loaded: false, error: null };
    case loaded:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
      };
    case error:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loaded: false,
      };
    case clean:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default resetPWReducer;
