import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Notifications from "./Notifications";
import AccountMenu from "./AccountMenu";
import AppBar from "@mui/material/AppBar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { toggleDrawer } from "../../../redux/ducks/drawer";
import {
  getMyNotifications,
  markSeen,
} from "../../../redux/ducks/notifications";
import { getConversations } from "../../../redux/ducks/conversations";
import makeStyles from "@mui/styles/makeStyles";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import LogoWhite from "../../LogoWhite";
import { Link } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatIcon from "@mui/icons-material/Chat";
import Badge from "@mui/material/Badge";

const drawerWidth = 190;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.common.black,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  grow: {
    flexGrow: 1,
  },
  notificationButton: {
    marginRight: "1em",
  },
}));

export const MUIDynAppbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userId = useSelector((s) => s.auth.user);

  // -----------Account Setting --------------
  const [accAnchorEl, setAccAnchorEl] = React.useState();
  const openAccSettings = (e) => {
    setAccAnchorEl(e.currentTarget);
  };
  const closeAccMenu = () => {
    setAccAnchorEl(null);
  };
  // -------------Notifications ---------------
  const [notifAnchorEl, setNotifAnchorEl] = React.useState();
  const openNotifications = (event) => {
    setNotifAnchorEl(event.currentTarget);
  };
  const closeNotifications = () => {
    setNotifAnchorEl(null);
    dispatch(markSeen());
  };
  // brings notification state from the redux store
  const { notifications } = useSelector((s) => s.notifications);

  // counts unread notifications
  const [notifCount, setNotifCount] = React.useState(
    notifications.filter((notification) => !notification.read).length
  );
  // tracks changes to notifications to update the notification icon
  React.useEffect(() => {
    setNotifCount(
      notifications.filter((notification) => !notification.read).length
    );
  }, [notifications]);

  // calls API to get company notifications
  const { company } = useSelector((s) => s.auth);
  React.useEffect(() => {
    if (company) {
      dispatch(getMyNotifications());
    }
  }, [dispatch, company]);
  // ---------------------------------------
  // conversations
  const conversations = useSelector((s) => s.conversations.get.conversations);
  // const loaded = useSelector((s) => s.conversations.create.loaded);
  const [uncheckedConvs, setUncheckedConvs] = React.useState(0);
  // gets the conversations array of a user id
  React.useEffect(() => {
    if (userId) {
      dispatch(getConversations(userId));
    }
  }, [userId, dispatch]);

  React.useEffect(() => {
    const notCheckedConvs = conversations.filter(
      (c) => c.checked === false
    ).length;
    setUncheckedConvs(notCheckedConvs);
  }, [conversations]);

  // -----------------------------------
  // drawer
  const handleMenuClick = () => {
    dispatch(toggleDrawer());
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Button className={classes.logo} component={Link} to="/">
          <LogoWhite />
        </Button>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleMenuClick}
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.grow}></div>

        <Tooltip title="Benachrichtigungen">
          <IconButton
            color="inherit"
            onClick={openNotifications}
            // className={classes.notificationButton}
            size="large"
          >
            <Badge badgeContent={notifCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Unterhaltungen">
          <IconButton
            color="inherit"
            size="large"
            component={Link}
            to="/conversations"
          >
            <Badge badgeContent={uncheckedConvs} color="error">
              <ChatIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Kontoeinstellung">
          <IconButton
            color="inherit"
            onClick={openAccSettings}
            className={classes.notificationButton}
            size="large"
          >
            <AccountCircleIcon />
          </IconButton>
        </Tooltip>
        <Notifications
          anchorEl={notifAnchorEl}
          handleClose={closeNotifications}
        />

        <AccountMenu anchorEl={accAnchorEl} handleClose={closeAccMenu} />
      </Toolbar>
    </AppBar>
  );
};

export default MUIDynAppbar;
