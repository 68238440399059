import messagesAPI from "../../apis/messages";

const getLoading = "messages/get/loading";
const getLoaded = "messages/get/loaded";
const getError = "messages/get/error";
const sendLoading = "messages/send/loading";
const sendLoaded = "messages/send/loaded";
const sendError = "messages/send/error";
const msgReceived = "messages/received";
const initialState = {
  get: { loading: false, loaded: false, error: null, messages: [] },
  send: { loading: false, loaded: false, error: null, text: "" },
};

export const getConvMessages = (convId) => async (dispatch) => {
  dispatch({ type: getLoading });
  try {
    const response = await messagesAPI.get(`/${convId}`);
    if (response.data.status === "success") {
      dispatch({ type: getLoaded, payload: response.data.data.messages });
      // dispatch(reset("ContactForm"));
    } else {
      dispatch({ type: getError, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: getError, payload: e.response?.data || e });
  }
};

export const sendMessage = (reqBody) => async (dispatch) => {
  dispatch({ type: sendLoading, payload: reqBody.body });
  try {
    const response = await messagesAPI.post(`/`, reqBody);
    if (response.data.status === "success") {
      dispatch({ type: sendLoaded, payload: response.data.message });
      // dispatch(reset("ContactForm"));
    } else {
      dispatch({ type: sendError, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: sendError, payload: e.response?.data || e });
  }
};

export const receiveMessage = (params) => {
  const { message, isCurrentConv } = params;
  return {
    type: msgReceived,
    payload: { message, isCurrentConv },
  };
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case getLoading:
      return {
        ...state,
        get: {
          ...state.get,
          loading: true,
          loaded: false,
          error: null,
          messages: [],
        },
      };
    case getLoaded:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          loaded: true,
          error: null,
          messages: action.payload,
        },
      };
    case getError:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case sendLoading:
      return {
        ...state,
        send: {
          ...state.send,
          loading: true,
          loaded: false,
          error: null,
          text: action.payload,
        },
      };
    case sendLoaded:
      return {
        ...state,
        send: {
          ...state.send,
          loading: false,
          loaded: true,
          error: null,
        },
        get: {
          ...state.get,
          messages: [...state.get.messages, action.payload],
        },
      };
    case sendError:
      return {
        ...state,
        send: {
          ...state.send,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case msgReceived:
      // add to the messages array if the message is in the current conv
      if (action.payload.isCurrentConv) {
        return {
          ...state,
          get: {
            ...state.get,
            messages: [...state.get.messages, action.payload.message],
          },
        };
      }
      return state;
    default:
      return state;
  }
};

export default messagesReducer;
