import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
// import Link from "../../../shared/Link";
import { useHistory } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import EnhancedTableHead from "./TableHead";
import formatNum from "../../../../utils/formatNum";

// Sorting: orderBy?
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// Sorting: ?
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Sorting
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Head Cells Data example
// const headCells = [
//   {
//     id: "title",
//     numeric: false,
//     disablePadding: false,
//     label: "Order Title",
//     disableSorting: true,
//   },
//   { id: "qty", numeric: true, disablePadding: false, label: "Quantity" },
//   { id: "material", numeric: false, disablePadding: false, label: "Material" },
//   {
//     id: "technology",
//     numeric: false,
//     disablePadding: false,
//     label: "Technology",
//   },
//   {
//     id: "certificate",
//     numeric: false,
//     disablePadding: false,
//     label: "Certificate",
//   },
// ];

// // Table Head Component
// function EnhancedTableHead(props) {
//   const { classes, order, orderBy, onRequestSort } = props;
//   const createSortHandler = (property) => (event) => {
//     onRequestSort(event, property);
//   };

//   return (
//     <TableHead headCells={props.headcells}>
//       <TableRow>
//         {headCells.map((headCell) => (
//           <TableCell
//             key={headCell.id}
//             align={"left"}
//             padding={headCell.disablePadding ? "none" : "default"}
//             sortDirection={orderBy === headCell.id ? order : false}
//           >
//             <TableSortLabel
//               active={orderBy === headCell.id}
//               direction={orderBy === headCell.id ? order : "asc"}
//               onClick={createSortHandler(headCell.id)}
//             >
//               {headCell.label}
//               {orderBy === headCell.id ? (
//                 <span className={classes.visuallyHidden}>
//                   {order === "desc" ? "sorted descending" : "sorted ascending"}
//                 </span>
//               ) : null}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const useToolbarStyles = makeStyles((theme) => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//   },
//   title: {
//     flex: "1 1 100%",
//   },
// }));

// const EnhancedTableToolbar = (props) => {
//   const classes = useToolbarStyles();
//   const {
//     queryValue,
//     onQueryChange,
//     materialQueryValue,
//     onMaterialQueryChange,
//     technologyQueryValue,
//     onTechnologyQueryChange,
//     certificateQueryValue,
//     onCertificateQueryChange,
//     onFilterRequest,
//   } = props;

//   // const handleQueryChange=(term)=>{
//   //   // let newArray = ordersAPI
//   //   console.log("New Array:")
//   // }

//   return (
//     <div
//       className={clsx(classes.root)}
//     >

//           <SearchField queryValue={queryValue} onQueryChange={onQueryChange} />
//           <SelectSearch
//             label="Materials"
//             value={materialQueryValue}
//             onChange={onMaterialQueryChange}
//             options={materials}
//           />
//           <SelectSearch
//             label="Technology"
//             value={technologyQueryValue}
//             onChange={onTechnologyQueryChange}
//             options={technologies}
//           />
//           <SelectSearch
//             label="Certificates"
//             value={certificateQueryValue}
//             onChange={onCertificateQueryChange}
//             options={certificates}
//           />

//           <Button variant="contained" color="primary" onClick={onFilterRequest}>
//             Apply Filters
//           </Button>
//         <Accordion />
//     </div>
//   );
// };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("orderTitle");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { rows = [], isLoading = false, headCells, SentOffers } = props;
  const history = useHistory();
  const dateFormat = (date) => moment(date).format("DD/MM/YYYY");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {!rows.length && !isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography variant="h5">{"Keine Daten gefunden"}</Typography>
        </div>
      ) : (
        <Paper elevation={4} className={classes.paper}>
          {isLoading && <LinearProgress />}

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />

              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((offer, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    const handleRowClick = () => {
                      history.push(`/offers/${offer._id}`);
                    };
                    return (
                      <TableRow
                        style={{ cursor: "pointer" }}
                        hover
                        tabIndex={-1}
                        key={index}
                        onClick={handleRowClick}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                        >
                          {offer.order.title}
                        </TableCell>
                        {SentOffers ? (
                          <TableCell align="left">
                            {/* <Link to={"/companies/" + offer.receivingCompany._id}> */}
                            {offer.receivingCompany.name}
                            {/* </Link> */}
                          </TableCell>
                        ) : (
                          <TableCell align="left">
                            {/* <Link to={"/companies/" + offer.company._id}> */}
                            {offer.company.name}
                            {/* </Link> */}
                          </TableCell>
                        )}
                        <TableCell align="left">
                          {"€ " + formatNum(offer.price)}
                        </TableCell>
                        <TableCell align="left">
                          {dateFormat(offer.deliveryDate)}
                        </TableCell>
                        <TableCell align="left">{offer.status}</TableCell>
                        <TableCell align="left">
                          {dateFormat(offer.createdAt)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {!rows.length && !isLoading ? null : (
        <FormControlLabel
          control={
            <Switch
              checked={dense}
              onChange={handleChangeDense}
              color="primary"
            />
          }
          label="Kompakte Tabelle"
        />
      )}
    </div>
  );
}
