import React from "react";
import Typography from "@mui/material/Typography";

function ViewText({ title = "", text, className, children }) {
  return (
    <div className={className}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle1" style={{ maxWidth: "20em" }}>
        {text || children ? text : "-"}
        {children}
      </Typography>
    </div>
  );
}

export default ViewText;
