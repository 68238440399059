import React from "react";
import Typography from "@mui/material/Typography";

function ViewTable({ title = "", exists = false, data = [], className }) {
  return (
    <div className={className}>
      <Typography variant="h6">{title}</Typography>

      {exists &&
        data.map((row, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",

                // justifyContent: "center",
              }}
            >
              <div>
                <Typography variant="subtitle2" component={"p"}>
                  {row.key}:
                </Typography>
              </div>
              <div style={{ minWidth: "16px" }}></div>
              <div>
                {" "}
                <Typography
                  variant="subtitle1"
                  component={"p"}
                  sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                >
                  <b>{row.value ? row.value : "-"}</b>
                </Typography>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ViewTable;
