import React from "react";
import LogoWhite from "../LogoWhite";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000",
    color: "rgb(187, 187, 187)",
    // textAlign: "center",
    paddingTop: "1em",
    paddingBottom: "1em",
    flexShrink: 0,
    flexWrap: "noWrap",
  },
  wrapper: {
    // flexWrap: "noWrap",
  },
  column: {
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    color: "rgb(187, 187, 187)",
    textDecoration: "none",
    padding: "0.2em",
    "&:hover": {
      textDecoration: "underline",
      color: "#fff",
    },
  },
}));

const mainMenu = [
  {
    name: "Einkäufer",
    path: "/",
  },
  {
    name: "Fertiger",
    path: "/manufacturer",
  },
  {
    name: "Kontakt",
    path: "/contact",
  },
  {
    name: "Über Uns",
    path: "/about",
  },
];
const secondaryMenu = [
  {
    name: "Datenschutzerklärung",
    path: "/data-policy",
  },
  {
    name: "Impressum",
    path: "/imprint",
  },
  {
    name: "AGB",
    path: "/terms-and-conditions",
  },
];

function Footer() {
  const classes = useStyles();
  return (
    <>
      <footer className={classes.root}>
        <Container>
          <Grid
            container
            direction="row"
            className={classes.wrapper}
            spacing={2}
          >
            <Grid
              item
              container
              direction="column"
              className={classes.column}
              xs={12}
              md={4}
            >
              <Button to="/" className={classes.title} component={Link}>
                <LogoWhite />
              </Button>
              <div>&copy;Order-Scout {new Date().getFullYear()}</div>
            </Grid>
            <Grid
              item
              container
              direction="column"
              className={classes.column}
              xs={12}
              md={4}
            >
              {mainMenu.map((item) => {
                return (
                  <Link to={item.path} className={classes.link} key={item.name}>
                    {item.name}
                  </Link>
                );
              })}
            </Grid>
            <Grid
              item
              container
              direction="column"
              className={classes.column}
              xs={12}
              md={4}
            >
              {secondaryMenu.map((item) => {
                return (
                  <Link to={item.path} className={classes.link} key={item.name}>
                    <Typography align="center">{item.name}</Typography>
                  </Link>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
