import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import {
  DataGrid,
  //GridToolbar,
  deDE,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";


const StyledDataGrid = styled(DataGrid)({
  "& .row": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none",
  },
});

const dateFormat = (date) => moment(date).format("DD/MM/YYYY");
const columns = [
  { field: "title", headerName: "Auftrag Titel", minWidth: 100, flex: 1 },
  {
    field: "companyName",
    headerName: "Firma",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Erstelldatum",
    type: "date",
    width: 115,
    valueFormatter: (params) => {
      return dateFormat(params.value);
    },
  },
  {
    field: "deliveryDeadline",
    headerName: "Lieferfrist",
    type: "date",
    width: 110,
    valueFormatter: (params) => {
      return dateFormat(params.value);
    },
  },
  {
    field: "offersDeadline",
    headerName: "Angebotsfrist",
    type: "date",
    width: 115,
    valueFormatter: (params) => {
      return dateFormat(params.value);
    },
  },
  {
    field: "distance",
    headerName: "Entfernung",
    type: "number",
    width: 100,
    valueFormatter: (params) => {
      return `${Math.round(params.value * 100) / 100} KM`;
    },
  },
  {
    field: "qty",
    headerName: "Menge",
    width: 90,
    type: "number",
  },
  {
    field: "material",
    headerName: "Material",
    width: 150,
    //flex: 1,
  },
  {
    field: "technology",
    headerName: "Technologie",
    width: 150,
    //flex: 1,
  },
  {
    field: "certificate",
    headerName: "Zertifikat",
    minWidth: 110,
    flex: 1,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}

export default function OrdersDataGrid() {
  const { orders, loading, error } = useSelector((state) => state.search);
  console.log(orders)
  const formattedOrders = orders.map((order) => ({
    ...order,
    id: order._id,
  }));
  const history = useHistory();

  
  
  return (
    <Paper style={{ width: "100%" }}>
      {error ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography>
            {error.message || "Etwas ist schief gelaufen."}
          </Typography>
        </div>
      ) : (
        <StyledDataGrid
          // className={classes.root}
          components={{
            Toolbar: CustomToolbar,
          }}
          editMode="row"
          rows={formattedOrders}
          columns={columns}
          pageSize={10}
          //rowsPerPageOptions={[10, 25, 100 ]}
          rowsPerPageOptions = {[]}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          getRowClassName={() => "row"}
          onRowClick={(r) => {
            return history.push(`/orders/${r.id}`);
          }}
          loading={loading}
          autoHeight
        />
      )}
    </Paper>
  );
}
