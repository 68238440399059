import conversationsAPI from "../../apis/conversations";

const getLoading = "conversations/get/loading";
const getLoaded = "conversations/get/loaded";
const getError = "conversations/get/error";
const cleanCurrent = "conversation/current/clean";
const createLoading = "conversations/create/loading";
const createLoaded = "conversations/create/loaded";
const createError = "conversations/create/error";
const createClean = "conversations/create/clean";
const convChange = "conversations/convChange";
const initialState = {
  get: {
    loading: false,
    loaded: false,
    error: null,
    conversations: [],
  },
  create: {
    loading: false,
    loaded: false,
    error: null,
    conversationId: null,
  },
  currentConversationId: null,
  messages: [],
};

export const getConversations = (userId) => async (dispatch) => {
  dispatch({ type: getLoading });
  try {
    const response = await conversationsAPI.get(`/${userId}`);
    if (response.data.status === "success") {
      dispatch({ type: getLoaded, payload: response.data.data.conversations });
    } else {
      dispatch({ type: getError, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: getError, payload: e.response?.data || e });
  }
};

export const changeCurrentConv = (convId) => (dispatch) => {
  dispatch({ type: convChange, payload: convId });
};

export const createConv = (params) => async (dispatch) => {
  dispatch({ type: createLoading });
  try {
    const response = await conversationsAPI.post("/", params);
    if (response.data.status === "success") {
      dispatch({
        type: createLoaded,
        payload: response.data.data,
      });
    } else {
      dispatch({ type: createError, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: createError, payload: e.response?.data || e });
  }
};
export const cleanCurrentConv = () => ({
  type: cleanCurrent,
});

export const cleanCreate = () => {
  return { type: createClean };
};
const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getLoading:
      return {
        ...state,
        get: {
          ...state.get,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case getLoaded:
      return {
        ...state,
        get: {
          ...state.get,

          loading: false,
          loaded: true,
          error: null,
          conversations: action.payload,
        },
        // currentConversationId: action.payload[0]?._id,
      };
    case getError:
      return {
        ...state,
        get: {
          ...state.get,

          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case cleanCurrent:
      return { ...state, currentConversationId: null };
    case createLoading:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case createLoaded:
      const existingConvIndex = state.get.conversations.findIndex(
        (c) => c._id.toString() === action.payload.conversation._id.toString()
      );
      if (existingConvIndex < 0) {
        return {
          ...state,
          create: {
            ...state.create,
            loading: false,
            loaded: true,
            error: null,
            conversationId: action.payload.conversation._id.toString(),
          },
          get: {
            ...state.get,
            conversations: [
              action.payload.conversation,
              ...state.get.conversations,
            ],
          },
          currentConversationId: action.payload.conversation._id.toString(),
        };
      } else {
        return {
          ...state,
          create: {
            ...state.create,
            loading: false,
            loaded: true,
            error: null,
            conversationId: action.payload.conversation._id.toString(),
          },
          currentConversationId: action.payload.conversation._id.toString(),
        };
      }

    case createError:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case createClean:
      return {
        ...state,
        create: {
          ...initialState.create,
        },
      };
    case convChange:
      return {
        ...state,
        currentConversationId: action.payload,
      };
    case "messages/send/loaded":
      const convIndex = state.get.conversations.findIndex(
        (c) => c._id.toString() === action.payload.conversation._id.toString()
      );

      return {
        ...state,
        get: {
          ...state.get,
          conversations: state.get.conversations
            .map((conv, index) => {
              if (index === convIndex) {
                return {
                  ...conv,
                  lastMessage: action.payload,
                  updatedAt: new Date(),
                };
              }
              return conv;
            })
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
        },
      };
    case "messages/received":
      const conversationIndex = state.get.conversations.findIndex(
        (c) =>
          c._id.toString() ===
          action.payload.message.conversation._id.toString()
      );
      if (conversationIndex >= 0) {
        // -1 means no conv found
        // if conv found, update conv lastMessage and updatedAt
        return {
          ...state,
          get: {
            ...state.get,
            conversations: state.get.conversations
              .map((conv, index) => {
                if (index === conversationIndex) {
                  return {
                    ...conv,
                    lastMessage: action.payload.message,
                    checked: action.payload.isCurrentConv,
                    updatedAt: new Date(),
                  };
                }
                return conv;
              })
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
          },
        };
      } else {
        // new conv
        // if conv not found, create a new conversation and add on top of the convs arr
        return {
          ...state,
          get: {
            ...state.get,
            conversations: [
              action.payload.message.conversation,
              ...state.get.conversations,
            ],
          },
        };
      }
    case "messages/get/loaded":
      const conversationId = action.payload[0]?.conversation;
      return {
        ...state,
        get: {
          ...state.get,
          conversations: state.get.conversations.map((conv) => {
            if (conv._id === conversationId) {
              conv.checked = true;
              return conv;
            } else {
              return conv;
            }
          }),
        },
      };

    default:
      return state;
  }
};

export default conversationsReducer;
