import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "../shared/Link";

import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { Typography, Button } from "@mui/material";
import LogoWhite from "../LogoWhite";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url('/images/CNCBG.jpg')",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-64px",
    // paddingTop: "100px",
    backgroundAttachment: "fixed",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  container: {
    // border: "red solid 1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "100vh",
    flexGrow: 1,
    paddingTop: "5em",
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    // minHeight: "100%",
    // border: "red solid 1px",
    // padding: "10em 0",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    margin: "1em",
    borderRadius: "1em",
    // border: "red solid 1px",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  logo: {
    width: "500px",
    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },
  descContainer: {
    margin: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    backgroundColor: "rgba(0,0,0,0.4)",
    color: "white",
    maxWidth: "45em",
    borderRadius: "1em",
  },
  desc: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.0rem",
    },
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "1.3rem",
    // },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  slogan: {
    color: "white",
    fontSize: "2rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
    },
  },
  bottomMessage: {
    color: "white",
    backgroundColor: "rgba(0,0,0,0.2)",
    fontSize: "1.4rem",
    padding: "0.5em",
    paddingBottom: "1em",
    borderRadius: "1em 1em 0 0",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.1rem",
    },
    // position: "absolute",
    // bottom: "0",
  },
  link: {
    textDecoration: "none",
    margin: "2em",
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em",
    },
  },
  joinButton: {
    background:
      "linear-gradient(135deg, rgba(115,21,34,1) 0%, rgba(182,51,33,1) 50%, rgba(241,198,30,1) 100%)",
    fontSize: "1.2rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
  moreButton: {
    border: "3px solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: "rgba(0,0,0,0.4)",
    fontSize: "1.2rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
  },
}));

const HeroSection = ({ manufacturer }) => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.gridContainer}>
          <div className={classes.titleContainer}>
            <LogoWhite className={classes.logo} />
            <Typography align="center" className={classes.slogan}>
              {"Das Cockpit der CNC-Fertigung"}
            </Typography>
          </div>
          <div className={classes.descContainer}>
            <Typography align="center" className={classes.desc}>
              {
                "Order-Scout ist der Marktplatz für Einkäufer und Fertiger aus den Bereichen Metall-, Holz- und Kunststoffverarbeitung sowie 3D-Druck."
              }
            </Typography>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              className={[classes.link, classes.moreButton].join(" ")}
              variant="outlined"
              href="#more"
              color="primary"
              // size="large"
            >
              {"Mehr Erfahren"}
            </Button>
            <RouterLink to="/register" className={classes.link}>
              <Button
                variant="contained"
                color="primary"
                // size="large"
                className={classes.joinButton}
              >
                {"JETZT REGISTRIEREN"}
              </Button>
            </RouterLink>
          </div>
        </div>
        <Typography align="center" className={classes.bottomMessage}>
          {!manufacturer ? (
            <span>
              Die folgenden Infos sind für Einkäufer gedacht, wenn Sie Fertiger
              sind, klicken Sie bitte <Link to="/manufacturer">hier</Link>
            </span>
          ) : (
            <span>
              Die folgenden Infos sind für Fertiger gedacht, wenn Sie Einkäufer
              sind, klicken Sie bitte <Link to="/">hier</Link>
            </span>
          )}
        </Typography>
      </Container>
    </section>
  );
};

export default HeroSection;
