import { notificationsAPI } from "../../apis/notifications";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  notifications: [],
  anchorEl: null,
};

const loading = "notifications/loading";
const loaded = "notifications/loaded";
const error = "notifications/error";
const seen = "notifications/seen";
const newNotif= "notifications/new"

const reformatNotifications = (notificationsArr) => {
  const formattedNotifications = [];
  notificationsArr.forEach((notification) => {
    switch (notification.type) {
      case "new_offer":
        return formattedNotifications.push({
          message: `${notification.info.actorName} hat Ihnen ein neues Angebot für Ihren Auftrag ${notification.info.orderTitle} unterbreitet.`,
          createdAt: notification.createdAt,
          read: notification.readDate !== null,
          link: `/offers/${notification.entityId}`,
        });
      case "offer_accepted":
        return formattedNotifications.push({
          message: `${notification.info.actorName} hat Ihr Angebot für den Auftrag ${notification.info.orderTitle} angenommen`,
          createdAt: notification.createdAt,
          read: notification.readDate !== null,
          link: `/offers/${notification.entityId}`,
        });
      default:
        return notification;
    }
  });
  return formattedNotifications;
};

export const getMyNotifications = () => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await notificationsAPI.get("/");

    if (response.data.status === "success") {
      dispatch({
        type: loaded,
        payload: { notifications: response.data.data.notifications },
      });
    } else {
      dispatch({
        type: error,
        payload: { error: response.data.error },
      });
    }
  } catch (e) {
    dispatch({ type: error, payload: e });
  }
};

export const markSeen = () => async (dispatch) => {
  dispatch({ type: seen });
  try {
    await notificationsAPI.patch("/");
  } catch (e) {
    console.log(e);
  }
};

export const addNewNotifications = (notif) => (dispatch)=>{
  dispatch({
    type:newNotif,
    payload:notif
  })
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, ...initialState, loading: true };
    case loaded:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        notifications: reformatNotifications([...action.payload.notifications]),
      };
    case error:
      return { ...state, loading: false, loaded: false, error: action.payload };
    case seen:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          return { ...notification, read: true };
        }),
      };
    case newNotif:
      return{
        ...state,
        notifications:[...reformatNotifications([action.payload]),...state.notifications]
      }
    default:
      return state;
  }
};

export default notificationsReducer;
