import React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import NorthEastIcon from "./NorthEastIcon";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../../redux/ducks/drawer";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const drawerWidth = 200;

const SidebarData = [
  {
    title: "Startseite",
    path: "/",
    icon: <HomeIcon />,
    cName: "nav-text",
  },
  {
    title: "Mein Firmenprofil",
    path: "/companies/my",
    icon: <BusinessIcon />,
    cName: "nav-text",
  },
];
const ordersData = [
  {
    title: "Auftrag Suchen",
    path: "/orders/search",
    icon: <SearchIcon />,
    cName: "nav-text",
    manufOnly: true,
  },
  {
    title: "Auftrag Hinzufügen",
    path: "/orders/new",
    icon: <AddCircleIcon />,
    cName: "nav-text",
  },
  {
    title: "Meine Aufträge",
    path: "/orders/my",
    icon: <FileCopyIcon />,
    cName: "nav-text",
  },
];
const offersData = [
  {
    title: "Eingegangene Angebote",
    path: "/offers/received",
    icon: <CallReceivedIcon />,
    cName: "nav-text",
  },
  {
    title: "Gesendete Angebote",
    path: "/offers/sent",
    icon: <NorthEastIcon />,
    cName: "nav-text",
    manufOnly: true,
  },
];
const expiredData = [
  {
    title: "Abgelaufene Aufträge",
    path: "/orders/expired",
    icon: <AccessTimeIcon />,
    cName: "nav-text",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function MUIDrawer(props) {
  const history = useHistory();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const toggled = useSelector((s) => s.drawer.toggled);
  const industryRole = useSelector((s) =>
    s.auth.company ? s.auth.company.industryRole : null
  );
  // disable Tooltip condition
  const disabledTooltip = (item) =>
    !item.manufOnly || industryRole === "Fertiger";
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {SidebarData.map((item, index) => (
          <ListItem
            button
            key={index}
            onClick={() => {
              history.push(item.path);
            }}
          >
            {<ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {ordersData.map((item, index) => (
          <Tooltip
            title="Nur für Fertiger verfügbar"
            disableFocusListener={disabledTooltip(item)}
            disableHoverListener={disabledTooltip(item)}
            disableTouchListener={disabledTooltip(item)}
            key={item.title}
          >
            <span>
              <ListItem
                disabled={item.manufOnly && industryRole !== "Fertiger"}
                button
                onClick={() => {
                  history.push(item.path);
                }}
              >
                {<ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.title} />
              </ListItem>
            </span>
          </Tooltip>
        ))}
      </List>
      <Divider />
      <List>
        {offersData.map((item) => (
          <Tooltip
            title="Nur für Fertiger verfügbar"
            disableFocusListener={disabledTooltip(item)}
            disableHoverListener={disabledTooltip(item)}
            disableTouchListener={disabledTooltip(item)}
            key={item.title}
          >
            <span>
              <ListItem
                disabled={item.manufOnly && industryRole !== "Fertiger"}
                button
                onClick={() => {
                  history.push(item.path);
                }}
              >
                {<ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.title} />
              </ListItem>
            </span>
          </Tooltip>
        ))}
      </List>
      <Divider />
      <List>
        {expiredData.map((item) => (
          <Tooltip
            title="Nur für Fertiger verfügbar"
            disableFocusListener={disabledTooltip(item)}
            disableHoverListener={disabledTooltip(item)}
            disableTouchListener={disabledTooltip(item)}
            key={item.title}
          >
            <span>
              <ListItem
                disabled={item.manufOnly && industryRole !== "Fertiger"}
                button
                onClick={() => {
                  history.push(item.path);
                }}
              >
                {<ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.title} />
              </ListItem>
            </span>
          </Tooltip>
        ))}
      </List>
      <Divider />
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={toggled}
          onClose={() => {
            dispatch(toggleDrawer());
          }}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default MUIDrawer;
