import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router";

// MUI Components
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "60vh",
  },
  seen: {
    backgroundColor: "rgb(236, 75, 33,0.1)",
  },
  notifText: {
    width: "40ch",
  },
}));

function Notifications({ anchorEl, handleClose }) {
  const { notifications } = useSelector((s) => s.notifications);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  let history = useHistory();
  const handleNotifClick = (target) => {
    history.push(target);
    handleClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      className={classes.root}
    >
      <AppBar position="static" elevation={2} component={"div"}>
        <Typography variant="body1" style={{ margin: "0.7em" }}>
          {"Benachrichtigungen"}
        </Typography>
      </AppBar>
      <List>
        {notifications.map((notif, i) => (
          <ListItem
            button
            divider
            className={!notif.read ? classes.seen : null}
            key={i}
            onClick={() => {
              handleNotifClick(notif.link);
            }}
          >
            <ListItemText
              className={classes.notifText}
              primary={notif.message}
              secondary={moment(notif.createdAt).format("DD/MM/YYYY HH:mm")}
            />
          </ListItem>
        ))}
        {!notifications.length && (
          <ListItem>
            <ListItemText
              className={classes.notifText}
              primary={"Sie haben keine Benachrichtigungen"}
            />
          </ListItem>
        )}
      </List>
    </Popover>
  );
}

export default Notifications;
