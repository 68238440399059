import { Box } from "@mui/system";
import React from "react";
import Avatar from "@mui/material/Avatar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  cleanCurrentConv,
  changeCurrentConv,
} from "../../../redux/ducks/conversations";
import { useSelector, useDispatch } from "react-redux";
import { Alert, CircularProgress } from "@mui/material";
import useQuery from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

function ChatNav() {
  const { loaded, loading, error, conversations } = useSelector(
    (s) => s.conversations.get
  );
  const myId = useSelector((s) => s.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const smAndUp = useMediaQuery("(min-width:655px)");
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const [value, setValue] = React.useState(null);
  let query = useQuery();
  let queryConvId = query.get("conversationId");

  // if first render with a query: set the (tab) value to the query:done automatically by last 2 effects
  // if first render with no query || if query is not included in the loaded conversations: set the query to the first item in the conv arr
  React.useEffect(() => {
    if (
      (!queryConvId && conversations.length) ||
      (conversations.length &&
        conversations.findIndex((el) => el._id === queryConvId) < 0)
    ) {
      const params = new URLSearchParams({
        conversationId: conversations[0]?._id,
      });
      history.replace({ search: params.toString() });
    }
  });
  // if change with click:  set the query to the new value
  const handleChange = (event, newValue) => {
    const params = new URLSearchParams({
      conversationId: conversations[newValue]._id,
    });
    history.replace({ search: params.toString() });
  };

  // Query Params > State Value > State in redux

  // Query Params > State Value
  React.useEffect(() => {
    if (queryConvId) {
      const newIndex = conversations.findIndex((el) => el._id === queryConvId);
      if (newIndex >= 0) {
        setValue(newIndex);
      }
      // if (newIndex < 0 && conversations.length) {
      //   setValue(0);
      // }
    }
  }, [queryConvId, conversations]);
  // State Value > State in redux
  React.useEffect(() => {
    if (conversations.length) {
      dispatch(changeCurrentConv(conversations[value]?._id));
    }
    // we don't need to add conversations in the dependencies since
    // the above useEffect will take care of the conversations update and update the value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, dispatch]);
  React.useEffect(() => {
    return () => {
      dispatch(cleanCurrentConv());
    };
  }, [dispatch]);

  const ConvLabel = ({ conv, partnerFullname }) => {
    return (
      <div>
        <p
          style={{ textAlign: "start" }}
        >{`${conv.partner.companyName} - ${partnerFullname}`}</p>
        <div
          style={{
            display: "flex",
            width: "100%",
            color: "grey",
            textAlign: "start",
          }}
        >
          {conv.lastMessage?.sender.toString() === myId && "Sie: "}
          {conv.lastMessage &&
            (conv.lastMessage?.body.length < 25
              ? conv.lastMessage.body
              : conv.lastMessage.body.substring(0, 22) + "...")}
        </div>
      </div>
    );
  };
  return (
    <Box sx={{ maxWidth: "100%" }}>
      {(loading || error) && (
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          // minHeight={"80vh"}
          sx={{
            maxWidth: loading ? "100%" : "250px",
            "& > *": {
              maxWidth: "100%",
              wordWrap: "break-word",
              wordBreak: "break-all",
            },
          }}
        >
          {loading && <CircularProgress />}
          {error && (
            <Alert sx={{ maxWidth: "100%" }} severity="error">
              {error.message}
            </Alert>
          )}
        </Box>
      )}
      {loaded && conversations.length ? (
        <Tabs
          orientation={smAndUp ? "vertical" : "horizontal"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          {conversations.map((c) => {
            const partnerFullname = `${c.partner.firstName} ${c.partner.lastName}`;
            return (
              <Tab
                key={c._id}
                icon={<Avatar {...stringAvatar(partnerFullname)} />}
                iconPosition="start"
                label={<ConvLabel conv={c} partnerFullname={partnerFullname} />}
                sx={{
                  textTransform: "none",
                  justifyContent: "flex-start",
                  maxWidth: "100%",
                  backgroundColor: !c.checked && "rgb(236, 75, 33,0.1)",
                }}
              />
            );
          })}
        </Tabs>
      ) : (
        loaded && (
          <Alert severity="info">
            Sie haben noch keine Gespräche begonnen.
          </Alert>
        )
      )}
    </Box>
  );
}

export default ChatNav;
