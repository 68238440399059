const initialState = {
  toggled: false,
};
export const toggleDrawer = () => (dispatch) => {
  dispatch({
    type: "drawer/toggle",
  });
};

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "drawer/toggle":
      return {
        ...state,
        toggled: !state.toggled,
      };
    default:
      return state;
  }
};
export default drawerReducer;
