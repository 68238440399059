import React from "react";

import ResetPWForm from "./Form";

import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: "1em",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const Forgot = (props) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar
          sx={(theme) => ({
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
          })}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h4" component="h1">
          {"Ihr neues Passwort"}
        </Typography>
        <br />
        <Typography variant="body1">
          {
            "Um wieder Zugang zu Ihrem Konto zu erhalten, geben Sie bitte Ihr neues Passwort zweimal ein."
          }
        </Typography>
        <ResetPWForm />
      </Paper>
    </Container>
  );
};

export default Forgot;
