import offersAPI from "../../apis/offers";

const loading = "offers/offersSent/loading";
const loaded = "offers/offersSent/loaded";
const error = "offers/offersSent/error";
const tabChange = "offers/offersReceived/activeTab/changed";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  activeTab: 0,
  offers: {
    all: [],
    open: [],
    accepted: [],
    rejected: [],
    archived: [],
  },
  selectedOffers: [],
};

export const getOffers = (company) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await offersAPI("/", { params: { company } });
    if (response.data.status === "success") {
      const offers = response.data.data.offers;
      offers.forEach((offer) => {
        // to fix table sorting
        offer.orderTitle = offer.order.title;
        offer.companyName = offer.company.name;
        // translating status
        switch (offer.status) {
          case "open":
            offer.status = "offen";
            break;
          case "accepted":
            offer.status = "akzeptiert";
            break;
          case "rejected":
            offer.status = "abgelehnt";
            break;
          case "archived":
            offer.status = "archiviert";
            break;
          default:
            return;
        }
      });
      dispatch({
        type: loaded,
        payload: offers,
      });
    } else {
      dispatch({
        type: error,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: error,
      payload: e.response?.data || e,
    });
  }
};

export const setActiveTab = (tabNumber) => (dispatch) => {
  dispatch({ type: tabChange, payload: { tabNumber } });
};

export default function sentOffersReducer(state = initialState, action) {
  switch (action.type) {
    case loading:
      return { ...state, loading: true };
    case loaded:
      const allOffers = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        offers: {
          all: allOffers,
          open: allOffers.filter((offer) => offer.status === "offen"),
          accepted: allOffers.filter((offer) => offer.status === "akzeptiert"),
          rejected: allOffers.filter((offer) => offer.status === "abgelehnt"),
          //archived: allOffers.filter((offer) => offer.status === "archiviert"),
        },
        selectedOffers: allOffers,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    case tabChange:
      switch (action.payload.tabNumber) {
        case 1:
          state.selectedOffers = state.offers.open;
          break;
        case 2:
          state.selectedOffers = state.offers.accepted;
          break;
        case 3:
          state.selectedOffers = state.offers.rejected;
          break;
        case 4:
          state.selectedOffers = state.offers.archived;
          break;
        default:
          state.selectedOffers = state.offers.all;
      }
      return {
        ...state,
        activeTab: action.payload.tabNumber,
      };
    default:
      return state;
  }
}
