const initialState = {
  open: false,
  severity: "success",
  message: "",
};

const open = "snackbar/open";
const close = "snackbar/close";

export const openSnack =
  (message, severity = "success") =>
  (dispatch) => {
    dispatch({ type: open, payload: { severity, message } });
  };
export const closeSnack = () => {
  return { type: close };
};
// export const closeSnack = () => (dispatch) => {
//   dispatch({ type: close });
// };

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case open:
      return {
        ...state,
        open: true,
        severity: action.payload.severity,
        message: action.payload.message,
      };
    case close:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default snackbarReducer;
