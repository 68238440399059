// dependencies
import React from "react";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// MUI
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
// imports
// import LoadingButton from "../../LoadingButton";
import Link from "../../shared/Link";
import { register, cleanUp } from "../../../redux/ducks/register";
import { openSnack } from "../../../redux/ducks/snackbar";

const useStyles = makeStyles((theme) => ({
  selectField: {
    minWidth: "100%",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid",
    borderColor: "#cccccc",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const renderTextField = ({
  label,
  input,
  meta,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      sx={{ width: "100%" }}
      {...input}
      {...custom}
    />
  );
};

const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const renderRadio = ({
  input,
  name,
  label,
  children,
  meta: { touched, error },
  ...rest
}) => {
  return (
    <FormControl component="fieldset" error={touched && error}>
      <FormLabel
        component="legend"
        style={{ fontSize: "1.2em", marginBottom: "0em" }}
      >
        {label}
      </FormLabel>
      {renderFormHelper({ touched, error })}
      <RadioGroup
        aria-label={label}
        name={name}
        row
        color="primary"
        style={{ paddingBottom: "-2em" }}
        {...input}
        {...rest}
        //   onChange={handleChange}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
};
const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl
    variant="outlined"
    style={{ width: "100%" }}
    error={!!touched && !!error}
  >
    <InputLabel id={label}>{label}</InputLabel>
    <Select
      labelId={label + "Id"}
      id={label}
      label={label}
      {...input}
      {...custom}
    >
      {children}
    </Select>
    {renderFormHelper({ touched, error })}
  </FormControl>
);

const renderCheckbox = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <FormControl error={!!touched && !!error}>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
    {renderFormHelper({ touched, error })}
  </FormControl>
);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { touched, invalid, error },
  label,
  id,
  ...props
}) => {
  const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files);
  return (
    <div style={{ maxWidth: "100%" }}>
      {!!label && (
        <FormLabel
          component="label"
          style={{ fontSize: "1.2em", marginRight: "0.5em" }}
          htmlFor={id}
        >
          {label}
        </FormLabel>
      )}
      <input
        style={{ maxWidth: "100%" }}
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        id={id}
        {...props.input}
        {...props}
      />
      <FormHelperText error>{touched && error}</FormHelperText>
    </div>
  );
};

const industryRoles = [
  { value: "Einkäufer", label: "Einkäufer" },
  { value: "Fertiger", label: "Fertiger" },
];
const salutations = [
  { value: "Herr", label: "Herr" },
  { value: "Frau", label: "Frau" },
];
const countriesDE = ["Deutschland", "Österreich", "Schweiz (die)"];

const gdprLabel = (
  <span>
    Ich habe die{" "}
    <Link to="data-policy" newTab>
      Datenschutzerklärung
    </Link>{" "}
    gelesen und erkläre mich mit der Verarbeitung und Speicherung meiner Daten
    einverstanden.
  </span>
);
const aGBLabel = (
  <span>
    Hiermit bestätige ich, die{" "}
    <Link to="/terms-and-conditions" newTab>
      AGB
    </Link>{" "}
    zur Kenntnis genommen zu haben und akzeptiere diese.
  </span>
);
// const paymentLabel = (
//   <span>
//     (Nur für Fertiger erforderlich) Hiermit bestätige ich, die{" "}
//     <a
//       style={{ color: "#EC4B21" }}
//       download
//       href="/docs/Zahlungsbedienungen.pdf"
//     >
//       Zahlungsbedienungen
//     </a>{" "}
//     zur Kenntnis genommen zu haben und akzeptiere diese.
//   </span>
// );

const RegisterForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loaded, loading, error } = useSelector((state) => state.register);
  const classes = useStyles();
  // const [redirect, setRedirect] = React.useState(false);
  // React.useEffect(() => {
  //   if (!!loaded)
  //     setTimeout(() => {
  //       setRedirect(true);
  //     }, 500);
  // });

  // Loaded Handling
  React.useEffect(() => {
    if (!!loaded) {
      dispatch(openSnack("Willkommen bei Order-Scout!"));
      history.push("orders/search");
      dispatch(cleanUp());
    }
  }, [dispatch, loaded, history]);

  // Error Handling
  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, error]);

  return (
    <form
      onSubmit={props.handleSubmit((values) => {
        dispatch(register(values));
      })}
    >
      <Grid container direction={"column"} spacing={2} sx={{ mt: "1em" }}>
        <Grid item>
          <Typography variant="h4" component="h2">
            {"Firmendetails"}
          </Typography>
        </Grid>
        <Grid item>
          <Field
            name="cIndustryRole"
            label="Industrie Rolle*:"
            component={renderRadio}
          >
            {industryRoles.map((role) => {
              return (
                <FormControlLabel
                  key={role.value}
                  value={role.value}
                  control={<Radio color="primary" />}
                  label={role.label}
                />
              );
            })}
          </Field>
        </Grid>
        <Grid item>
          <Field
            name="cName"
            component={renderTextField}
            label="Firmenname*"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={9} md={7}>
            <Field
              name="cStreetName"
              component={renderTextField}
              label="Straße*"
              variant="outlined"
              placeholder="Name der Straße"
            />
          </Grid>
          <Grid item xs={3} md={5}>
            <Field
              name="cStreetNum"
              component={renderTextField}
              label="Nr.*"
              variant="outlined"
              type="number"
              placeholder="Straßennummer"
              min="1"
              InputProps={{ inputProps: { min: 1, max: 999 } }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={4}>
            <Field
              name="cZip"
              component={renderTextField}
              label="PLZ*"
              variant="outlined"
              type="number"
              placeholder="Postleitzahl"
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              name="cCity"
              component={renderTextField}
              label="Stadt*"
              variant="outlined"
              placeholder="Name der Stadt"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              name="cProvince"
              component={renderTextField}
              label="Provinz"
              variant="outlined"
              placeholder="Provinz/Bundesland Name"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Field
            className={classes.selectField}
            name="cCountry"
            component={renderSelectField}
            label="Land*"
          >
            <MenuItem value="" />
            {countriesDE.map((option, index) => {
              return (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Field>
        </Grid>
        <Grid item>
          <Field
            name="cTaxId"
            component={renderTextField}
            label="USt-ID*"
            variant="outlined"
            placeholder="Umsatzsteuer Identifikationsnummer"
          />
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={6}>
            <Field
              name="cPhoneNumber"
              component={renderTextField}
              label="Firma Telefonnummer"
              autoComplete="tel"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="cFaxNumber"
              component={renderTextField}
              label="Firma Faxnummer"
            />
          </Grid>
        </Grid>
        <Grid item>
          {" "}
          <Field
            name="cWebsite"
            component={renderTextField}
            label="Firma Website"
            variant="outlined"
            placeholder="Firma Website"
            fullWidth
            autoComplete="url"
          />
        </Grid>
        <Grid
          item
          style={{ marginTop: "1em", marginBottom: "1em", maxWidth: "100%" }}
        >
          <Field
            name="logo"
            component={FileInput}
            type="file"
            label="Firmenlogo:"
            id="logo"
            accept="image/*"
          />
        </Grid>

        <Grid item>
          <Divider sx={{ my: 1 }} />
        </Grid>

        <Grid item>
          <Typography variant="h4" component="h2">
            {"Benutzerdetails"}
          </Typography>
        </Grid>

        <Grid item>
          <Field name="uSalutation" label="Anrede*:" component={renderRadio}>
            {salutations.map((role) => {
              return (
                <FormControlLabel
                  key={role.value}
                  value={role.value}
                  control={<Radio color="primary" />}
                  label={role.label}
                />
              );
            })}
          </Field>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={12} md={6}>
            <Field
              name="uFirstName"
              component={renderTextField}
              label="Vorname*"
              variant="outlined"
              placeholder="Vorname"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="uLastName"
              component={renderTextField}
              label="Nachname*"
              variant="outlined"
              placeholder="Nachname"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Field
            name="uEmail"
            component={renderTextField}
            label="E-Mail Adresse*"
            variant="outlined"
            placeholder="Benutzer E-Mail"
            type="email"
            autoComplete="username"
          />
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={12} md={6}>
            <Field
              name="uPassword"
              component={renderTextField}
              label="Passwort*"
              variant="outlined"
              type="password"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="uPassword2"
              component={renderTextField}
              label="Passwort wiederholen*"
              variant="outlined"
              type="password"
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
      </Grid>

      <div style={{ paddingTop: "16px" }}>
        <Field
          name="acceptedDataPolicy"
          component={renderCheckbox}
          label={gdprLabel}
        />
      </div>
      <div style={{ paddingTop: "16px" }}>
        <Field name="acceptedAGB" component={renderCheckbox} label={aGBLabel} />
      </div>
      {/* <div style={{ paddingTop: "16px" }}>
        <Field
          name="acceptedPayment"
          component={renderCheckbox}
          label={paymentLabel}
        />
      </div> */}
      {/* <div style={{ paddingTop: "16px" }}>
        <Field
          name="registerationCode"
          component={renderTextField}
          label={"Registrierungscode"}
          placeholder="Der Registrierungscode, den Sie von OrderScout erhalten haben"
        />
      </div> */}

      <div className={classes.buttonSection}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress /> : "Registrieren"}
        </Button>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "cName",
    "cStreetName",
    "cStreetNum",
    "cZip",
    "cCity",
    "cCountry",
    "cIndustryRole",
    "cTaxId",
    "uSalutation",
    "uFirstName",
    "uLastName",
    "uEmail",
    "uPassword",
    "uPassword2",
    "acceptedDataPolicy",
    "acceptedAGB",
  ];

  const numFields = ["cStreetNum", "cZip"];
  const strNumFields = ["cPhoneNumber", "cFaxNumber"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld";
    }
  });

  // if (values.cIndustryRole === "Fertiger") {
  //   if (!values.acceptedPayment) {
  //     errors.acceptedPayment = "Pflichtfeld";
  //   }
  // }

  if (values.uPassword2 !== values.uPassword) {
    errors.uPassword2 = "Passwort stimmt nicht überein";
  }
  if (!values.uPassword?.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)) {
    errors.uPassword =
      "Ihr Passwort muss 6-20 Zeichen, mindestens einen Großbuchstaben, mindestens einen Kleinbuchstaben, und mindestens eine Ziffer enthalten.";
  }

  if (
    !values.uEmail?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    errors.uEmail = "Bitte verwenden Sie eine gültige E-Mail Adresse";
  }
  strNumFields.forEach((field) => {
    if (
      values[field] &&
      !values[field].match(
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d+)\)?)[-. \\/]?)?((?:\(?\d{1,}\)?[ \\]?){0,})(?:[ \\]?(?:#|ext\.?|extension|x)[\\]?(\d+))?$/i
      )
    ) {
      errors[field] = "ungültige Eingabe";
    }
  });

  if (values.logo) {
    if (values.logo[0]) {
      if (values.logo[0].size > 4 * 1024 * 1024) {
        // const logo = values.logo[0];
        errors["logo"] =
          "Zu große Datei. Die maximale Dateigröße beträgt 4 MB.";
      }
    }
  }

  numFields.forEach((field) => {
    if (values[field] <= 0) {
      errors[field] = "Ungültig";
    }
  });
  return errors;
};

export default reduxForm({
  form: "RegisterForm", // a unique identifier for this form
  validate,
})(RegisterForm);
