import React from "react";
import ChatNav from "./ChatNav";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChatBox from "./ChatBox";

function Conversations() {
  const smAndUp = useMediaQuery("(min-width:655px)");
  return (
    <div
      style={{
        display: "flex",
        height: "calc(100vh - 70px)",
        flexDirection: smAndUp ? "row" : "column",
      }}
    >
      <div item style={{ maxWidth: "100%" }}>
        <Paper
          elevation={0}
          sx={{
            height: smAndUp ? `100%` : "auto",
          }}
        >
          <ChatNav />
        </Paper>
      </div>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          maxHeight: "100%",
        }}
      >
        <ChatBox />
      </div>
    </div>
  );
}

export default Conversations;
