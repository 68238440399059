import getDistance from "../../utils/getDistance";
import { ordersAPI } from "../../apis/orders";

const loading = "orders/search/loading";
const loaded = "orders/search/loaded";
const error = "orders/search/error";
const changeOrders = "orders/search/changeQuery";

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  orders: [],
  ordersAPI: [],
};

const formatOrders = (orders, myCompanyCoordinates) => {
  return orders.map((order) => {
    return {
      ...order,
      distance: getDistance(
        order.geometry[0],
        order.geometry[1],
        myCompanyCoordinates[0],
        myCompanyCoordinates[1]
      ),
    };
  });
};

export const searchOrders = (myCompanyCoordinates) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await ordersAPI.get(
      `/?offersDeadline[$gt]=${Date.now()}&status=published`
    );
    if (response.data.status === "success") {
      dispatch({
        type: loaded,
        payload: formatOrders(response.data.orders, myCompanyCoordinates),
      });
    } else {
      dispatch({
        type: error,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: error,
      payload: err.response.data,
    });
  }
};
export const expiredOrders = (myCompanyCoordinates) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await ordersAPI.get(
      `/?status=expired`
    );
    if (response.data.status === "success") {
      dispatch({
        type: loaded,
        payload: formatOrders(response.data.orders, myCompanyCoordinates),
      });
    } else {
      dispatch({
        type: error,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: error,
      payload: err.response.data,
    });
  }
};
// export const setOrders = (orders) => async (dispatch) => {
//   dispatch({ type: changeOrders, payload: orders });
// };
// export const updateOrders = (params) => async (dispatch) => {
//   dispatch({ type: loading });
//   try {
//     const response = await ordersAPI.get("/", { params });
//     if (response.data.success) {
//       dispatch({
//         type: loaded,
//         payload: response.data.orders,
//       });
//     } else {
//       dispatch({
//         type: error,
//         payload: response.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: error,
//       payload: err.response.data,
//     });
//   }
// };
const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, ...initialState, loading: true };
    case loaded:
      return {
        ...state,
        loaded: true,
        loading: false,
        orders: action.payload,
        ordersAPI: action.payload,
      };
    case changeOrders:
      return {
        ...state,
        orders: action.payload,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
