import React from "react";
import SubArticle from "./SubArticle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const a11List = [
  "gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;",
  "gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder die Vervollständigung Ihrer bei uns gespeicherten Daten zu verlangen;",
  "gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;",
  "gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird oder die Verarbeitung unrechtmäßig ist;",
  'gemäß Art. 20 DSGVO Ihre Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen ("Datenübertragbarkeit");',
  "gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung einzulegen, sofern die Verarbeitung aufgrund von Art. 6 Abs. 1 S. 1 lit. e oder lit. f DSGVO erfolgt. Dies ist insbesondere der Fall, wenn die Verarbeitung nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist. Sofern es sich nicht um einen Widerspruch gegen Direktwerbung handelt, bitten wir bei Ausübung eines solchen Widerspruchs um die Darlegung der Gründe, weshalb wir Ihre Daten nicht wie von uns durchgeführt verarbeiten sollen. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen;",
  "gemäß Art. 7 Abs. 3 DSGVO Ihre einmal (auch vor der Geltung der DSGVO, dh vor dem 25.5.2018) erteilte Einwilligung – also Ihr freiwilliger, in informierter Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung verständlich gemachter Willen, dass Sie mit der Verarbeitung der betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden sind – jederzeit uns gegenüber zu widerrufen, falls Sie eine solche erteilt haben. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und",
];
const a11subList = [
  "Kavalleriestr. 2-4",
  "40213 Düsseldorf",
  "Telefon: +49 (0)211/38424-0",
  "E-Mail: poststelle@ldi.nrw.de",
  "Website: https://www.ldi.nrw.de/",
];

function A11() {
  return (
    <SubArticle title="(11) Ihre Rechte">
      <Typography>
        Ihre Rechte als Betroffener bezüglich Ihrer verarbeiteten
        personenbezogenen Daten können Sie uns gegenüber unter den eingangs
        unter A.(2) angegebenen Kontaktdaten jederzeit geltend machen. Sie haben
        als Betroffener das Recht:
      </Typography>
      <List>
        {a11List.map((article, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText sx={{ ml: { xs: "-2rem", sm: "0" } }}>
                {article}
              </ListItemText>
            </ListItem>
          );
        })}
        <ListItem>
          <ListItemIcon>-</ListItemIcon>
          <ListItemText sx={{ ml: { xs: "-2rem", sm: "0" } }}>
            gemäß Art. 77 DSGVO sich bei einer Datenschutz-Aufsichtsbehörde über
            die Verarbeitung Ihrer personenbezogenen Daten in unserem
            Unternehmen zu beschweren, etwa bei der für uns zuständigen
            Datenschutz-Aufsichtsbehörde in Nordrhein-Westfalen:
            {/* <List> */}
            {a11subList.map((article, index) => {
              return (
                <p key={index} style={{ marginLeft: "1rem" }}>
                  {article}
                </p>
              );
            })}
            {/* </List> */}
          </ListItemText>
        </ListItem>
      </List>
    </SubArticle>
  );
}

export default A11;
