import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";

import { verifyUser } from "../../redux/ducks/verifyUser";
import { openSnack } from "../../redux/ducks/snackbar";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

function VerifyUser() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { loaded, loading, error } = useSelector((s) => s.verifyUser);

  React.useEffect(() => {
    if (loaded) {
      dispatch(openSnack("Ihr Benutzerkonto wurde erfolgreich verifiziert"));
    }
  });
  React.useEffect(() => {
    dispatch(verifyUser(token));
  }, [dispatch, token]);
  return (
    <Box
      sx={{
        height: "50vh",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <CircularProgress />}
        {error && <Alert severity="error">{error.message}</Alert>}
        {loaded && <Redirect to="/companies/my" />}
      </div>
    </Box>
  );
}

export default VerifyUser;
