import React from "react";
import { useDispatch } from "react-redux";

// MUI
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    // color: theme.palette.primary.main,
  },
  countContainer: {
    border: "solid 1px",
    borderRadius: "50%",
    padding: "0 0.5rem",
    marginLeft: "0.5em",
  },
}));

const TableTabs = ({ tabs, activeTab, setActiveTab }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  function tabProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tab-panel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    dispatch(setActiveTab(newValue));
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        variant="scrollable"
      >
        {tabs.map((tab, index) => {
          return (
            <Tab
              label={
                <div>
                  {tab.label}
                  <span className={classes.countContainer}>{tab.count}</span>
                </div>
              }
              {...tabProps(index)}
              disabled={!tab.count}
              key={index}
            />
          );
        })}
      </Tabs>
    </AppBar>
  );
};

export default TableTabs;
