import React from "react";

import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import LoginForm from "./Form";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    // minWidth: "30%",
    marginTop: theme.spacing(8),
    marginBottom: "1em",
  },
}));
const Login = () => {
  const classes = useStyles();
  return (
    // <div style={divStyle}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {/* <div
        
        // style={{
        //   // padding: "10px",
        //   // minWidth: "30%",
        //   // marginTop: "2em",
        //   // marginBottom: "1em",
        //   // display: "flex",
        //   // justfiyContent: "center",
        //   // alignItem: "center",

        // }}
      > */}
      <Paper className={classes.paper}>
        <LoginForm />
      </Paper>
    </Container>
    // </div>
  );
};

export default Login;
