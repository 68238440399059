import getDistance from "../../utils/getDistance";
import offersAPI from "../../apis/offers";
import { ordersAPI } from "../../apis/orders";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  order: null,
  deleteDialog: {
    open: false,
    neutral: true,
    loading: false,
    loaded: false,
    error: false,
  },
  offerDialog: {
    open: false,
    loading: false,
    loaded: false,
    error: false,
  },
};

const loading = "orders/orderView/loading";
const loaded = "orders/orderView/loaded";
const err = "orders/orderView/error";
const errObj = (response) => {
  return {
    type: err,
    payload: {
      message: response.data.message,
      code: response.status,
      statusText: response.statusText,
    },
  };
};

const deleteOpen = "orders/orderView/deleteDialog/open";
const deleteClose = "orders/orderView/deleteDialog/close";
const deleteLoading = "orders/orderView/deleteDialog/loading";
const deleteLoaded = "orders/orderView/deleteDialog/loaded";
const deleteError = "orders/orderView/deleteDialog/error";
const offerOpen = "orders/orderView/offerDialog/open";
const offerClose = "orders/orderView/offerDialog/close";
const offerLoading = "orders/orderView/offerDialog/loading";
const offerLoaded = "orders/orderView/offerDialog/loaded";
const offerError = "orders/orderView/offerDialog/error";
const clean = "orders/orderView/cleanUp";
const cleanOfferDialog = "orders/orderView/cleanUpOffer";

const formatOrder = (order, myCompanyCoordinates) => ({
  ...order,
  distance: getDistance(
    order.geometry[0],
    order.geometry[1],
    myCompanyCoordinates[0],
    myCompanyCoordinates[1]
  ),
});

export const getOrder = (id, myCompanyCoordinates) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await ordersAPI.get(`/${id}`);
    switch (response.data.status) {
      case "success":
        return dispatch({
          type: loaded,
          payload: {
            order: formatOrder(response.data.data.order, myCompanyCoordinates),
          },
        });
      default:
        return dispatch(errObj(response));
    }
  } catch (error) {
    dispatch({ type: err, payload: error });
  }
};

export const sendOffer = (orderId, offerValues) => async (dispatch) => {
  dispatch({ type: offerLoading });
  try {
    const getFormData = (values) => {
      const formData = new FormData();

      const valuesKeys = Object.keys(values).filter((item) => item !== "files");
      valuesKeys.forEach((key) => formData.append(key, values[key]));

      const files = values.files;

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      }
      return formData;
    };
    const data = getFormData(offerValues);
    const response = await offersAPI.post(`/order/${orderId}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    switch (response.data.status) {
      case "success":
        return dispatch({
          type: offerLoaded,
        });
      default:
        return dispatch({
          type: offerError,
          payload: { status: "error", message: response.data.message },
        });
    }
  } catch (error) {
    dispatch({ type: offerError, payload: error });
  }
};

export const openDialog = () => (dispatch) => {
  dispatch({ type: deleteOpen });
};
export const openOfferDialog = () => (dispatch) => {
  dispatch({ type: offerOpen });
};
export const closeOfferDialog = () => (dispatch) => {
  dispatch({ type: offerClose });
};

export const closeDialog = () => (dispatch) => {
  dispatch({ type: deleteClose });
};

export const deleteOrder = (id) => async (dispatch) => {
  dispatch({ type: deleteLoading });
  try {
    const response = await ordersAPI.delete(`/${id}`);
    switch (response.data.status) {
      case "success":
        return dispatch({
          type: deleteLoaded,
        });
      default:
        return dispatch({
          type: deleteError,
          payload: { message: response.data.message },
        });
    }
  } catch (error) {
    dispatch({ type: deleteError, payload: error });
  }
};

export const updateOrder = (id, deliveryDate, offerDate) => async (dispatch) => {
  try {
    const response = await ordersAPI.put(`/${id}`, {
      deliveryDeadline: deliveryDate.split("/").reverse().join("-"),
      offersDeadline: offerDate.split("/").reverse().join("-"),
      updatedAt: new Date(),
    });
    console.log(response, "RESPONSE UPD");
  } catch (error) {
    dispatch({ type: deleteError, payload: error });
  }
};
export const cleanUp =
  (offerDialog = false) =>
  (dispatch) => {
    if (offerDialog) {
      return dispatch({ type: cleanOfferDialog });
    } else {
      dispatch({ type: clean });
    }
  };
const orderViewReducer = (state = initialState, action) => {
  switch (action.type) {
    // Root
    case loading:
      return { ...state, loading: true };
    case loaded:
      return {
        ...state,
        loading: false,
        loaded: true,
        order: action.payload.order,
      };
    case err:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };

    // Offer Dialog
    case offerOpen:
      return {
        ...state,
        offerDialog: {
          ...state.offerDialog,
          open: true,
        },
      };
    case offerClose:
      return {
        ...state,
        offerDialog: {
          ...state.offerDialog,
          open: false,
        },
      };
    case offerLoading:
      return {
        ...state,
        offerDialog: {
          ...state.offerDialog,
          loading: true,
        },
      };
    case offerLoaded:
      return {
        ...state,
        order: { ...state.order, hasSentOffer: true },
        offerDialog: {
          ...state.offerDialog,
          loading: false,
          loaded: true,
          error: false,
          open: false,
        },
      };
    case offerError:
      return {
        ...state,
        offerDialog: {
          ...state.offerDialog,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case cleanOfferDialog:
      return {
        ...state,
        offerDialog: {
          open: false,
          loading: false,
          loaded: false,
          error: false,
        },
      };

    // Delete Dialog
    case deleteOpen:
      return {
        ...state,
        deleteDialog: { ...state.deleteDialog, open: true },
      };
    case deleteClose:
      return {
        ...state,
        deleteDialog: { ...initialState.deleteDialog },
      };
    case deleteLoading:
      return {
        ...state,
        deleteDialog: { ...state.deleteDialog, loading: true, neutral: false },
      };
    case deleteLoaded:
      return {
        ...state,
        deleteDialog: {
          ...state.deleteDialog,
          loading: false,
          loaded: true,
          open: false,
        },
      };
    case deleteError:
      return {
        ...state,
        deleteDialog: {
          ...state.deleteDialog,
          loading: false,
          loaded: false,
          neutral: false,
          error: action.payload,
        },
      };

    case clean:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default orderViewReducer;
