import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {DatePicker} from 'antd';
import TodayIcon from "@mui/icons-material/Today";

function ViewTable({ title = "", exists = false, data = [], className, handleDeliveryDateChange, handleOfferDateChange, deliveryDate, offerDate }) {

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  
  return (
    <div className={className}>
      <Typography variant="h6">{title}</Typography>
      <table>
        <tbody>
          {exists &&
            data.map((row, index) => {
              return (
                <tr key={index}>
                  <td style={{ minWidth: "160px" }}>
                    <Typography variant="subtitle2">{row.key}:</Typography>
                  </td>
                  <td>
                    <Typography variant="subtitle1">
                      {row.value ? row.value : "-"}
                    </Typography>
                  </td>
                  <td style={{position: "relative"}}>
                    {(index === 1 && title === "Auftrag Zusammenfassung") && <div style={{display: "flex", position: "absolute", top: "0"}}>
                      <div onClick={() => setOpenDatePicker(!openDatePicker)}><TodayIcon /></div>
                      {openDatePicker && <div style={{position: "relative", top: "-40px"}}><DatePicker
                          open
                          className="hidden-datepicker"
                          fullscreen={false}
                          onChange={(date) => {
                            let data = (date.$M + 1) +"/"+date.$D+"/"+date.$y
                            handleDeliveryDateChange(data)
                            setOpenDatePicker(false)
                          }}
                          format="DD/MM/YYYY"
                        /></div>}
                    </div>}
                    {(index === 2 && title === "Auftrag Zusammenfassung") && <div style={{display: "flex", position: "absolute", top: "0"}}>
                      <div onClick={() => setOpenOffer(!openOffer)}><TodayIcon /></div>
                      {openOffer && <div style={{position: "relative", top: "-40px"}}><DatePicker
                          open
                          className="hidden-datepicker"
                          fullscreen={false}
                          onChange={(date) => {
                            let data = (date.$M + 1) +"/"+date.$D+"/"+date.$y
                            handleOfferDateChange(data)
                            setOpenOffer(false)
                          }}
                          format="DD/MM/YYYY"
                        /></div>}
                    </div>} 
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default ViewTable;
