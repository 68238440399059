import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// MUI
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
// Icons
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
//
import { logout } from "../../../../redux/ducks/auth";

const AccountMenu = ({ anchorEl, handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const open = Boolean(anchorEl);
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem
        onClick={() => {
          history.push("/settings");
        }}
      >
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        {"Einstellungen"}
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(logout());
        }}
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {"Abmelden"}
      </MenuItem>
    </Menu>
  );
};

export default AccountMenu;
