import React from "react";

// MUI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF7F1",
    padding: theme.spacing(6),
  },
  wrapper: {
    justifyContent: "space-around",
  },
  h2: {
    fontSize: "3em",
    marginBottom: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
  },
}));
function Section5({ manufacturer }) {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Typography variant="h2" className={classes.h2} color="primary">
              {"Smarter wird´s nicht: Order-Scout"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            container
            direction="column"
            className={classes.wrapper}
            spacing={2}
          >
            <Grid item>
              <Typography>
                {!manufacturer
                  ? "Nutzen Sie als Einkäufer direkt und unmittelbar alle Vorteile von digitalen Ausschreibungen auf Order-Scout. Sie arbeiten schneller, kaufen günstiger ein und erhalten Top-Qualität. Erfahrene Support-Spezialisten aus dem CNC-Umfeld stehen Ihnen bei jedem Projektschritt mit Rat und Tat zur Seite."
                  : "Steigern Sie Ihren Geschäftserfolg als CNC-Hersteller! Mit Order-Scout an Ihrer Seite verbringen Sie weniger Zeit mit Akquisitionstätigkeiten und sind trotzdem besser ausgelastet."}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {!manufacturer
                  ? "Den optimalen CNC-Fertiger finden: Das geht auf Order-Scout unübertroffen schnell, einfach und gezielt. Denn Order-Scout ist der eine Scout für alle Anwendungsfälle."
                  : "Außerdem fallen für Sie keine Kosten für eine Provision an, wenn Sie einen CNC-Auftrag annehmen wollen. Profitieren Sie von einem Netzwerk von Unternehmen aus der CNC-Branche!"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Section5;
