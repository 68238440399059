import stripeAPI from "../../apis/stripe";
const initialState = {
  loading: false,
  loaded: false,
  error: null,
  checkoutURL: null,
};

const loading = "users/unsubscribed/loading";
const loaded = "users/unsubscribed/loaded";
const error = "users/unsubscribed/error";
const clean = "users/unsubscribed/cleanUp";

export const createCheckOut = (values) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await stripeAPI.post("/checkout", values);
    if (response.data.status === "success") {
      dispatch({ type: loaded, payload: response.data.data.checkoutURL });
    } else {
      dispatch({ type: error, payload: response.data });
    }
  } catch (e) {
    dispatch({
      type: error,
      payload: e.response?.data || e,
    });
  }
};

export const cleanUp = () => (dispatch) => {
  dispatch({ type: clean });
};

const unsubscribedReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        checkoutURL: null,
      };
    case loaded:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        checkoutURL: action.payload,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        checkoutURL: null,
      };
    case clean:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default unsubscribedReducer;
