import React from "react";
import SubArticle from "./SubArticle";
import Typography from "@mui/material/Typography";
import ExternalLink from "../shared/ExternalLink";

function A4to10() {
  return (
    <React.Fragment>
      <SubArticle title="(4) Datenlöschung und Speicherdauer">
        <Typography>
          Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im
          Folgenden jeweils an, wie lange die Daten bei uns gespeichert und wann
          sie gelöscht oder gesperrt werden. Soweit nachfolgend keine
          ausdrückliche Speicherdauer angegeben wird, werden Ihre
          personenbezogenen Daten gelöscht oder gesperrt, sobald der Zweck oder
          die Rechtsgrundlage für die Speicherung entfällt. Eine Speicherung
          Ihrer Daten erfolgt grundsätzlich nur auf unseren Servern in
          Deutschland, vorbehaltlich einer ggf. erfolgenden Weitergabe nach den
          Regelungen in A.(7) und A.(8).Eine Speicherung kann jedoch über die
          angegebene Zeit hinaus im Falle einer (drohenden) Rechtsstreitigkeit
          mit Ihnen oder eines sonstigen rechtlichen Verfahrens erfolgen oder
          wenn die Speicherung durch gesetzliche Vorschriften, denen wir als
          Verantwortlicher unterliegen (zB § 257 HGB, § 147 AO), vorgesehen ist.
          Wenn die durch die gesetzlichen Vorschriften vorgeschriebene
          Speicherfrist abläuft, erfolgt eine Sperrung oder Löschung der
          personenbezogenen Daten, es sei denn, dass eine weitere Speicherung
          durch uns erforderlich ist und dafür eine Rechtsgrundlage besteht.
        </Typography>
      </SubArticle>
      <SubArticle title="(5) Datensicherheit">
        <Typography>
          Wir bedienen uns geeigneter technischer und organisatorischer
          Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche
          Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder
          gegen den unbefugten Zugriff Dritter zu schützen (zB
          TSL-Verschlüsselung für unsere Webseite) unter Berücksichtigung des
          Stands der Technik, der Implementierungskosten und der Natur, des
          Umfangs, des Kontextes und des Zwecks der Verarbeitung sowie der
          bestehenden Risiken einer Datenpanne (inklusive von deren
          Wahrscheinlichkeit und Auswirkungen) für den Betroffenen. Unsere
          Sicherheitsmaßnahmen werden entsprechend der technologischen
          Entwicklung fortlaufend verbessert. Nähere Informationen hierzu
          erteilen wir Ihnen auf Anfrage gerne. Wenden Sie sich hierzu bitte an
          unseren Datenschutzbeauftragten (siehe unter A.(3)).
        </Typography>
      </SubArticle>
      <SubArticle title="(6) Zusammenarbeit mit Auftragsverarbeitern">
        <Typography>
          Wie bei jedem größeren Unternehmen, setzen auch wir zur Abwicklung
          unseres Geschäftsverkehrs externe in- und ausländische Dienstleister
          ein (z.B. für die Bereiche IT, Logistik, Telekommunikation, Vertrieb
          und Marketing). Diese werden nur nach unserer Weisung tätig und wurden
          i.S.v. Art. 28 DSGVO vertraglich dazu verpflichtet, die
          datenschutzrechtlichen Bestimmungen einzuhalten. Sofern
          personenbezogene Daten von Ihnen durch uns an unsere
          Tochtergesellschaften weitergegeben werden oder von unseren
          Tochtergesellschaften an uns weitergegeben werden (z.B. zu werblichen
          Zwecken), geschieht dies aufgrund von bestehenden
          Auftragsverarbeitungsverhältnissen.
        </Typography>
      </SubArticle>
      <SubArticle title="(7) Voraussetzungen der Weitergabe von personenbezogenen Daten in Drittländer">
        <Typography>
          Im Rahmen unserer Geschäftsbeziehungen können Ihre personenbezogenen
          Daten an Drittgesellschaften weitergegeben oder offengelegt werden.
          Diese können sich auch außerhalb des Europäischen Wirtschaftsraums
          (EWR), also in Drittländern, befinden. Eine derartige Verarbeitung
          erfolgt ausschließlich zur Erfüllung der vertraglichen und
          geschäftlichen Verpflichtungen und zur Pflege Ihrer Geschäftsbeziehung
          zu uns. Über die jeweiligen Einzelheiten der Weitergabe unterrichten
          wir Sie nachfolgend an den dafür relevanten Stellen. Einigen
          Drittländern bescheinigt die Europäische Kommission durch sog.
          Angemessenheitsbeschlüsse einen Datenschutz, der dem EWR-Standard
          vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der
          Angemessenheitsbeschlüsse erhalten Sie{" "}
          <ExternalLink to="http://ec.europa.eu/justice/data-protection/internationaltransfers/adequacy/index_en.html">
            hier
          </ExternalLink>
          ). In anderen Drittländern, in die ggf. personenbezogene Daten
          übertragen werden, herrscht aber unter Umständen wegen fehlender
          gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau.
          Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz
          ausreichend gewährleistet ist. Möglich ist dies über bindende
          Unternehmensvorschriften, Standard-Vertragsklauseln der Europäischen
          Kommission zum Schutz personenbezogener Daten, Zertifikate oder
          anerkannte Verhaltenskodizes. Bitte wenden Sie sich an unseren
          Datenschutzbeauftragten (siehe unter A.(3)), wenn Sie hierzu nähere
          Informationen erhalten möchten.
        </Typography>
      </SubArticle>
      <SubArticle title="(8) Keine automatisiere Entscheidungsfindung (einschließlich Profiling)">
        <Typography>
          Wir haben nicht die Absicht, von Ihnen erhobene personenbezogene Daten
          für ein Verfahren zur automatisierten Entscheidungsfindung
          (einschließlich Profiling) zu verwenden.
        </Typography>
      </SubArticle>
      <SubArticle title="(9) Keine Verpflichtung zur Bereitstellung personenbezogener Daten">
        <Typography>
          Wir machen den Abschluss von Verträgen mit uns nicht davon abhängig,
          dass Sie uns zuvor personenbezogene Daten bereitstellen. Für Sie als
          Kunde besteht grundsätzlich auch keine gesetzliche oder vertragliche
          Verpflichtung, uns Ihre personenbezogenen Daten zur Verfügung zu
          stellen; es kann jedoch sein, dass wir bestimmte Angebote nur
          eingeschränkt oder gar nicht erbringen können, wenn Sie die dafür
          erforderlichen Daten nicht bereitstellen. Sofern dies im Rahmen der
          nachfolgend vorgestellten, von uns angebotenen Produkte ausnahmsweise
          der Fall sein sollte, werden Sie gesondert darauf hingewiesen.
        </Typography>
      </SubArticle>
      <SubArticle title="(10) Gesetzliche Verpflichtung zur Übermittlung bestimmter Daten">
        <Typography>
          Wir können unter Umständen einer besonderen gesetzlichen oder
          rechtlichen Verpflichtung unterliegen, die rechtmäßig verarbeiteten
          personenbezogenen Daten für Dritte, insbesondere öffentlichen Stellen,
          bereitzustellen (Art. 6 Abs. 1 S. 1 lit. c DSGVO).
        </Typography>
      </SubArticle>
    </React.Fragment>
  );
}

export default A4to10;
