import React from "react";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  step: {
    backgroundColor: "white",
    width: "345px",
    // height: "250px",
    borderRadius: "32px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    padding: "4em 20px 20px 20px",
  },
  upperNum: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: "-35px",
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  icon: {
    "& > :first-child": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      fontSize: "3.2rem",
    },
  },
}));

const StepBox = ({ num, icon, text }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.step}>
      <Typography style={{ color: "white" }} className={classes.upperNum}>
        {num}
      </Typography>
      <div className={classes.icon}>{icon}</div>
      <Typography color="primary" align="center">
        {text}
      </Typography>
    </Grid>
  );
};

export default StepBox;
