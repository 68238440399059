import React from "react";
//
import Form from "./Form";
import ContactInfoBox from "./ContactInfoBox";
// MUI
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function ContactPage() {
  return (
    <Container sx={{ mt: 2 }}>
      <Typography align="center" variant="h2" component={"h1"}>
        {"Kontaktieren Sie uns!"}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {
          "Haben Sie noch Fragen? Kontaktieren Sie uns über das untenstehende Formular oder über die Methode Ihrer Wahl."
        }
      </Typography>
      <Paper sx={{ mb: 2, mt: 2 }}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Form />
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactInfoBox />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default ContactPage;
