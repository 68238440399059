import React from "react";
import { useSelector, useDispatch } from "react-redux";

import SortedTable from "../SortedTable";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import PageTitle from "../shared/PageTitle";

import { getOrders, setActiveTab, getExpiredOrders } from "../../redux/ducks/myOrders";
import TableTabs from "../shared/TableTabs";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    [theme.breakpoints.up("sm")]: {
      margin: "2rem",
    },
  },
  toolbar: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  errorDiv: {
    minHeight: "500px",
    marginTop: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

const MyExpiredOrders = () => {
  const classes = useStyles();
  const company = useSelector((state) => state.auth.company._id);
  const { orders, loading, error, selectedOrders, activeTab } = useSelector(
    (state) => state.myOrders
  );
  const dispatch = useDispatch();

  // TableTabs
  const tabs = [
    { label: "alle", count: orders.all.length },
    { label: "veröffentlicht", count: orders.published.length },
    { label: "zugeordnet", count: orders.allocated.length },
    { label: "archiviert", count: orders.archived.length },
  ];
  React.useEffect(() => {
    dispatch(getExpiredOrders(company));
  }, [company, dispatch]);
  React.useEffect(() => {
    return () => {
      dispatch(setActiveTab(0));
    };
  }, [dispatch]);
  return (
    <React.Fragment>
      <PageTitle text="Abgelaufene Aufträge" />
      {error ? (
        <div className={classes.errorDiv}>
          <Typography variant="h6">
            {"Etwas ist schief gelaufen."}
            <br />
            {error.message}
          </Typography>
        </div>
      ) : (
        <div className={classes.searchContainer}>
          <SortedTable orders={selectedOrders} isLoading={loading} />
        </div>
      )}
    </React.Fragment>
  );
};

export default MyExpiredOrders;
