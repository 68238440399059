import React from "react";
import { makeStyles } from "@mui/styles";
import PageTitle from "../../shared/PageTitle";

import Typography from "@mui/material/Typography";

import { useDispatch, useSelector } from "react-redux";
import { getOffers, setActiveTab } from "../../../redux/ducks/receivedOffers";

import SortableTable from "./SortableTable";
import TableTabs from "../../shared/TableTabs";

const useStyles = makeStyles((theme) => ({
  offersContainer: {
    [theme.breakpoints.up("sm")]: {
      margin: "2rem",
    },
  },
  errorDiv: {
    minHeight: "500px",
    marginTop: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

const ReceivedOffers = () => {
  const classes = useStyles();

  const headCells = [
    {
      id: "orderTitle",
      numeric: false,
      disablePadding: false,
      label: "Auftrag Titel",
      disableSorting: true,
    },
    {
      id: "companyName",
      numeric: false,
      disablePadding: false,
      label: "Firma",
    },
    { id: "price", numeric: true, disablePadding: false, label: "Preis" },
    {
      id: "deliveryDate",
      numeric: false,
      disablePadding: false,
      label: "Lieferdatum",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Gesendet am",
    },
  ];

  const dispatch = useDispatch();
  const company = useSelector((state) => state.auth.company._id);
  const { loading, error, selectedOffers, activeTab, offers } = useSelector(
    (state) => state.receivedOffers
  );

  // TableTabs
  const tabs = [
    { label: "alle", count: offers.all.length },
    { label: "offen", count: offers.open.length },
    { label: "akzeptiert", count: offers.accepted.length },
    { label: "abgelehnt", count: offers.rejected.length },
    //{ label: "archiviert", count: offers.archived.length },
  ];

  //
  React.useEffect(() => {
    dispatch(getOffers(company));
  }, [company, dispatch]);
  //
  React.useEffect(() => {
    return () => {
      dispatch(setActiveTab(0));
    };
  }, [dispatch]);

  //
  return (
    <div>
      <PageTitle text="Eingegangene Angebote" />
      <div className={classes.offersContainer}>
        {error ? (
          <div className={classes.errorDiv}>
            <Typography variant="h6">
              {"Etwas ist schief gelaufen."}
              <br />
              {error.message}
            </Typography>
          </div>
        ) : (
          <div>
            <TableTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <SortableTable
              headCells={headCells}
              rows={selectedOffers}
              isLoading={loading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceivedOffers;
