import React from "react";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function Unverified() {
  const { isAuthenticated, isVerified, loading } = useSelector((s) => s.auth);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "40vh",
      }}
    >
      <Alert severity={isVerified ? "success" : "warning"} icon={false}>
        {isVerified ? (
          <Typography>
            Ihr Konto ist bereits verifiziert und Sie können die
            Order-Scout-Dienste bereits in Anspruch nehmen.
          </Typography>
        ) : (
          <div>
            <Typography align="center">
              Willkommen bei Order-Scout, Ihre E-Mail Adresse wurde noch nicht
              verifiziert.
            </Typography>
            <Typography align="center">
              Bitte gehen Sie zu Ihrer E-Mail und klicken Sie auf den
              Verifizierungslink, den wir Ihnen geschickt haben.
            </Typography>
          </div>
        )}
      </Alert>
    </div>
  );
}

export default Unverified;
