import React from "react";
import { makeStyles } from "@mui/styles";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";

import ViewTable from "./ViewTable";
// import companyLogo from "../../../Assets/images/CompanyLogoEx.jpg";
import companyNoLogo from "../../../Assets/images/CompanyNoLogo.png";
import Link from "../../shared/Link";
import ExternalLink from "../../shared/ExternalLink";
import ContactButton from "../../shared/ContactButton";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "200px",
    maxHeight: "100%",
  },
}));

const CompanyContact = ({ offer, isReceiver }) => {
  const classes = useStyles();
  const { company, receivingCompany } = offer;
  let contactCompanyData = [];
  let companyLink = null;
  let companyId = null;
  if (isReceiver) {
    contactCompanyData = [
      {
        key: "Telefonnummer",
        value: company.phoneNumber,
      },
      // {
      //   key: "Email",
      //   value: "-",
      // },
      {
        key: "Webseite",
        value: company.website ? (
          <ExternalLink
            to={`https://www.google.com/search?q=${company.website}`}
          >
            {company.website}
          </ExternalLink>
        ) : (
          "-"
        ),
      },
    ];
    companyLink = `/companies/${company._id}`;
    companyId = company._id;
  } else {
    contactCompanyData = [
      {
        key: "Telefonnummer",
        value: receivingCompany.phoneNumber,
      },
      // {
      //   key: "Email",
      //   value: "-",
      // },
      {
        key: "Webseite",
        value: receivingCompany.website ? (
          <ExternalLink
            to={`https://www.google.com/search?q=${receivingCompany.website}`}
          >
            {receivingCompany.website}
          </ExternalLink>
        ) : (
          "-"
        ),
      },
    ];
    companyLink = `/companies/${receivingCompany._id}`;
    companyId = receivingCompany._id;
  }
  return (
    <Paper>
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item className={classes.title}>
          {isReceiver ? "Gesendet von" : "Gesendet an"}
        </Grid>
        <Grid item>
          <img
            src={
              isReceiver
                ? company.logo
                  ? `/files/${company.logo.name}`
                  : companyNoLogo
                : receivingCompany.logo
                ? `/files/${receivingCompany.logo.name}`
                : companyNoLogo
            }
            alt="Company Logo"
            width="100%"
            className={classes.img}
          />
        </Grid>
        <Grid item>{isReceiver ? company.name : receivingCompany.name}</Grid>
        <Grid item maxWidth={"100%"}>
          <ViewTable data={contactCompanyData} exists />
        </Grid>
        <Grid item>
          <ContactButton companyId={companyId} />
        </Grid>
        <Grid item>
          <Link to={companyLink}>{"Komplettes Firmenprofil"}</Link>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyContact;
