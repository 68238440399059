import React from "react";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cleanCreate, createConv } from "../../redux/ducks/conversations";
import { openSnack } from "../../redux/ducks/snackbar";

function ContactButton({ companyId, ...otherProps }) {
  const history = useHistory();
  const newConvId = useSelector((s) => s.conversations.create.conversationId);
  const error = useSelector((s) => s.conversations.create.error);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (newConvId) {
      history.push(`/conversations?conversationId=${newConvId}`);

      return () => {
        dispatch(cleanCreate());
      };
    }
  });
  React.useEffect(() => {
    if (error) {
      dispatch(openSnack(error.message, "error"));
    }
  }, [dispatch, error]);
  return (
    <Button
      variant="contained"
      onClick={() => {
        dispatch(createConv({ companyId }));
      }}
      {...otherProps}
    >
      Firma Kontaktieren
    </Button>
  );
}

export default ContactButton;
