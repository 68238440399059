import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { deDE } from "@mui/material/locale";

let theme = createTheme(
  {
    palette: {
      primary: {
        main: "#EC4B21",
      },
      secondary: {
        main: "#2B50AA",
      },
      common: {
        seashell: "#FFF7F1",
        canvas: "#fefcfb",
      },
    },
    typography: {
      fontFamily: ["Exo", "sans-serif"].join(","),
      fontStyle: "normal",
      fontDisplay: "swap",
      // h1: {
      //   fontSize: "1rem",
      //   // [theme.breakpoints.down("sm")]: { fontSize: "1.3rem" },
      // },
      h2: {
        fontSize: "2.75rem",
        fontWeight: "400",
      },
      h3: {
        fontSize: "2.5rem",
        fontWeight: "300",
      },
    },
  },
  deDE
);

theme = responsiveFontSizes(theme);
theme.typography.h1 = {
  fontWeight: "500",
  fontSize: "1.7rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
  },
};

export default theme;
