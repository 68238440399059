import { usersAPI } from "../../apis/users";
import socket from "../../helpers/socket";

const initialState = {
  loading: true,
  isAuthenticated: null,
  isVerified: false,
  subscription: null,
  user: null,
  company: null,
  error: null,
};

export const loadCurrentUser = () => async (dispatch) => {
  try {
    const response = await usersAPI.get("/current");
    const user = response.data.data.user;
    const userID = user._id;
    const company = user.company;
    const isVerified = user.isVerified;
    const subscription = user.subscription;
    socket.connect();
    dispatch({
      type: "auth/login",
      payload: { userID, company, isVerified, subscription },
    });
  } catch (err) {
    localStorage.clear();
    dispatch({
      type: "auth/logout",
    });
    dispatch({
      type: "auth/error",
      payload: err,
    });
  }
};
export const logout =
  (serverLogOut = true) =>
  async (dispatch) => {
    try {
      if (serverLogOut) {
        socket.emit("logout");
        socket.disconnect();
        await usersAPI.get("/logout");
      }
      localStorage.removeItem("user");
      localStorage.removeItem("company");
      dispatch({
        type: "auth/logout",
      });
    } catch (err) {
      dispatch({
        type: "auth/error",
        payload: err,
      });
    }
  };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "auth/login":
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload.userID,
        company: action.payload.company,
        isVerified: Boolean(action.payload.isVerified),
        subscription: action.payload.subscription,
        error: null,
      };
    case "auth/update":
      return {
        ...state,
        company: action.payload.company,
      };
    case "auth/logout":
      return {
        ...state,
        ...initialState,
        loading: false,
      };
    case "auth/error":
      return { ...state, error: action.payload };

    case "users/verify/loaded":
      return { ...state, isVerified: true };
    case "users/stripeSuccess/loaded":
      return { ...state, subscription: action.payload.data.subscription };
    default:
      return state;
  }
};

export default authReducer;
