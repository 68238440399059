import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import OwnLink from "../shared/Link";

const Subtitle = ({ children }) => {
  return (
    <Typography variant="h5" component="h2" sx={{ mt: 5, mb: 2 }}>
      {children}
    </Typography>
  );
};

function Terms() {
  return (
    <Container sx={{ mb: 2, px: 1 }}>
      <Typography variant="h1" align="center">
        Allgemeine Geschäftsbedingungen
      </Typography>
      <Subtitle>Präambel</Subtitle>
      <p>
        Betreiber dieser Plattform ist OS Order-Scout GmbH, De-Gasperi-Straße 3 in
        51469 Bergisch Gladbach, eingetragen beim Amtsgericht Köln HRB 104214 |
        Geschäftsführer: Dipl.-Wirt.-Ing. Ercan Öztürk (nachfolgend „OS
        Order-Scout“). OS Order-Scout ist ein standortgebundener
        Vermittlungsdienst, der die Verbindung von Fertigern und Einkäufern
        unter www.order-scout.com („Plattform“) im Zusammenhang mit der
        Ausschreibung von Anfragen, dem Abgleich von Lieferanten und Angeboten
        mit solchen Anfragen und allen damit verbundenen Dienstleistungen im
        Bereich der industriellen Fertigung („Fertigungskapazitäten und
        -möglichkeiten“) vermittelt. OS Order-Scout unterstützt den Fertiger
        gegenüber den Einkäufern dabei im Wesentlichen nur durch die
        übersichtliche Darstellung des Angebots und bei der Abwicklung von
        Bestellungen, nicht jedoch bei der Erfüllung des Fertigungs-
        /Dienstleistungsvertrages zwischen Fertiger und Einkäufer.
      </p>
      <Subtitle>§ 1 Geltungsbereich, Anmeldung</Subtitle>
      <p>
        (1) Die AGB gelten zwischen OS Order-Scout und Ihnen als Nutzer
        (Fertiger oder Einkäufer) der Plattform.{" "}
      </p>
      <p>
        (2) Mit dem Anlegen eines Nutzerkontos (das „Nutzerkonto“) oder dem
        anderweitigen Zugriff auf die Plattform erklären sich die Nutzer damit
        einverstanden und verpflichten sich, die Plattform ausschließlich in
        Übereinstimmung mit diesen AGB zu nutzen. Die AGB gelten nur, wenn der
        Nutzer Unternehmer (§ 14 BGB), eine juristische Person des öffentlichen
        Rechts oder ein öffentlich-rechtliches Sondervermögen ist.
      </p>
      <p>
        (3) Unsere AGB gelten ausschließlich. Abweichende, entgegenstehende oder
        ergänzende Allgemeine Geschäftsbedingungen des Nutzers werden nur dann
        und insoweit Vertragsbestandteil, als wir ihrer Geltung ausdrücklich
        zugestimmt haben.
      </p>
      <p>
        (4) Ergänzend kommen die Allgemeinen Geschäftsbedingungen zwischen
        Fertiger und den Einkäufer bzw. dem Nutzer im Rahmen des zwischen ihnen
        geschlossenen Vertragsverhältnis zur Anwendung. Im Einzelfall
        getroffene, individuelle Vereinbarungen mit dem Nutzer (einschließlich
        Nebenabreden, Ergänzungen und Änderungen) haben in jedem Fall Vorrang
        vor diesen AGB. Für den Inhalt derartiger Vereinbarungen ist,
        vorbehaltlich des Gegenbeweises, ein schriftlicher Vertrag bzw. unsere
        schriftliche Bestätigung maßgebend.
      </p>
      <p>
        (5) Hinweise auf die Geltung gesetzlicher Vorschriften haben nur
        klarstellende Bedeutung. Auch ohne eine derartige Klarstellung gelten
        daher die gesetzlichen Vorschriften, soweit sie in diesen AGB nicht
        unmittelbar abgeändert oder ausdrücklich ausgeschlossen werden.
      </p>
      <Subtitle>§ 2 Nutzung der Plattform, Mitgliedschaft</Subtitle>
      <p>
        (1) OS Order-Scout bietet eine Plattform, die als Marktplatz für
        Fertiger und Einkäufer dient.
      </p>
      <p>
        (2) Einkäufer können Anfragen für Produktionskapazitäten und
        -fähigkeiten auf die Plattform stellen, entweder eigenständig oder von
        OS Order-Scout verwaltet.
      </p>
      <p>
        (3) Fertiger können die Plattform zur Abgabe von Angeboten bei Abschluss
        einer Mitgliedschaft nutzen.
      </p>
      <p>
        (4) Zusätzlich bietet OS Order-Scout an, sowohl die Einkäufer als auch
        die Fertiger bei der Vertragsabwicklung, z.B. bei der Zahlungsabwicklung
        und der Versandautomatisierung, zu unterstützen.
      </p>
      <p>
        (5) Ein Vertragsschluss über den Kauf und die Lieferung von Waren kommt
        nur zwischen Fertiger und Einkäufer zustande und nicht mit OS
        Order-Scout.
      </p>
      <Subtitle>
        § 3 Nutzungsentgelt, Zahlungsbedingungen, Registrierungsgebühr
      </Subtitle>
      <p>
        (1) Als Gegenleistung für die Nutzung der OS Order-Scout Plattform ist
        der Nutzer, soweit er Fertiger ist, verpflichtet, ein Nutzungsentgelt zu
        leisten. Die Höhe des zu entrichtenden Entgelts wird von den
        Vertragsparteien gesondert in einem Abonnementvertrag geregelt.
      </p>
      <p>
        (2) Der Nutzer, soweit er Einkäufer ist, ist verpflichtet, eine
        jährliche Gebühr in Höhe von 0,99 Euro zu zahlen.
      </p>
      <Subtitle>§ 4 Rechte und Pflichten des Einkäufers</Subtitle>
      <p>
        (1) Der Einkäufer ist verpflichtet, alle Informationen zur Verfügung zu
        stellen, die OS Order-Scout für die Einholung von Angebot bei Fertigern
        benötigt.
      </p>
      <p>
        (2) Der Einkäufer ist zudem verpflichtet auf Angebote in angemessener
        Zeit zu reagieren und Änderungswünsche unverzüglich zu informieren.
      </p>
      <Subtitle>§ 5 Rechte und Pflichten des Fertigers</Subtitle>
      <p>
        (1) Der Fertiger verpflichtet sich, die Angaben im Rahmen seiner
        Mitgliedschaft (einschließlich des Unternehmensprofils) auf dem neuesten
        Stand zu halten und die Verpflichtungen und Fristen einzuhalten.
      </p>
      <p>
        (2) Mit Abschluss des Herstellungsvertrags sichert der Fertiger die
        Erfüllung des Herstellungsvertrages gegenüber dem Einkäufer zu.
      </p>
      <p>
        (3) Der Fertiger wird alle für die Erfüllung des Werkvertrages
        notwendigen und relevanten Informationen und Materialien zur Verfügung
        stellen und OS Order-Scout bei ihren Tätigkeiten unterstützen.
      </p>
      <p>
        (4) Der Fertiger führt den Fertigungsauftrag mit dem Einkäufer
        eigenverantwortlich nach den Bestimmungen des Fertigungsauftrages aus.
      </p>
      <p>
        (5) Der Fertiger hat alle vorgeschriebenen und sonstigen Normen,
        insbesondere den Stand der Technik bei der Herstellung von
        Fertigungswerkstücken, einzuhalten.
      </p>
      <Subtitle>§ 6 Gewährleistung</Subtitle>
      <p>
        Es finden für etwaige Gewährleistungsansprüche wegen Rechts- und/oder
        Sachmängeln die gesetzlichen Regelungen Anwendung.
      </p>
      <Subtitle>§ 7 Haftung</Subtitle>
      <p>
        (1) Ansprüche auf Schadensersatz sind ausgeschlossen. Hiervon
        ausgenommen sind Schadensersatzansprüche aus der Verletzung des Lebens,
        des Körpers, der Gesundheit oder aus der Verletzung wesentlicher
        Vertragspflichten (Kardinalpflichten) sowie die Haftung für sonstige
        Schäden, die auf einer vorsätzlichen oder grob fahrlässigen
        Pflichtverletzung von OS Order-Scout, seiner gesetzlichen Vertreter oder
        Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche,
        deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist.
      </p>
      <p>
        (2) Bei der Verletzung wesentlicher Vertragspflichten haftet OS
        Order-Scout nur auf den vertragstypischen, vorhersehbaren Schaden, wenn
        dieser einfach fahrlässig verursacht wurde, es sei denn, es handelt sich
        um Schadensersatzansprüche des Nutzers aus einer Verletzung des Lebens,
        des Körpers oder der Gesundheit.
      </p>
      <p>
        (3) OS Order-Scout übernimmt auch keinerlei Haftung für die Richtigkeit
        und Vollständigkeit der auf der OS Order-Scout -Plattform durch den
        Fertiger selbst in Eigenverantwortung angegebenen Informationen oder für
        die fehlerhafte oder unvollständige Übertragung von Daten.
      </p>
      <Subtitle>§ 8 Verfügbarkeit von OS Order-Scout</Subtitle>
      <p>
        (1) Sie erkennen an, dass eine 100%ige Verfügbarkeit der OS Order-Scout
        Plattform technisch nicht zu realisieren ist. Wir bemühen uns jedoch,
        unsere Plattform möglichst konstant verfügbar zu halten. Insbesondere
        Wartungs-, Sicherheits-, Integritäts- oder Kapazitätsbelange sowie
        Ereignisse, die nicht im Machtbereich von uns stehen (wie z. B.
        Störungen von öffentlichen Kommunikationsnetzen, Stromausfälle etc.),
        können aber zu Störungen oder zur vorübergehenden Einstellung des
        Betriebs unserer Plattform führen.
      </p>
      <p>
        (2) Bei einem Ausfall der Plattform, Systemausfall oder einer
        Serverbeeinträchtigung und hierdurch verursachter fehlerhafter
        Übermittlung der Daten des Nutzers, der/die weder auf vorsätzliches,
        noch grob fahrlässiges Verhalten von OS Order-Scout, seinen Mitarbeitern
        oder Erfüllungsgehilfen beruht, bestehen keine Ansprüche des Nutzers auf
        Schadensersatz.
      </p>
      <p>
        (3) Wir bemühen uns, dem Nutzer voraussehbare notwendige
        Betriebsunterbrechungen für vorbeugende Wartungsarbeiten oder technische
        Änderungen innerhalb eines angemessenen Zeitraums vor der
        Betriebsunterbrechung anzukündigen.
      </p>
      <p>
        (4) Wir sind berechtigt, jederzeit den Betrieb der Plattform aus
        wichtigem Grund ganz oder teilweise vorübergehend einzustellen. OS
        Order-Scout übernimmt keine Gewähr für die ununterbrochene Verfügbarkeit
        von OS Order-Scout.
      </p>
      <p>
        (5) Für den Fall, dass OS Order-Scout eine 90%ige Verfügbarkeit pro
        Kalendermonat aus welchem Grund auch immer nicht erzielt, wird OS
        Order-Scout auf Aufforderung des Nutzers den auf den betroffenen Teil
        des Kalendermonats entfallenden Entgeltsteil rückerstatten. Für die
        Funktionsfähigkeit und Erreichbarkeit seiner eigenen Systeme bleibt der
        Nutzer allein verantwortlich.{" "}
      </p>
      <Subtitle>§ 9 Sonstiges, Datenschutz, Änderungsvorbehalt</Subtitle>
      <p>
        (1) Personenbezogene Daten, die erforderlich sind, um ein
        Vertragsverhältnis mit Ihnen einschließlich seiner inhaltlichen
        Ausgestaltung zu begründen oder zu ändern sowie personenbezogene Daten
        zur Bereitstellung und Erbringung unserer Leistungen verarbeiten wir
        selbstverständlich nur im Rahmen der geltenden gesetzlichen Bestimmungen
        der DSGVO. Weitere Informationen sind in unseren{" "}
        <OwnLink to="/data-policy" newTab>
          Datenschutzhinweisen{" "}
        </OwnLink>
        enthalten, die Sie unter folgendem Link abrufen können:
        https://www.order-scout.com/data-policy.
      </p>
      <p>
        (2) OS Order-Scout ist berechtigt, diese AGB in einem Umfang, der
        zumutbar ist, einseitig zu ändern, soweit dies sachlich gerechtfertigt
        ist, zur Beseitigung nachträglich entstehender Äquivalenzstörungen oder
        zur Anpassung an veränderte gesetzliche oder technische
        Rahmenbedingungen notwendig ist. Über die beabsichtigten Änderungen wird
        OS Order-Scout den Nutzer schriftlich rechtzeitig informieren. Soweit
        der Nutzer nicht binnen vier Wochen nach Zugang schriftlich oder per
        E-Mail Widerspruch erhebt, sind diese Änderungen – sofern sie keine
        wesentlichen Vertragspflichten berühren – vereinbart und damit
        Vertragsinhalt. Über das Widerspruchsrecht sowie über die Rechtsfolgen
        des Fristablaufs wird OS Order-Scout in der Benachrichtigung hinweisen.
      </p>
      <Subtitle>
        § 10 Erfüllungsort, Anwendbares Recht und Gerichtsstand
      </Subtitle>
      <p>
        (1) Soweit gesetzlich zulässig, ist Erfüllungsort und Zahlungsort der
        Sitz von OS Order-Scout.
      </p>
      <p>
        (2) Änderungen und Ergänzungen dieses Vertrages einschließlich dieses §
        11 Abs. 3 bedürfen der Schriftform, soweit nicht etwas anderes bestimmt
        ist.
      </p>
      <p>
        (3) Die Parteien dürfen diesen Vertrag sowie Rechte und Pflichten aus
        diesem nur mit vorheriger schriftlicher Zustimmung der jeweils anderen
        Partei auf einen Dritten übertragen.
      </p>
      <p>
        (4) Mündliche oder schriftliche Nebenabreden zu diesem Vertrag bestehen
        nicht.
      </p>
      <p>
        (5) Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im
        Zusammenhang mit Vertrag ist der Sitz von OS Order-Scout.
      </p>
      <p>
        (6) Die Parteien vereinbaren hinsichtlich sämtlicher Rechtsbeziehungen
        aus diesem Vertragsverhältnis die Anwendung des Rechts der
        Bundesrepublik Deutschland unter Ausschluss seiner kollisionsrechtlichen
        Bestimmungen und des Übereinkommens der Vereinten Nationen über Verträge
        über den internationalen Warenverkauf vom 11.4.1980 (UN-Kaufrecht).
      </p>
    </Container>
  );
}

export default Terms;
