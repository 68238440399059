import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

export const ProtectedRoute = (props) => {
  const { component: Component, manufOnly, ...rest } = props;
  const { auth } = props;
  const location = useLocation();
  const loadingJsx = (
    <div
      style={{
        minHeight: "500px",
        marginTop: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
  const manufOnlyJsx = (
    <div
      style={{
        minHeight: "500px",
        marginTop: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Dieser Service ist nur für Fertiger verfügbar.
    </div>
  );
  const authenticatedJsx = <Component {...props} />;
  const unverifiedJsx = (
    <Redirect
      to={{
        pathname: "/unverified",
      }}
    />
  );
  const unAuthRedirectJsx = (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          from: props.location,
        },
      }}
    />
  );
  const unsubscribedJsx = (
    <Redirect
      to={{
        pathname: "/unsubscribed",
      }}
    />
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.loading) {
          return loadingJsx;
        } else {
          if (auth.isAuthenticated) {
            if (
              !auth.isVerified &&
              location.pathname.split("/")[1] !== "verify"
            ) {
              return unverifiedJsx;
            }

            if (
              !["Fertiger Abo", "Einkäufer Abo"].includes(auth.subscription) &&
              // auth.subscription !== "Fertiger Abo" &&
              ![
                "unsubscribed",
                "stripe-failure",
                "stripe-success",
                "verify",
              ].includes(
                location.pathname.toLowerCase().toString().split("/")[1]
              )
            ) {
              return unsubscribedJsx;
            }
            if (manufOnly && auth.company.industryRole !== "Fertiger") {
              return manufOnlyJsx;
            }
            return authenticatedJsx;
          } else {
            return unAuthRedirectJsx;
          }
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth;
  return { auth };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
