import React from "react";

import { Field, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";

import LoadingButton from "../../LoadingButton";
import { forgot, cleanUp } from "../../../redux/ducks/forgotPW";
import { openSnack } from "../../../redux/ducks/snackbar";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: "100%",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid",
    borderColor: "#cccccc",
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const renderTextField = ({
  label,
  input,
  meta,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );
};

const ForgotForm = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, loaded, error } = useSelector((s) => s.forgot);
  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, error]);
  React.useEffect(() => {
    if (!!loaded) {
      dispatch(
        openSnack(
          "Eine E-Mail mit weiteren Anweisungen wurde erfolgreich an Sie gesendet. Bitte prüfen Sie Ihren Spam-Ordner, falls Sie die E-Mail nicht finden können."
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, loaded]);
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={props.handleSubmit((values) => {
        dispatch(forgot(values));
      })}
      className={classes.form}
    >
      <div style={{ marginTop: "1em" }}>
        <div>
          <Field
            name="email"
            component={renderTextField}
            label="Email*"
            variant="outlined"
            className={classes.textField}
            autoComplete="email"
            fullWidth
          />
        </div>
        <div className={classes.buttonSection}>
          <LoadingButton
            type="submit"
            success={loaded}
            loading={loading}
            label="Anmeldelink senden"
            successLabel="Gesendet!"
            fullWidth
          />
        </div>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["email"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld";
    }
  });
  return errors;
};

export default reduxForm({
  form: "ForgotForm", // a unique identifier for this form
  validate,
})(ForgotForm);
