import React from "react";
import { Field, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import { countriesDE } from "../../../fixedInput";

// MUI
import { makeStyles } from "@mui/styles";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import {
  closeEditDialog,
  updateProfile,
} from "../../../redux/ducks/companyProfile";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    // minWidth: "25ch",
    width: "100%",
  },
  selectField: {
    margin: `${theme.spacing(1)} 0`,
    // marginLeft: 0,
    // minWidth: "25ch",
    width: "100%",
  },
}));

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={!!touched && !!invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);
const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl
    variant="outlined"
    style={{ width: "100%" }}
    error={!!touched && !!error}
  >
    <InputLabel id={label}>{label}</InputLabel>
    <Select
      labelId={label + "Id"}
      id={label}
      label={label}
      {...input}
      {...custom}
    >
      {children}
    </Select>
    {renderFormHelper({ touched, error })}
  </FormControl>
);

const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const EditDialogForm = ({ handleSubmit, loading }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <form
      onSubmit={handleSubmit((values) => {
        dispatch(updateProfile(values));
      })}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Field
            name="streetName"
            component={renderTextField}
            variant="outlined"
            label="Straße*"
            placeholder="Name der Straße"
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            name="streetNum"
            component={renderTextField}
            label="Nr.*"
            variant="outlined"
            placeholder="Straßennummer"
            className={classes.textField}
            type="number"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Field
            name="city"
            component={renderTextField}
            label="Stadt*"
            fullWidth
            variant="outlined"
            className={classes.textField}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Field
            name="province"
            component={renderTextField}
            label="Provinz"
            variant="outlined"
            placeholder="Provinz/Bundesland Name"
            className={classes.textField}
          />
        </Grid>
      </Grid>
      <Field
        className={classes.selectField}
        name="country"
        variant="outlined"
        component={renderSelectField}
        label="Land*"
      >
        <MenuItem value="" />
        {countriesDE.map((option, index) => {
          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Field>
      <Field
        name="website"
        component={renderTextField}
        label="Firma Website"
        variant="outlined"
        placeholder="Firma Website"
        className={classes.textField}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Field
            name="phoneNumber"
            component={renderTextField}
            label="Telefonnummer"
            variant="outlined"
            placeholder="+4912345678"
            className={classes.textField}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Field
            name="faxNumber"
            component={renderTextField}
            label="Faxnummer"
            variant="outlined"
            placeholder="+4912345678"
            className={classes.textField}
          />
        </Grid>
      </Grid>

      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeEditDialog());
          }}
          color="primary"
        >
          {"Abbrechen"}
        </Button>
        <Button type="submit" color="primary" autoFocus disabled={loading}>
          {loading ? <CircularProgress /> : "Profil aktualisieren"}
        </Button>
      </DialogActions>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["streetName", "streetNum", "city", "country"];
  const numberFields = ["streetNum", "phoneNumber", "faxNumber"];
  const strNumFields = ["phoneNumber", "faxNumber"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld";
    }
  });

  numberFields.forEach((field) => {
    if (!!values[field] && values[field] <= 0) {
      errors[field] = "Diese Zahl muss größer als 0 sein";
    }
  });
  strNumFields.forEach((field) => {
    if (
      values[field] &&
      !values[field].match(
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d+)\)?)[-. \\/]?)?((?:\(?\d{1,}\)?[ \\]?){0,})(?:[ \\]?(?:#|ext\.?|extension|x)[\\]?(\d+))?$/i
      )
    ) {
      errors[field] = "ungültige Eingabe";
    }
  });
  // if (values["country"]) {
  //   errors["country"] = "invalid";
  // }
  return errors;
};

export default reduxForm({
  form: "EditDialogForm", // a unique identifier for this form
  validate,
})(EditDialogForm);
