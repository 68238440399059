import { usersAPI } from "../../apis/users";
import { reset } from "redux-form";
const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

export const forgot = (values) => async (dispatch) => {
  dispatch({ type: "users/forgot/loading" });
  values.origin = window.location.origin;
  try {
    const response = await usersAPI.post("/forgot", values);
    if (response.data.status === "success") {
      dispatch({ type: "users/forgot/loaded" });
      dispatch(reset("ForgotForm"));
    } else {
      dispatch({ type: "users/forgot/error", payload: response.data });
    }
  } catch (e) {
    dispatch({ type: "users/forgot/error", payload: e.response?.data || e });
  }
};

export const cleanUp = () => {
  return { type: "users/forgot/clean" };
};

const forgotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "users/forgot/loading":
      return { ...state, loading: true, loaded: false, error: null };
    case "users/forgot/loaded":
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null,
      };
    case "users/forgot/error":
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    case "users/forgot/clean":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default forgotReducer;
