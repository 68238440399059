import React from "react";

import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import Vorwort from "./Vorwort";
import A1 from "./A1";
import A2 from "./A2";
import A3 from "./A3";
import A12 from "./A12";
import A4to10 from "./A4to10";
import A11 from "./A11";
import B1 from "./B1";
import B2 from "./B2";
import B5 from "./B5";
import B6 from "./B6";
import B3to4 from "./B3to4";

const Privacy = () => {
  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Typography variant="h1" align="center">
        {"Datenschutzerklärung"}
      </Typography>
      <Vorwort />
      <Typography variant="h2" sx={{ mt: 2 }}>
        A. Allgemeines
      </Typography>
      <Box sx={{ ml: 2 }}>
        <A1 />
        <A2 />
        <A3 />

        <A4to10 />
        <A11 />
        <A12 />
      </Box>
      <Typography variant="h2" sx={{ mt: 2 }}>
        B. Besuch von Webseiten
      </Typography>
      <Box sx={{ ml: 2 }}>
        <B1 />
        <B2 />
        <B3to4 />
        <B5 />
        <B6 />
      </Box>
    </Container>
  );
};

export default Privacy;
