import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import SubArticle from "./SubArticle";

const a1TextList = [
  '"Personenbezogene Daten" (Art. 4 Nr. 1 DSGVO) sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person ("Betroffener") beziehen. Identifizierbar ist eine Person, wenn sie direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, einer Kennnummer, einer Online-Kennung, Standortdaten oder mithilfe von Informationen zu ihren physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identitätsmerkmalen identifiziert werden kann. Die Identifizierbarkeit kann auch mittels einer Verknüpfung von derartigen Informationen oder anderem Zusatzwissen gegeben sein. Auf das Zustandekommen, die Form oder die Verkörperung der Informationen kommt es nicht an (auch Fotos, Video- oder Tonaufnahmen können personenbezogene Daten enthalten).',
  '"Verarbeiten" (Art. 4 Nr. 2 DSGVO) ist jeder Vorgang, bei dem mit personenbezogenen Daten umgegangen wird, gleich ob mit oder ohne Hilfe automatisierter (dh technikgestützter) Verfahren. Dies umfasst insbesondere das Erheben (dh die Beschaffung), das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, die Verbreitung oder sonstige Bereitstellung, den Abgleich, die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung von personenbezogenen Daten sowie die Änderung einer Ziel- oder Zweckbestimmung, die einer Datenverarbeitung ursprünglich zugrunde gelegt wurde.',
  '"Verantwortlicher" (Art. 4 Nr. 7 DSGVO) ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.',
  '"Dritter" (Art. 4 Nr. 10 DSGVO) ist jede natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer dem Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten; dazu gehören auch andere konzernangehörige juristische Personen.',
  '"Auftragsverarbeiter" (Art. 4 Nr. 8 DSGVO) ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen, insbesondere gemäß dessen Weisungen, verarbeitet (z. B. IT-Dienstleister). Im datenschutzrechtlichen Sinne ist ein Auftragsverarbeiter insbesondere kein Dritter.',
  '"Einwilligung" (Art. 4 Nr. 11 DSGVO) der betroffenen Person bezeichnet jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.',
];

function A1() {
  return (
    <SubArticle title="(1) Begriffsbestimmungen">
      <Typography>
        Nach dem Vorbild des Art. 4 DSGVO liegen dieser Datenschutzhinweise
        folgende Begriffsbestimmungen zugrunde:
      </Typography>
      <List>
        {a1TextList.map((article, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText>{article}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </SubArticle>
  );
}

export default A1;
