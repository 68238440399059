import React from "react";
import Box from "@mui/system/Box";
import { Alert, CircularProgress } from "@mui/material";

function Message({ text, date, own, loading, error }) {
  return (
    <Box
      // the whole message
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
        alignItems: own ? "flex-end" : "flex-start",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {!!loading && (
          <CircularProgress size={15} sx={{ margin: "15px 10px 0 0" }} />
        )}
        <p
          style={{
            padding: "10px",
            borderRadius: own ? "20px 0 0px 20px" : "0 20px 20px 0px",
            backgroundColor: own
              ? loading
                ? "#4d90e8"
                : error
                ? "grey"
                : "#1877f2"
              : "rgb(245, 241, 241)",
            color: own ? (loading ? "#f0f2f5" : "white") : "black",
            maxWidth: "500px",
          }}
        >
          {text}
        </p>
        {!!error && (
          <Alert severity="error">
            Diese Nachricht konnte nicht gesendet werden
          </Alert>
        )}
      </Box>
      <Box sx={{ fontSize: "12px", marginTop: "10px" }}>{date}</Box>
    </Box>
  );
}

export default Message;
