import React from "react";

function CNCMachineIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="3.2rem"
      height="3.2rem"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M1335 5104 c-736 -106 -1180 -548 -1311 -1304 -22 -128 -30 -519 -15
-677 59 -594 260 -1275 602 -2039 l90 -201 -152 -304 c-140 -280 -152 -309
-157 -370 -7 -94 25 -152 105 -193 29 -15 226 -16 2267 -16 l2234 0 44 33 c59
44 78 86 78 169 l0 67 -287 573 -288 573 -5 1290 -5 1290 -22 41 c-32 60 -91
97 -164 102 -92 7 -159 -30 -200 -110 -16 -31 -19 -59 -19 -158 l0 -120 -485
0 -485 0 0 90 c0 120 -15 183 -53 227 -36 41 -98 72 -143 73 -30 0 -32 3 -39
47 -13 86 -56 217 -102 311 -166 340 -466 541 -901 607 -125 18 -457 18 -587
-1z m521 -399 c332 -47 542 -201 636 -467 15 -44 28 -84 28 -89 0 -5 -17 -16
-37 -24 -95 -40 -122 -97 -123 -257 l0 -118 -484 0 -484 0 -4 123 c-3 106 -7
128 -28 164 -34 62 -88 96 -161 101 -92 7 -159 -30 -200 -110 -18 -36 -19 -77
-19 -1312 l0 -1275 -22 -45 -22 -45 -18 41 c-115 256 -291 749 -362 1014 -209
779 -206 1357 9 1761 163 307 444 487 840 538 136 17 329 18 451 0z m504
-1777 c0 -380 2 -416 19 -453 10 -22 29 -50 42 -62 28 -26 89 -53 119 -53 19
0 20 -6 20 -100 l0 -100 200 0 200 0 0 99 0 98 36 6 c51 8 106 49 135 100 l24
42 3 418 3 417 485 0 484 0 0 -880 0 -880 -1370 0 -1370 0 0 880 0 880 485 0
485 0 0 -412z"
        />
      </g>
    </svg>
  );
}

export default CNCMachineIcon;
