import React from "react";
import { ReactComponent as NotFoundSvg } from "../../Assets/images/NotFound.svg";
import OwnLink from "../shared/Link";
// MUI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function NotFoundPage() {
  return (
    <Container sx={{ mt: 2, mb: 3 }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "500px",
            }}
          >
            <NotFoundSvg />
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="h2" align="center" component={"p"}>
            Hoppla!
          </Typography>
          <Typography variant="h4" align="center" component="h1">
            Die von Ihnen angeforderte Seite konnte nicht gefunden werden.
          </Typography>

          <Typography variant="body1" align="center" component="p">
            Möchten Sie zur <OwnLink to="/">Startseite</OwnLink> zurückkehren?
            Sie können sich auch mit uns{" "}
            <OwnLink to="/contact">Kontakt</OwnLink> aufnehmen, wenn Sie der
            Meinung sind, dass diese URL korrekt sein sollte.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NotFoundPage;
