import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";

import PageTitle from "../../shared/PageTitle";
import DataGrid from "./DataGrid/index.js";

import { searchOrders } from "../../../redux/ducks/search";

const SearchContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    margin: "2rem",
  },
}));

const InstructionsContainer = styled("div")(({ theme }) => ({
  marginBottom: "16px",
  maxWidth: "80vw",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "1rem",
  },
}));

const Search = () => {
  const dispatch = useDispatch();
  const myCompanyCoordinates = useSelector(
    (s) => s.auth.company.address.geometry.coordinates
  );

  useEffect(() => {
    dispatch(searchOrders(myCompanyCoordinates));
  }, [dispatch, myCompanyCoordinates]);

  return (
    <div>
      <PageTitle text="Auftrag Suchen" />
      <SearchContainer>
        <InstructionsContainer>
          <Typography sx={{ fontSize: "0.95rem", color: "gray", mb: 1 }}>
            Bitte benutzen Sie die Filterfunktion, um die spezifischen Aufträge
            zu finden.
          </Typography>
          <Typography sx={{ fontSize: "0.95rem", color: "gray" }}>
            Für weitere Sortier- und Filteroptionen fahren Sie bitte mit dem
            Mauszeiger über die Spaltenüberschriften.
          </Typography>
        </InstructionsContainer>

        <DataGrid />
      </SearchContainer>
    </div>
  );
};

export default Search;
