import React from "react";

import { makeStyles } from "@mui/styles";

import { Container, Typography, Grid } from "@mui/material";

import CNCMachineIcon from "./CNCMachineIcon";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ContactsIcon from "@mui/icons-material/Contacts";
import ShareIcon from "@mui/icons-material/Share";
import InfoIcon from "@mui/icons-material/Info";
import StarIcon from "@mui/icons-material/Star";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    // background: "rgb(115,21,34)",
    padding: "2em",
    background:
      "linear-gradient(135deg, rgba(115,21,34,1) 0%, rgba(182,51,33,1) 50%, rgba(236,75,33,1) 100%)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: "white",
    fontSize: "3rem",
    marginBottom: "2rem",
    textShadow: "0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 10px #fff",
  },
  subtitle: {
    color: "white",
    fontSize: "1.5rem",
    marginBottom: "5rem",
  },
  feature: {
    marginBottom: "2rem",
  },
  featureText: {
    color: "white",
  },
  icon: {
    "& > :first-child": {
      color: "white",
      //   fill: theme.palette.primary.main,
      fontSize: "3.2rem",
    },
  },
}));

const FeaturesSection = ({ manufacturer }) => {
  const classes = useStyles();

  let features = [];
  if (!manufacturer) {
    features = [
      {
        icon: <CNCMachineIcon />,
        text: "Sofort verfügbare CNC-Kapazitäten ",
      },
      {
        icon: <PostAddIcon />,
        text: "Digitalisierter Ausschreibungsprozess",
      },
      {
        icon: <ContactsIcon />,
        text: "Gezielte Auswahl von qualifizierten Lieferanten für CNC und 3D-Druck",
      },
      {
        icon: <ShareIcon />,
        text: "Kontinuierlicher Ausbau Ihres Lieferantennetzwerks",
      },
      {
        icon: <InfoIcon />,
        text: "Alle Auftragsinformationen jederzeit in Echtzeit verfügbar",
      },
      {
        icon: <StarIcon />,
        text: "KI-basiertes Optimum hinsichtlich Qualität, Preis und Lieferzeit",
      },
    ];
  } else {
    features = [
      {
        icon: <CNCMachineIcon />,
        text: "Sofort verfügbare CNC-Kapazitäten",
      },
      {
        icon: <SearchIcon />,
        text: "Erweitertes Suchsystem, um genau die Aufträge zu finden, die zu Ihnen passen",
      },
      {
        icon: <ContactsIcon />,
        text: "Outsourcing von Aufträgen bei zu hoher Auslastung oder Maschinenausfall",
      },
      {
        icon: <ShareIcon />,
        text: "Kontinuierlicher Ausbau Ihres Lieferantennetzwerks",
      },
      {
        icon: <InfoIcon />,
        text: "Alle Auftragsinformationen jederzeit in Echtzeit verfügbar",
      },
      {
        icon: <StarIcon />,
        text: "Zugang zu einem großen Pool von Spezialisten aus allen Branchen und allen Fertigungsverfahren",
      },
    ];
  }

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          Be Part Of It!
        </Typography>
        <Typography variant="h3" className={classes.subtitle}>
          {
            "Mit Ihrer Registrierung nutzen Sie das ganze Potenzial von Order-Scout "
          }
        </Typography>
        <Grid container spacing={3} justifycontent="center">
          {features.map((feature, index) => {
            return (
              <Grid
                key={index}
                item
                md={4}
                sm={6}
                xs={12}
                container
                alignItems="center"
                direction="column"
                className={classes.feature}
              >
                <Grid item className={classes.icon}>
                  {" "}
                  {feature.icon}
                </Grid>
                <Grid item>
                  <Typography align="center" className={classes.featureText}>
                    {feature.text}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </section>
  );
};

export default FeaturesSection;
