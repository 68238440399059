import React from "react";

import { Field, reduxForm } from "redux-form";
import { useSelector, useDispatch } from "react-redux";

// MUI
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { sendContactReq, cleanUp } from "../../redux/ducks/contact";
import { openSnack } from "../../redux/ducks/snackbar";
import ExternalLink from "../shared/ExternalLink";

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    sx={{ mb: 1, mt: 1 }}
    label={label}
    placeholder={label}
    error={!!touched && !!invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl
    variant="outlined"
    style={{ width: "100%" }}
    error={!!touched && !!error}
  >
    <InputLabel id={label}>{label}</InputLabel>
    <Select
      labelId={label + "Id"}
      id={label}
      label={label}
      {...input}
      {...custom}
    >
      {children}
    </Select>
    {renderFormHelper({ touched, error })}
  </FormControl>
);

const renderCheckbox = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <FormControl error={!!touched && !!error}>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
    {renderFormHelper({ touched, error })}
  </FormControl>
);

function ContactForm({ handleSubmit }) {
  const dispatch = useDispatch();
  const { loading, loaded, error } = useSelector((s) => s.contact);
  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, error]);
  React.useEffect(() => {
    if (!!loaded) {
      dispatch(
        openSnack(
          "Vielen Dank für Ihre Nachricht. Unser Team wird sich mit Ihnen so schnell wie möglich in Verbinung setzen."
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, loaded]);
  const gdprLabel = (
    <span>
      Ich habe die{" "}
      <ExternalLink to="data-policy">Datenschutzerklärung</ExternalLink> gelesen
      und erkläre mich mit der Verarbeitung und Speicherung meiner Daten
      einverstanden.
    </span>
  );
  return (
    <form
      onSubmit={handleSubmit((values) => {
        dispatch(sendContactReq(values));
      })}
      style={{ padding: "16px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container sx={{ mt: 1, mb: 1 }} spacing={1}>
          <Grid item xs={5} sm={3} md={2}>
            <Field
              name="salutation"
              component={renderSelectField}
              label="Anrede*"
            >
              <MenuItem value="Herr">{"Herr"}</MenuItem>
              <MenuItem value="Frau">{"Frau"}</MenuItem>
            </Field>
          </Grid>
          <Grid item xs>
            <Field
              style={{ width: "100%", margin: 0 }}
              name="name"
              component={renderTextField}
              label="Name*"
              placeholder="Ihr Name"
              // autoComplete=""
            />
          </Grid>
        </Grid>

        <Field
          name="company"
          component={renderTextField}
          label="Firma"
          placeholder="Ihr Firmenname"
          // autoComplete=""
        />
        <Field
          name="email"
          component={renderTextField}
          label="E-Mail Adresse*"
          placeholder="Ihre E-Mail"
          type="email"
        />
        <Field
          name="phoneNumber"
          component={renderTextField}
          label="Rufnummer"
          placeholder="Ihre Rufnummer"
          // autoComplete=""
        />
        <Field
          name="message"
          component={renderTextField}
          label="Nachricht*"
          multiline
          rows={4}
          placeholder="Ihre Nachricht"
        />

        <Field
          name="acceptedDataPolicy"
          component={renderCheckbox}
          label={gdprLabel}
        />

        <Button variant="contained" type="submit" disabled={loading} fullWidth>
          Nachricht senden
        </Button>
      </Box>
    </form>
  );
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "salutation",
    "name",
    "email",
    "message",
    "acceptedDataPolicy",
  ];

  if (!["Herr", "Frau"].includes(values.salutation)) {
    errors.salutation = "Ungültig Anrede";
  }

  if (!validateEmail(values.email)) {
    errors.email = "Bitte verwenden Sie eine gültige E-Mail Adresse.";
  }
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld!";
    }
  });

  ["name", "email", "company", "phoneNumber"].forEach((field) => {
    if (values[field]?.length > 70) {
      errors[field] = "Die Eingabe ist zu lang";
    }
  });
  if (values.message?.length > 5000) {
    errors.message = "Die Eingabe ist zu lang";
  }
  return errors;
};

export default reduxForm({
  form: "ContactForm", // a unique identifier for this form
  validate,
})(ContactForm);
