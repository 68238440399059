import React from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import OwnLink from "../shared/Link";
import ExternalLink from "../shared/ExternalLink";

const Subtitle = ({ children }) => {
  return (
    <Typography variant="h5" component="h2" sx={{ mt: 5, mb: 2 }}>
      {children}
    </Typography>
  );
};

const Imprint = () => {
  return (
    <Container sx={{ mb: 2, px: 1 }}>
      <Typography variant="h1" align="center">
        Impressum
      </Typography>
      <Subtitle>Angaben gemäß § 5 TMG:</Subtitle>
      <p>OS Order-Scout GmbH</p>
      <p>De-Gasperi-Straße 3</p>
      <p>51469 Bergisch Gladbach</p>
      <p>Deutschland</p>
      <br />
      <p>Handelsregister: HRB104214</p>
      <p>Amtsgericht Köln</p>
      <p>USt-ID: DE338732148</p>
      <Subtitle>Vertreten durch den Geschäftsführer:</Subtitle>
      <p>Dipl.-Wirt.-Ing. Ercan Öztürk</p>
      <Subtitle>Kontakt</Subtitle>
      <p>Telefon: +49 (2202) 9595 232</p>
      <p>E-Mail: info@order-scout.com</p>
      <Subtitle>Redaktionell Verantwortlicher</Subtitle>
      <p>Dipl.-Wirt.-Ing. Ercan Öztürk</p>
      <Subtitle>EU-Streitschlichtung</Subtitle>
      <p>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:
      </p>
      <ExternalLink to="https://ec.europa.eu/consumers/odr">
        https://ec.europa.eu/consumers/odr
      </ExternalLink>
      <p>Verbraucherstreitbeilegung/ Universalschlichtungsstelle</p>

      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <Subtitle>Haftung</Subtitle>
      <p>
        Wir sind für die Inhalte unserer Internetseiten nach den Maßgaben der
        allgemeinen Gesetzen, insbesondere nach § 7 Abs. 1 des
        Telemediengesetzes, verantwortlich. Alle Inhalte werden mit der
        gebotenen Sorgfalt und nach bestem Wissen erstellt. Soweit wir auf
        unseren Internetseiten mittels Hyperlink auf Internetseiten Dritter
        verweisen, können wir keine Gewähr für die fortwährende Aktualität,
        Richtigkeit und Vollständigkeit der verlinkten Inhalte übernehmen, da
        diese Inhalte außerhalb unseres Verantwortungsbereichs liegen und wir
        auf die zukünftige Gestaltung keinen Einfluss haben. Sollten aus Ihrer
        Sicht Inhalte gegen geltendes Recht verstoßen oder unangemessen sein,
        teilen Sie uns dies bitte mit. Die rechtlichen Hinweise auf dieser Seite
        sowie alle Fragen und Streitigkeiten im Zusammenhang mit der Gestaltung
        dieser Internetseite unterliegen dem Recht der Bundesrepublik
        Deutschland.
      </p>
      <Subtitle>Datenschutz</Subtitle>
      <p>
        Unsere Datenschutzhinweise finden Sie{" "}
        <OwnLink to="/data-policy" newTab={true}>
          hier
        </OwnLink>
      </p>
      <Subtitle>Urheberrechtshinweis</Subtitle>
      <p>
        Die auf unserer Internetseite vorhandenen Texte, Bilder, Fotos, Videos
        oder Grafiken unterliegen in der Regel dem Schutz des Urheberrechts.
        Jede unberechtigte Verwendung (insbesondere die Vervielfältigung,
        Bearbeitung oder Verbreitung) dieser urheberrechtsgeschützten Inhalte
        ist daher untersagt. Wenn Sie beabsichtigen, diese Inhalte oder Teile
        davon zu verwenden, kontaktieren Sie uns bitte im Voraus unter den oben
        stehenden Angaben. Soweit wir nicht selbst Inhaber der benötigten
        urheberrechtlichen Nutzungsrechte sein sollten, bemühen wir uns, einen
        Kontakt zum Berechtigten zu vermitteln.
      </p>
      <Subtitle>Social Media-Profile</Subtitle>
      <p>Dieses Impressum gilt auch für unsere Social Media Profile.</p>
    </Container>
  );
};

export default Imprint;
