import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { makeStyles } from "@mui/styles";

// MUI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { openSnack } from "../../../redux/ducks/snackbar";
import { closeEditDialog, cleanUp } from "../../../redux/ducks/companyProfile";
import Form from "./Form";

const EditDialog = () => {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { open, loaded, error, loading } = useSelector(
    (state) => state.companyProfile.editDialog
  );
  const comp = useSelector((s) => s.companyProfile.company);

  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, error]);

  React.useEffect(() => {
    if (!!loaded) {
      dispatch(openSnack("Ihr Profil wurde erfolgreich aktualisiert"));
      dispatch(cleanUp());
    }
  }, [dispatch, loaded]);

  const initialValues = {
    streetName: comp.address.street.streetName,
    streetNum: comp.address.street.streetNum,
    zip: comp.address.zip,
    city: comp.address.city,
    country: comp.address.country,
    website: comp.website,
    phoneNumber: comp.phoneNumber,
    faxNumber: comp.faxNumber,
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(closeEditDialog());
      }}
    >
      <DialogTitle>{"Profil bearbeiten"}</DialogTitle>
      <DialogContent>
        <Form loading={loading} initialValues={initialValues} />
      </DialogContent>
    </Dialog>
  );
};

export default EditDialog;
