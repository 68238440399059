import React from "react";

import InnerContainer from "./InnerContainer";
import PageTitle from "../../shared/PageTitle";
import AddOrdersForm from "./Form";

function AddOrder() {
  return (
    <>
      <PageTitle text="Auftrag Hinzufügen" />
      <InnerContainer>
        <AddOrdersForm />
      </InnerContainer>
    </>
  );
}

export default AddOrder;
