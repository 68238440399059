import React from "react";

import Paper from "@mui/material/Paper";

import RegisterForm from "./RegisterForm";
const divStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
function Register() {
  return (
    <div style={divStyle}>
      <Paper
        style={{
          padding: "10px",
          minWidth: "60%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <RegisterForm />
      </Paper>
    </div>
  );
}

export default Register;
