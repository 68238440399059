import React from "react";
import SubArticle from "./SubArticle";
import Typography from "@mui/material/Typography";

function A4() {
  return (
    <SubArticle title="(12) Änderungen der Datenschutzhinweise">
      <Typography>
        Im Rahmen der Fortentwicklung des Datenschutzrechts sowie
        technologischer oder organisatorischer Veränderungen werden unsere
        Datenschutzhinweise regelmäßig auf Anpassungs- oder Ergänzungsbedarf hin
        überprüft.
      </Typography>
    </SubArticle>
  );
}

export default A4;
