import { usersAPI } from "../../apis/users";
import socket from "../../helpers/socket";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  company: null,
};

const loading = "users/register/loading";
const loaded = "users/register/loaded";
const error = "users/register/error";
const clean = "users/register/cleanUp";

export const register = (registerValues) => async (dispatch) => {
  dispatch({ type: loading });
  registerValues.origin = window.location.origin;
  const getFormData = (values) => {
    const formData = new FormData();

    const valuesKeys = Object.keys(values).filter((item) => item !== "logo");
    valuesKeys.forEach((key) => formData.append(key, values[key]));

    const logoArr = values.logo;
    if (logoArr && logoArr.length) {
      formData.append("logo", logoArr[0]);
    }
    return formData;
  };

  const data = getFormData(registerValues);
  try {
    const response = await usersAPI.post("/register", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.status === "success") {
      dispatch({
        type: loaded,
      });
      localStorage.setItem("user", response.data.data.user._id);
      localStorage.setItem("company", response.data.data.user.company._id);
      socket.connect();
      dispatch({
        type: "auth/login",
        payload: {
          userID: response.data.data.user._id,
          company: response.data.data.user.company,
          isVerified: response.data.data.user.isVerified,
        },
      });
    } else {
      dispatch({
        type: error,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: error,
      payload: err.response.data,
    });
  }
};

export const cleanUp = () => (dispatch) => {
  dispatch({ type: clean });
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, loading: true, loaded: false, error: null };
    case loaded:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    case clean:
      return {
        ...state,
        ...initialState,
      };
    case "auth/logout":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default registerReducer;
