import React from "react";
import SubArticle from "./SubArticle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const a3List = [
  'Art. 6 Abs. 1 S. 1 lit. a DSGVO ("Einwilligung"): Wenn der Betroffene freiwillig, in informierter Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung zu verstehen gegeben hat, dass er mit der Verarbeitung der ihn betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden ist;',
  "Art. 6 Abs. 1 S. 1 lit. b DSGVO: Wenn die Verarbeitung zur Erfüllung eines Vertrags, dessen Vertragspartei der Betroffene ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf die Anfrage des Betroffenen erfolgen;",
  "Art. 6 Abs. 1 S. 1 lit. c DSGVO: Wenn die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der der Verantwortliche unterliegt (z. B. eine gesetzliche Aufbewahrungspflicht);",
  "Art. 6 Abs. 1 S. 1 lit. d DSGVO: Wenn die Verarbeitung erforderlich ist, um lebenswichtige Interessen des Betroffenen oder einer anderen natürlichen Person zu schützen",
  "Art. 6 Abs. 1 S. 1 lit. e DSGVO: Wenn die Verarbeitung für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde oder",
  'Art. 6 Abs. 1 S. 1 lit. f DSGVO ("Berechtigte Interessen"): Wenn die Verarbeitung zur Wahrung berechtigter (insbesondere rechtlicher oder wirtschaftlicher) Interessen des Verantwortlichen oder eines Dritten erforderlich ist, sofern nicht die gegenläufigen Interessen oder Rechte des Betroffenen überwiegen (insbesondere dann, wenn es sich dabei um einen Minderjährigen handelt).',
];

function A3() {
  return (
    <SubArticle title="(3) Rechtsgrundlagen der Datenverarbeitung">
      <Typography>
        Von Gesetzes wegen ist im Grundsatz jede Verarbeitung personenbezogener
        Daten verboten und nur dann erlaubt, wenn die Datenverarbeitung unter
        einen der folgenden Rechtfertigungstatbestände fällt:
      </Typography>
      <List>
        {a3List.map((article, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText>{article}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Typography>
        Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir im
        Folgenden jeweils die anwendbare Rechtsgrundlage an. Eine Verarbeitung
        kann auch auf mehreren Rechtsgrundlagen beruhen.
      </Typography>
    </SubArticle>
  );
}

export default A3;
