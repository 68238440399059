import React from "react";
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    display: "inline",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.dark,
    },
  },
}));

function OwnLink({ to, children, newTab }) {
  const classes = useStyles();
  return (
    <Link
      to={to}
      className={classes.link}
      target={newTab ? "_blank" : ""}
      rel={newTab ? "noopener noreferrer" : ""}
    >
      {children}
    </Link>
  );
}

export default OwnLink;
