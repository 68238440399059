import React from "react";
import Typography from "@mui/material/Typography";

function Vorwort() {
  return (
    <React.Fragment>
      <Typography variant="h2">Vorwort</Typography>
      <Typography>
        Wir, OS Order-Scout GmbH (nachfolgend: "das Unternehmen", "wir" oder
        "uns") nehmen den Schutz Ihrer personenbezogenen Daten ernst und möchten
        Sie an dieser Stelle über den Datenschutz in unserem Unternehmen
        informieren. Uns sind im Rahmen unserer datenschutzrechtlichen
        Verantwortlichkeit durch das Inkrafttreten der
        EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016/679; nachfolgend:
        "DSGVO") zusätzliche Pflichten auferlegt worden, um den Schutz
        personenbezogener Daten der von einer Verarbeitung betroffenen Person
        (wir sprechen Sie als betroffene Person nachfolgend auch mit "Kunde",
        "Nutzer", "Sie", "Ihnen" oder "Betroffener" an) sicherzustellen. Soweit
        wir entweder alleine oder gemeinsam mit anderen über die Zwecke und
        Mittel der Datenverarbeitung entscheiden, umfasst dies vor allem die
        Pflicht, Sie transparent über Art, Umfang, Zweck, Dauer und
        Rechtsgrundlage der Verarbeitung zu informieren (vgl. Art. 13 und 14
        DSGVO). Mit dieser Erklärung (nachfolgend: "Datenschutzhinweise")
        informieren wir Sie darüber, in welcher Weise Ihre personenbezogenen
        Daten von uns verarbeitet werden. Unsere Datenschutzhinweise sind
        modular aufgebaut. Sie bestehen aus einem allgemeinen Teil für jegliche
        Verarbeitung personenbezogener Daten und Verarbeitungssituationen, die
        bei jedem Aufruf einer Webseite zum Tragen kommen (Teil A. Allgemeines)
        und einem besonderen Teil, dessen Inhalt sich jeweils nur auf die dort
        angegebene Verarbeitungssituation mit Bezeichnung des jeweiligen
        Angebots oder Produkts bezieht, insbesondere den hier näher
        ausgestalteten Besuch von Webseiten (Teil B. Besuch von Webseiten). Teil
        B ist relevant, wenn Sie unser deutsches Internetangebot inklusive der
        Auftritte in den sozialen Medien nutzen.
      </Typography>
    </React.Fragment>
  );
}

export default Vorwort;
