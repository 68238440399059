import React from "react";

function LogoWhite({ width = "200px", className }) {
  return (
    <img
      src="/images/logo-white.svg"
      width={width}
      className={className}
      alt="OrderScout White Logo"
    />
  );
}

export default LogoWhite;
