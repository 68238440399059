import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Grid from "@mui/material/Grid";
import LoadingButton from "../../LoadingButton";
import Button from "@mui/material/Button";
import { login } from "../../../redux/ducks/login";
// import { Redirect } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "start",
  },
  formControl: {
    minWidth: 200,
  },
  // selectField: {
  //   margin: theme.spacing(1),
  //   width: "100%",
  //   // marginLeft: 0,
  // },
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: "100%",
  },
  section: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  innerRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid",
    borderColor: "#cccccc",
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
    // width: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  linkButton: {
    textTransform: "none",
  },
}));

const renderTextField = ({
  label,
  input,
  meta,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );
};

const LoginForm = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h4" component="h1">
        {"Anmelden"}
      </Typography>
      <form
        autoComplete="off"
        onSubmit={props.handleSubmit(props.login)}
        className={classes.form}
      >
        {props.state.login.error && (
          <FormHelperText error>
            {props.state.login.error.message ||
              "Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal."}
          </FormHelperText>
        )}
        <div style={{ marginTop: "1em" }}>
          <div>
            <Field
              name="email"
              component={renderTextField}
              label="E-Mail Adresse*"
              variant="outlined"
              className={classes.textField}
              autoComplete="username"
              // error={props.state.login.invalid}
              fullWidth
            />
          </div>
          <div>
            <Field
              name="password"
              component={renderTextField}
              label="Passwort*"
              variant="outlined"
              className={classes.textField}
              type="password"
              autoComplete="current-password"
              // error={props.state.login.invalid}
              fullWidth
            />
          </div>
          <Button
            className={classes.linkButton}
            color="primary"
            component={Link}
            to="/forgot"
          >
            {"Passwort Vergessen?"}
          </Button>
          <div className={classes.buttonSection}>
            <LoadingButton
              type="submit"
              loading={props.state.login.loading}
              label="Anmelden"
              successLabel="Angemeldet!"
              fullWidth
              // error={props.state.login.error}
            />
          </div>
        </div>
        <Grid container>
          <Grid item xs>
            <Button
              component={Link}
              to="/register"
              color="primary"
              className={classes.linkButton}
            >
              {"Sie haben noch kein Konto? Registrieren Sie sich!"}
            </Button>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </form>
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["email", "password"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld";
    }
  });
  return errors;
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, { login })(
  reduxForm({
    form: "LoginForm", // a unique identifier for this form
    validate,
  })(LoginForm)
);
