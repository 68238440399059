import React from "react";
import moment from "moment";
import { useHistory } from "react-router";

import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
  orderTitle: {
    flexGrow: "1",
  },
  orderDetailsContainer: {},
}));

const PaperDetail = (props) => {
  const { title, info } = props;
  return (
    <div>
      {title}:<br />
      <b>{info}</b>
    </div>
  );
};

const OrderSummary = ({ offer, className }) => {
  const classes = useStyles();
  const history = useHistory();
  let orderSummaryDetails = [];
  let order = {};

  if (offer) {
    order = offer.order;
    orderSummaryDetails = [
      {
        title: "Angebote Frist",
        info: moment(order.offersDeadline).format("DD/MM/YYYY"),
      },
      {
        title: "Lieferfrist",
        info: moment(order.deliveryDeadline).format("DD/MM/YYYY"),
      },
      {
        title: "Menge",
        info: order.qty,
      },
    ];
  }
  return (
    <div className={className}>
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid item xs={12} className={classes.title}>
            {"Auftrag Zusammenfassung"}
          </Grid>
          <Grid container item style={{ flexWrap: "nowrap" }} direction="row">
            <Grid item container justifyContent="flex-start">
              <Grid item>
                <Typography variant="h4" component="h2">                  
                  {offer.order.title}                  
                </Typography>
              </Grid>
            </Grid>

            {/* <Grid item container justifyContent="flex-end">
              <Grid item>
                Order Status: <b>PUBLIC</b>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container item>
            <Grid container justifyContent="space-around" item xs={12} sm={6}>
              {orderSummaryDetails.map((detail, index) => {
                return (
                  <Grid item key={index}>
                    <PaperDetail title={detail.title} info={detail.info} />
                  </Grid>
                );
              })}
            </Grid>
            <Grid xs={12} sm={6} container item justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push(`/orders/${offer.order._id}`);
                  }}
                >
                  {"Auftragsdetails Anzeigen"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default OrderSummary;
