import React from "react";
import { makeStyles } from "@mui/styles";
import ContactSection from "./ContactSection";
import HowItWorksSection from "./HowItWorksSection";
import Section2 from "./Section2";
import Section5 from "./Section5";
import FeaturesSection from "./FeaturesSection";
import HeroSection from "./HeroSection";

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(2),
  },
}));
const Home = () => {
  const classes = useStyles();
  return (
    <div style={{ marginBottom: "20px" }}>
      <HeroSection />
      <Section2 className={classes.section} id="more" />
      <HowItWorksSection />
      <FeaturesSection />
      <Section5 className={classes.section} />
      <ContactSection className={classes.section} />
    </div>
  );
};

export default Home;
