import offersAPI from "../../apis/offers";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  offer: null,
  acceptDialog: {
    open: false,
    neutral: true,
    loading: false,
    loaded: false,
    error: false,
  },
  rejectDialog: {
    open: false,
    loading: false,
    loaded: false,
    error: false,
  },
};

const loading = "offers/offerView/loading";
const loaded = "offers/offerView/loaded";
const err = "offers/offerView/error";

const acceptOpen = "offers/offerView/acceptDialog/open";
const acceptLoading = "offers/offerView/acceptDialog/loading";
const acceptLoaded = "offers/offerView/acceptDialog/loaded";
const acceptError = "offers/offerView/acceptDialog/error";
const acceptClose = "offers/offerView/acceptDialog/close";
const acceptClean = "offers/offerView/acceptDialog/clean";

const rejectOpen = "offers/offerView/rejectDialog/open";
const rejectLoading = "offers/offerView/rejectDialog/loading";
const rejectLoaded = "offers/offerView/rejectDialog/loaded";
const rejectError = "offers/offerView/rejectDialog/error";
const rejectClose = "offers/offerView/rejectDialog/close";
const rejectClean = "offers/offerView/rejectDialog/clean";

const errObj = (response) => {
  return {
    type: err,
    payload: {
      message: response.data.message,
      code: response.status,
      statusText: response.statusText,
    },
  };
};

export const getOffer = (id) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await offersAPI.get(`/${id}`);
    switch (response.data.status) {
      case "success":
        const offer = response.data.data.offer;
        // Translating offer status
        switch (offer.status) {
          case "active":
            offer.status = "offen";
            break;
          case "accepted":
            offer.status = "akzeptiert";
            break;
          case "rejected":
            offer.status = "abgelehnt";
            break;
          case "archived":
            offer.status = "archiviert";
            break;
          default:
            return;
        }
        return dispatch({
          type: loaded,
          payload: offer,
        });
      default:
        return dispatch(errObj(response));
    }
  } catch (error) {
    dispatch({ type: err, payload: error });
  }
};

export const openAcceptDialog = () => (dispatch) => {
  dispatch({ type: acceptOpen });
};
export const openRejectDialog = () => (dispatch) => {
  dispatch({ type: rejectOpen });
};
export const closeAcceptDialog = () => (dispatch) => {
  dispatch({ type: acceptClose });
};
export const closeRejectDialog = () => (dispatch) => {
  dispatch({ type: rejectClose });
};

export const acceptOffer = (id) => async (dispatch) => {
  dispatch({ type: acceptLoading });
  try {
    const response = await offersAPI.patch(`/${id}`, { status: "accepted" });
    switch (response.data.status) {
      case "success":
        return dispatch({
          type: acceptLoaded,
        });
      default:
        return dispatch({
          type: acceptError,
          payload: {
            message: response.data.message,
            code: response.status,
            statusText: response.statusText,
          },
        });
    }
  } catch (e) {
    dispatch({ type: acceptError, payload: e });
  }
};
export const rejectOffer = (id) => async (dispatch) => {
  dispatch({ type: rejectLoading });
  try {
    const response = await offersAPI.patch(`/${id}`, { status: "rejected" });
    switch (response.data.status) {
      case "success":
        return dispatch({
          type: rejectLoaded,
        });
      default:
        return dispatch({
          type: rejectError,
          payload: {
            message: response.data.message,
            code: response.status,
            statusText: response.statusText,
          },
        });
    }
  } catch (e) {
    dispatch({ type: rejectError, payload: e });
  }
};

export const cleanUp =
  (accept = false) =>
  (dispatch) => {
    if (accept) {
      return dispatch({ type: acceptClean });
    }
    return dispatch({ type: rejectClean });
  };

const offerViewReducer = (state = initialState, action) => {
  switch (action.type) {
    // Root
    case loading:
      return { ...state, loading: true, loaded: false, error: null };
    case loaded:
      return {
        ...state,
        loading: false,
        loaded: true,
        offer: action.payload,
        error: null,
      };
    case err:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    // Accept Dialog
    case acceptOpen:
      return {
        ...state,
        acceptDialog: {
          ...state.acceptDialog,
          open: true,
        },
      };
    case acceptClose:
      return {
        ...state,
        acceptDialog: {
          ...state.acceptDialog,
          open: false,
        },
      };
    case acceptLoading:
      return {
        ...state,
        acceptDialog: {
          ...state.acceptDialog,
          loading: true,
          loaded: false,
          error: false,
        },
      };
    case acceptLoaded:
      return {
        ...state,
        offer: {
          ...state.offer,
          status: "akzeptiert",
        },
        acceptDialog: {
          ...state.acceptDialog,
          open: false,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    case acceptError:
      return {
        ...state,
        acceptDialog: {
          ...state.acceptDialog,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case acceptClean:
      return {
        ...state,
        acceptDialog: {
          ...state.acceptDialog,
          open: false,
          loading: false,
          loaded: false,
          error: false,
        },
      };
    // Reject Dialog
    case rejectOpen:
      return {
        ...state,
        rejectDialog: {
          ...state.rejectDialog,
          open: true,
        },
      };
    case rejectClose:
      return {
        ...state,
        rejectDialog: {
          ...state.rejectDialog,
          open: false,
        },
      };
    case rejectLoading:
      return {
        ...state,
        rejectDialog: {
          ...state.rejectDialog,
          loading: true,
          loaded: false,
          error: false,
        },
      };
    case rejectLoaded:
      return {
        ...state,
        offer: {
          ...state.offer,
          status: "abgelehnt",
        },
        rejectDialog: {
          ...state.rejectDialog,
          open: false,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    case rejectError:
      return {
        ...state,
        rejectDialog: {
          ...state.rejectDialog,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case rejectClean:
      return {
        ...state,
        rejectDialog: {
          ...state.rejectDialog,
          open: false,
          loading: false,
          loaded: false,
          error: false,
        },
      };
    default:
      return state;
  }
};

export default offerViewReducer;
