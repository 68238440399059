import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function ContactInfoBox() {
  return (
    <Box
      sx={{
        height: "100%",
        // width: "100%",
        background:
          "linear-gradient(135deg, rgba(115,21,34,1) 0%, rgba(182,51,33,1) 50%, rgba(236,75,33,1) 100%)",
        p: 3,
        "& *": {
          color: "white",
        },
      }}
    >
      <Typography variant="h6">Sie erreichen uns auch über:</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link color="#FFF" href="mailto:kontakt@order-scout.com">
                kontakt@order-scout.com
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={"+49 (2202) 9595 232"} />
        </ListItem>
        <ListItem sx={{ alignItems: "flex-start" }}>
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <div>
                OS Order-Scout GmbH,
                <br />
                De-Gasperi-Straße 3,
                <br />
                51469 Bergisch Gladbach,
                <br />
                Deutschland
              </div>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
}

export default ContactInfoBox;
