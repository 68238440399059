import React from "react";
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    display: "inline",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.dark,
    },
  },
}));

function ExternalLink({ to, children, ...props }) {
  const classes = useStyles();
  return (
    <a
      href={to}
      className={classes.link}
      target={"_blank"}
      rel={"noopener noreferrer"}
      {...props}
    >
      {children}
    </a>
  );
}

export default ExternalLink;
