import { Field, reduxForm } from "redux-form";

import DialogContent from "@mui/material/DialogContent";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";

import { updateLogo } from "../../../redux/ducks/companyProfile";
import { useDispatch } from "react-redux";

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { touched, invalid, error, submitFailed, ...metaProps },
  label,
  id,
  ...props
}) => {
  const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files);
  return (
    <div style={{ maxWidth: "100%" }}>
      {!!label && (
        <FormLabel
          component="label"
          style={{ fontSize: "1.2em", marginRight: "0.5em" }}
          htmlFor={id}
        >
          {label}
        </FormLabel>
      )}
      <input
        style={{ maxWidth: "100%" }}
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        id={id}
        {...props.input}
        {...props}
      />
      <FormHelperText error>{touched && submitFailed && error}</FormHelperText>
    </div>
  );
};

const UploadLogoForm = ({ handleSubmit, closeDialog, loading }) => {
  const dispatch = useDispatch();
  return (
    <form
      onSubmit={handleSubmit((values) => {
        dispatch(updateLogo(values));
        // console.log("submitted", values);
      })}
    >
      <DialogContent>
        <Field name="newLogo" accept="image/*" component={FileInput} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeDialog();
            // dispatch(closeEditDialog());
          }}
          disabled={loading}
          color="primary"
        >
          {"Abbrechen"}
        </Button>
        <Button type="submit" color="primary" autoFocus disabled={loading}>
          {loading ? <CircularProgress /> : "Logo aktualisieren"}
        </Button>
      </DialogActions>
    </form>
  );
};
const validate = (values) => {
  if (!values?.newLogo || !values?.newLogo[0]) {
    return { newLogo: "Pflichtfeld" };
  }

  if (values.newLogo) {
    if (values.newLogo[0]) {
      if (values?.newLogo[0].size > 4 * 1024 * 1024) {
        // const logo = values.logo[0];
        return {
          newLogo: "Zu große Datei. Die maximale Dateigröße beträgt 4 MB.",
        };
      }
    }
  }
  // return errors;
};

export default reduxForm({
  form: "UploadLogoForm", // a unique identifier for this form
  validate,
})(UploadLogoForm);
