import React from "react";
import { makeStyles } from "@mui/styles";
import ContactSection from "../Home/ContactSection";
import HowItWorksSection from "../Home/HowItWorksSection";
import Section2 from "../Home/Section2";
import Section5 from "../Home/Section5";
import FeaturesSection from "../Home/FeaturesSection";
import HeroSection from "../Home/HeroSection";

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(2),
  },
}));
const Manufacturer = () => {
  const classes = useStyles();
  return (
    <div style={{ marginBottom: "20px" }}>
      <HeroSection manufacturer />
      <Section2 manufacturer className={classes.section} id="more" />
      <HowItWorksSection manufacturer />
      <FeaturesSection manufacturer />
      <Section5 manufacturer className={classes.section} />
      <ContactSection className={classes.section} />
    </div>
  );
};

export default Manufacturer;
