import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { makeStyles } from "@mui/styles";

// MUI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { openSnack } from "../../../redux/ducks/snackbar";
import {
  closeRejectDialog,
  rejectOffer,
  cleanUp,
} from "../../../redux/ducks/offerView";

const RejectOfferDialog = ({ offer }) => {
  const { open, loading, loaded, error } = useSelector(
    (state) => state.offerView.rejectDialog
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeRejectDialog());
  };

  React.useEffect(() => {
    if (!!loaded) {
      dispatch(openSnack("Angebot wurde erfolgreich abgelehnt"));
      dispatch(cleanUp());
    }
  }, [loaded, dispatch]);
  React.useEffect(() => {
    if (!!error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [error, dispatch]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Sind Sie sicher?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Sind Sie sicher, dass Sie dieses Angebot ablehnen möchten?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary">
          {"Abbrechen"}
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            dispatch(rejectOffer(offer._id));
          }}
          color="primary"
          autoFocus
        >
          {loading ? <CircularProgress /> : "Angebot Ablehnen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RejectOfferDialog;
