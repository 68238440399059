import React from "react";
import { Link } from "react-router-dom";

// MUI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  wrapper: {
    // justifyContent: "space-around",
  },
  link: {
    textDecoration: "none",
  },
  clipart: {
    width: "400px",
    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },
}));
function ContactSection() {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h2" color="primary">
              {
                "Haben Sie noch Fragen? Das Order-Scout-Team hilft Ihnen gerne weiter und beantwortet alle Ihre Fragen"
              }
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            direction="column"
            className={classes.wrapper}
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img
                src="/clipArt/contact_us.svg"
                className={classes.clipart}
                alt=""
              />
            </Grid>
            <Grid item>
              <Link to="/contact" className={classes.link}>
                <Button variant="outlined" color="primary">
                  Kontakt
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default ContactSection;
