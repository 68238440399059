import stripeAPI from "../../apis/stripe";
const initialState = {
  loading: true,
  loaded: false,
  error: null,
};

const loading = "users/stripeSuccess/loading";
const loaded = "users/stripeSuccess/loaded";
const error = "users/stripeSuccess/error";
const clean = "users/stripeSuccess/cleanUp";

export const updateCompany = (sessionId) => async (dispatch) => {
  try {
    const response = await stripeAPI.post("/payment-successful", { sessionId });
    if (response.data.status === "success") {
      dispatch({ type: loaded, payload: response.data });
    } else {
      dispatch({ type: error, payload: response.data });
    }
  } catch (e) {
    dispatch({
      type: error,
      payload: e.response?.data || e,
    });
  }
};

const stripeSuccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case loaded:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    case clean:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default stripeSuccessReducer;
