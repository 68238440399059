import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
//Icons
import TodayIcon from "@mui/icons-material/Today";
import RefreshIcon from '@mui/icons-material/Refresh';
// MUI
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import FilesList from "./FilesList";
import SendOfferDialog from "./SendOfferDialog";
import ViewTable from "./ViewTable";
import ViewText from "./ViewText";
// Actions
import {
  getOrder,
  openDialog,
  closeDialog,
  deleteOrder,
  openOfferDialog,
  updateOrder,
} from "../../redux/ducks/orderView";
import { cleanUp } from "../../redux/ducks/orderView";
import { openSnack } from "../../redux/ducks/snackbar";
import ConditionalWrapper from "../shared/ConditionalWrapper";
import ContactButton from "../shared/ContactButton";
import Link from "../shared/Link";

import formatNum from "../../utils/formatNum";

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.spacing(5),
    maxWidth: "500px",
  },
  viewTable: {
    marginTop: theme.spacing(3),
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(5),
  },
  content: {
    padding: theme.spacing(3),
    minWidth: "80%",
  },
  root: {
    flexGrow: 1,
  },
  headerMain: {
    padding: "10px",
  },
  keyCell: {
    maxWidth: "20px",
  },
  container: {
    maxWidth: "100%",
  },
  subtitle: { display: "flex", alignItems: "center", maxWidth: "100%" },
  loadingDiv: {
    minHeight: "500px",
    marginTop: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

function OrderView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let { id: orderID } = useParams();
  const { order, loading, loaded, error, deleteDialog } = useSelector(
    (state) => state.orderView
  );
  const company = useSelector((state) => state.auth.company._id);
  const myCompanyCoordinates = useSelector(
    (s) => s.auth.company.address.geometry.coordinates
  );
  const dateFormat = (date) => moment(date).format("DD/MM/YYYY");
  let orderSummaryData = [];
  let orderSpecificationsData = [];
  const [deliveryDate, setDeliveryDate] = useState(null)
  const [offerDate, setOfferDate] = useState(null)
  const handleDeliveryDateChange = (date) => {
    setDeliveryDate(date);
  };
  const handleOfferDateChange = (date) => {
    setOfferDate(date);
  };
  useEffect(() => {
    setDeliveryDate(order?.deliveryDeadline);
    setOfferDate(order?.offersDeadline)
  }, [order])
  if (order) {
    console.log("ORDER:", company, order.company, order.deliveryDeadline);
    orderSummaryData = [
      ...orderSummaryData,
      {
        key: "Menge",
        value: formatNum(order.qty),
      },
      {
        key: "Lieferfrist",
        value: dateFormat(deliveryDate),
      },
      { key: "Angebote Frist", value: dateFormat(offerDate) },
    ];
    orderSpecificationsData = [
      ...orderSpecificationsData,
      { key: "Material", value: order.material },
      { key: "Technologie", value: order.technology },
      { key: "Zertifikat", value: order.certificate },
    ];
  }

  useEffect(() => {
    dispatch(getOrder(orderID, myCompanyCoordinates));
  }, [dispatch, orderID, myCompanyCoordinates]);

  useEffect(() => {
    if (!!deleteDialog.loaded) {
      history.push("/orders/my");
      dispatch(openSnack("Auftrag erfolgreich gelöscht"));
      dispatch(cleanUp());
    }
  }, [deleteDialog.loaded, dispatch, history]);

  const isExpired = new Date(order?.offersDeadline) < new Date() || false;
  const isNotPublished = order?.status !== "published";
  const isNotManuf =
    useSelector((s) => s.auth.company?.industryRole) !== "Fertiger";

  return loaded ? (
    <div>
      <Paper
        elevation={1}
        sx={{ p: { md: 3, xs: "16px 0" }, maxWidth: "100%" }}
      >
        <Container disableGutters>
          <Grid container justifyContent={"center"}>
            <Grid item sm={8} sx={{ p: 2, maxWidth: "100%" }}>
              <Typography variant="h3" component="h2">
                {order && order.title}
              </Typography>
              <Typography
                className={classes.subtitle}
                sx={{ whiteSpace: "pre" }}
              >
                <BusinessIcon />
                {"Erstellt von "}
                <Link to={"/companies/" + order.company}>
                  {order.companyName}
                </Link>
              </Typography>
              <Typography variant="subtitle1" className={classes.subtitle}>
                <TodayIcon />
                {"Erstellt am "}
                {order && dateFormat(order.createdAt)}
              </Typography>
              {order.company !== company && (
                <Typography variant="subtitle1" className={classes.subtitle}>
                  <LocationOnIcon />
                  {order &&
                    `${
                      Math.round(order.distance * 100) / 100
                    } KM von Ihnen entfernt`}
                </Typography>
              )}
              {order.company !== company && (
                <ContactButton companyId={order.company} />
                // <Button
                //   variant="contained"
                //   onClick={() => {
                //     dispatch(createConv({ companyId: order.company }));
                //   }}
                // >
                //   Firma Kontaktieren
                // </Button>
              )}
            </Grid>

            <Grid item sm={4} sx={{ p: 1 }}>
              {order.company === company ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      dispatch(openDialog());
                    }}
                  >
                    {"Auftrag Löschen"}
                  </Button>
                  <Button 
                    variant="outlined"
                    color="primary"
                    startIcon={<RefreshIcon/>}
                    sx={{ marginLeft: '10px' }}
                    onClick={() => {
                      dispatch(updateOrder(orderID, dateFormat(deliveryDate), dateFormat(offerDate)));
                      history.push("/orders/my");
                    }
                    }
                  >
                    UPDATE
                  </Button>
                </>
              ) : (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent={"center"}
                  flexWrap={"nowrap"}
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Grid item>
                    <ConditionalWrapper
                      condition={isExpired || isNotPublished || isNotManuf}
                      wrapper={(children) => (
                        <Tooltip
                          title={
                            isExpired
                              ? "Die Angebotsfrist ist bereits abgelaufen."
                              : isNotManuf
                              ? "Nur für Fertiger verfügbar"
                              : "Dieser Auftrag ist nicht offen für Angebote."
                          }
                        >
                          <span>{children}</span>
                        </Tooltip>
                      )}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={isExpired || isNotPublished || isNotManuf}
                        onClick={() => {
                          dispatch(openOfferDialog());
                        }}
                      >
                        {"Angebot Senden"}
                      </Button>
                    </ConditionalWrapper>
                  </Grid>
                  {order.hasSentOffer && (
                    <Grid item>
                      <Alert severity="info" sx={{ mt: 1 }}>
                        <Typography variant="caption" display="block">
                          {
                            "Sie haben vorher ein Angebot zu diesem Auftrag geschickt."
                          }
                        </Typography>
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              )}
              <SendOfferDialog />
              <Dialog
                open={deleteDialog.open}
                onClose={() => {
                  dispatch(closeDialog());
                }}
              >
                {(!!deleteDialog.neutral || !!deleteDialog.loading) && (
                  <>
                    <DialogTitle id="alert-dialog-title">
                      {"Sind Sie sicher?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {
                          "Sind Sie sicher, dass Sie diesen Auftrag löschen wollen? Dieser Vorgang kann später nicht mehr rückgängig gemacht werden."
                        }
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        disabled={deleteDialog.loading}
                        onClick={() => {
                          dispatch(closeDialog());
                        }}
                        color="primary"
                      >
                        Abbrechen
                      </Button>
                      <Button
                        disabled={deleteDialog.loading}
                        onClick={() => {
                          dispatch(deleteOrder(orderID));
                        }}
                        color="primary"
                        autoFocus
                      >
                        {deleteDialog.loading ? (
                          <CircularProgress />
                        ) : (
                          "Löschen"
                        )}
                      </Button>
                    </DialogActions>
                  </>
                )}
                {/* Delete Error */}
                {!!deleteDialog.error && (
                  <DialogContent>
                    <DialogContentText>
                      <Typography variant="h6">
                        {"Etwas ist schief gelaufen."}
                        <br />
                        {deleteDialog.error.message}
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                )}
              </Dialog>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Container>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <ViewTable
              className={classes.viewTable}
              title="Auftrag Zusammenfassung"
              data={orderSummaryData}
              exists={order}
              deliveryDate={deliveryDate}
              offerDate={offerDate}
              handleDeliveryDateChange={handleDeliveryDateChange}
              handleOfferDateChange={handleOfferDateChange}
            />
            <ViewText
              className={classes.viewTable}
              title="Auftrag Beschreibung"
              text={order.description}
            />
            <ViewTable
              className={classes.viewTable}
              title="Auftragsspezifikationen"
              data={orderSpecificationsData}
              exists={order}
            />
            {order.files.length > 0 && (
              <ViewText
                className={classes.viewTable}
                title="Beigefügte Dateien"
              >
                <FilesList parent={order} />
              </ViewText>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              minHeight: "350px",
              mt: "16",
              mr: "0",
            }}
          >
            <Typography variant="h6" sx={{ mt: 3 }}>
              {"Firma Standort"}
            </Typography>
            <iframe
              title="Company Place"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDFQJZY6ZTsY1unkKyqvbM3LJJw5ZKFxGU&language=de&q=${order.address}`}
            ></iframe>
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : loading ? (
    <div className={classes.loadingDiv}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.loadingDiv}>
      <Typography variant="h6">
        {"Etwas ist schief gelaufen."}
        <br />
        {error.message}
      </Typography>
    </div>
  );
}

export default OrderView;
