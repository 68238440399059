import React from "react";
import ChangePassSection from "./changePassword";
import Container from "@mui/material/Container";

//
import PageTitle from "../shared/PageTitle";

function index() {
  return (
    <React.Fragment>
      <PageTitle text="Einstellungen" />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100vw",
        }}
      >
        <ChangePassSection />
      </Container>
    </React.Fragment>
  );
}

export default index;
