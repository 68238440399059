import React from "react";
import Box from "@mui/system/Box";
import Message from "./Message";
import { getConvMessages } from "../../../redux/ducks/messages";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { CircularProgress, Alert } from "@mui/material";

const ChatBoxTop = () => {
  const dispatch = useDispatch();
  const currentConvId = useSelector(
    (s) => s.conversations.currentConversationId
  );
  const userId = useSelector((s) => s.auth.user);
  const { messages, loading, error } = useSelector((s) => s.messages.get);
  const sentLoading = useSelector((s) => s.messages.send.loading);
  const sentError = useSelector((s) => s.messages.send.error);
  const sentText = useSelector((s) => s.messages.send.text);
  // scroll to last msg
  const messagesEndRef = React.useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };
  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);
  //
  React.useEffect(() => {
    if (currentConvId) {
      dispatch(getConvMessages(currentConvId));
    }
  }, [dispatch, currentConvId]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflowY: "scroll",
        paddingRight: "10px",
      }}
    >
      {(!!loading || !!error) && (
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          height="100%"
          // minHeight={"80vh"}
          sx={{
            "& > *": {
              maxWidth: "100%",
              wordWrap: "break-word",
              wordBreak: "break-all",
            },
          }}
        >
          {loading && <CircularProgress />}
          {error && (
            <Alert sx={{ maxWidth: "100%" }} severity="error">
              {error.message}
            </Alert>
          )}
        </Box>
      )}
      {!!messages &&
        messages.map((m, i) => {
          return (
            <Message
              key={i}
              text={m.body}
              date={moment(m.createdAt).format("DD/MM/YY HH:mm")}
              own={m.sender === userId}
            />
          );
        })}
      {(!!sentLoading || !!sentError) && (
        <>
          <Message
            loading={!!sentLoading}
            error={!!sentError}
            text={sentText}
            own
            date={moment(new Date()).format("DD/MM/YY HH:mm")}
          />
        </>
      )}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default ChatBoxTop;
