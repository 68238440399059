import React from "react";
import SubArticle from "./SubArticle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { openCookieDialog } from "../../redux/ducks/cookieConsentDialog";

const b6List = [
  "–	Technical Cookies: Diese sind zwingend erforderlich, um sich auf der Webseite zu bewegen, grundlegende Funktionen zu nutzen und die Sicherheit der Webseite zu gewährleisten; sie sammeln weder Informationen über Sie zu Marketingzwecken noch speichern sie, welche Webseiten Sie besucht haben;",
  "Performance Cookies: Diese sammeln Informationen darüber, wie Sie unsere Webseite nutzen, welche Seiten Sie besuchen und z. B. ob Fehler bei der Webseitennutzung auftreten; sie sammeln keine Informationen, die Sie identifizieren könnten – alle gesammelten Informationen sind anonym und werden nur verwendet, um unsere Webseite zu verbessern und herauszufinden, was unsere Nutzer interessiert;",
  "Advertising Cookies, Targeting Cookies: Diese dienen dazu, dem Webseitennutzer bedarfsgerechte Werbung auf der Webseite oder Angebote von Dritten anzubieten und die Effektivität dieser Angebote zu messen; Advertising und Targeting Cookies werden maximal 13 Monate lang gespeichert;",
  "Sharing Cookies: Diese dienen dazu, die Interaktivität unserer Webseite mit anderen Diensten (z. B. sozialen Netzwerken) zu verbessern; Sharing Cookies werden maximal 13 Monate lang gespeichert.",
];

function B6() {
  const dispatch = useDispatch();
  return (
    <SubArticle title="(6) Einsatz von Cookies, Plugins und sonstige Dienste auf unserer Webseite">
      <Typography variant="h5" component="h3">
        a) Cookies
      </Typography>
      <Typography>
        Auf unseren Webseiten nutzen wir Cookies. Bei Cookies handelt es sich um
        kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten
        Browser durch eine charakteristische Zeichenfolge zugeordnet und
        gespeichert werden und durch welche der Stelle, die das Cookie setzt,
        bestimmte Informationen zufließen. Cookies können keine Programme
        ausführen oder Viren auf Ihren Computer übertragen und daher keine
        Schäden anrichten. Sie dienen dazu, das Internetangebot insgesamt
        nutzerfreundlicher und effektiver, also für Sie angenehmer zu machen.
        Cookies können Daten enthalten, die eine Wiedererkennung des genutzten
        Geräts möglich machen. Teilweise enthalten Cookies aber auch lediglich
        Informationen zu bestimmten Einstellungen, die nicht personenbeziehbar
        sind. Cookies können einen Nutzer aber nicht direkt identifizieren. Man
        unterscheidet zwischen Session-Cookies, die wieder gelöscht werden,
        sobald Sie ihren Browser schließen und permanenten Cookies, die über die
        einzelne Sitzung hinaus gespeichert werden. Hinsichtlich ihrer Funktion
        unterscheidet man bei Cookies wiederum zwischen:
      </Typography>
      <List>
        {b6List.map((article, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText>{article}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Typography>
        Jeder Einsatz von Cookies, der nicht zwingend technisch erforderlich
        ist, stellt eine Datenverarbeitung dar, die nur mit einer ausdrücklichen
        und aktiven Einwilligung Ihrerseits gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO
        erlaubt ist. Dies gilt insbesondere für die Verwendung von Advertising,
        Targeting oder Sharing Cookies.8Darüber hinaus geben wir Ihre durch
        Cookies verarbeiteten personenbezogenen Daten nur an Dritte weiter, wenn
        Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eine ausdrückliche Einwilligung
        dazu erteilt haben.
      </Typography>
      <Typography variant="h5" component="h3">
        b) Cookie-Richtlinie
      </Typography>
      <Typography>
        Weitere Informationen darüber, welche Cookies wir verwenden und wie Sie
        Ihre Cookie-Einstellungen verwalten und bestimmte Arten von Tracking
        deaktivieren können, finden Sie in unserer{" "}
        <Button
          sx={{ padding: 0 }}
          onClick={() => {
            dispatch(openCookieDialog());
          }}
        >
          <Typography sx={{ textTransform: "none" }}>
            Cookie-Richtlinie
          </Typography>
        </Button>
        .
      </Typography>
      <Typography variant="h5" component="h3">
        c) Social Media Plugins
      </Typography>
      <Typography>
        Auf unseren Webseiten setzen wir keine Social-Media-Plugins ein. Sofern
        unsere Webseiten Symbole von Social-Media-Anbietern enthalten, nutzen
        wir diese lediglich zur passiven Verlinkung auf die Seiten der
        jeweiligen Anbieter.
      </Typography>
    </SubArticle>
  );
}

export default B6;
