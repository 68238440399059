import React from "react";
import SubArticle from "./SubArticle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Link from "../shared/Link";

const a2List = [
  "OS Order-Scout GmbH",
  "De-Gasperi-Straße 3",
  "51469 Bergisch Gladbach",
  "Deutschland",
  "Telefon: +49 (2202) 9595 232",
  "E-Mail: info@order-scout.com",
];

function A2() {
  return (
    <SubArticle title="(2) Name und Anschrift des für die Verarbeitung Verantwortlichen">
      <Typography>
        Die für die Verarbeitung Ihrer personenbezogenen Daten verantwortliche
        Stelle im Sinne des Art. 4 Nr. 7 DSGVO sind wir:
      </Typography>
      <List>
        {a2List.map((article, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>-</ListItemIcon>
              <ListItemText>{article}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Typography>
        Weitere Angaben zu unserem Unternehmen entnehmen Sie bitte den
        Impressumsangaben auf unserem <Link to="/imprint">Impressum</Link>
      </Typography>
    </SubArticle>
  );
}

export default A2;
