import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptCookies,
  closeCookieDialog,
} from "../../redux/ducks/cookieConsentDialog";
import Link from "../shared/Link";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const ConsentDialog = () => {
  const [state, setState] = React.useState({
    necessary: Boolean(localStorage.getItem("necessary")) || true,
    statistics: Boolean(localStorage.getItem("statistics")) || false,
    submitted: false,
  });
  const dispatch = useDispatch();
  const { open } = useSelector((s) => s.cookieConsent);

  const handleConsentClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    dispatch(closeCookieDialog());
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const acceptChoice = async ({ all }) => {
    if (all) {
      return setState({ necessary: true, statistics: true, submitted: true });
    }
    setState({ ...state, submitted: true });
  };

  const deleteCookie = function (name) {
    document.cookie = name + "=0; max-age=-1";
  };
  React.useEffect(() => {
    if (state.submitted) {
      for (const prop in state) {
        if (state[prop]) {
          if (prop !== "submitted") {
            localStorage.setItem(prop, true);
          }
        } else {
          localStorage.removeItem(prop);
        }
      }
      dispatch(acceptCookies());
      if (!state.statistics) {
        const disableStr = "ga-disable-G-DVV1W7LLCY";
        // window.dataLayer.push({ event: "disable-analytics" });
        deleteCookie("_ga_DVV1W7LLCY");
        deleteCookie("_ga");
        document.cookie =
          disableStr +
          "=true; expires=" +
          new Date(Date.now() + 31556952000) +
          "; path=/";
        window[disableStr] = true;
      } else {
        deleteCookie("ga-disable-G-DVV1W7LLCY");
      }
      // clear submitted state to prevent unintentional closing of dialog later on.
      setState({ ...state, submitted: false });
    }
  }, [state, dispatch]);

  return (
    <Dialog open={open} onClose={handleConsentClose}>
      <DialogTitle>{"Ihre Cookie-Einstellungen"}</DialogTitle>
      <DialogContent
        sx={{
          "&&>*": {
            marginBottom: 1,
          },
        }}
      >
        <DialogContentText>
          Herzlich willkommen bei Order-Scout.
        </DialogContentText>
        <DialogContentText>
          Order-Scout verwendet Cookies, einschließlich Cookies von
          Drittanbietern, aus funktionsbezogenen Gründen, und für statistische
          Analysen.
        </DialogContentText>
        <DialogContentText>
          Sie können wählen, welche Cookies wir verwenden dürfen, indem Sie die
          unten stehenden Optionen auswählen.
        </DialogContentText>
        <DialogContentText>
          Weitere Informationen finden Sie in unserer{" "}
          <Link to="/data-policy" newTab>
            Datenschutzrichtlinie
          </Link>
          .
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            disabled
            control={
              <Switch
                checked={state.necessary}
                onChange={handleChange}
                name="necessary"
              />
            }
            label="Notwendig"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.statistics}
                onChange={handleChange}
                name="statistics"
              />
            }
            label="Statistik"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={acceptChoice}>Auswahl Akzeptieren</Button>
        <Button
          variant="contained"
          onClick={() => {
            acceptChoice({ all: true });
          }}
        >
          Alle Akzeptieren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentDialog;
