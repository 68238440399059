import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeSnack } from "../redux/ducks/snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Snack() {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeSnack());
  };
  const { open, severity, message } = useSelector((state) => state.snackbar);
  return (
    <Snackbar
      open={open}
      autoHideDuration={4500}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      // message={message}
    >
      <div>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
}

export default Snack;
