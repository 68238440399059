import { combineReducers } from "redux";

import drawerReducer from "./ducks/drawer";
import registerReducer from "./ducks/register";
import loginReducer from "./ducks/login";
import authReducer from "./ducks/auth";
import forgotReducer from "./ducks/forgotPW";
import resetPWReducer from "./ducks/resetPW";
import searchOrdersReducer from "./ducks/search";
import myOrdersReducer from "./ducks/myOrders";
import companyProfileReducer from "./ducks/companyProfile";
import orderViewReducer from "./ducks/orderView";
import snackbarReducer from "./ducks/snackbar";
import addOrderReducer from "./ducks/addOrder";
import receivedOffersReducer from "./ducks/receivedOffers";
import sentOffersReducer from "./ducks/sentOffers";
import offerViewReducer from "./ducks/offerView";
import notificationsReducer from "./ducks/notifications";
import accountSettingsReducer from "./ducks/accountSettings";
import contactReducer from "./ducks/contact";
import cookieConsentDialogReducer from "./ducks/cookieConsentDialog";
import verifyUserReducer from "./ducks/verifyUser";
import unsubscribedReducer from "./ducks/unsubscribed";
import stripeSuccessReducer from "./ducks/stripeSuccess";
import { reducer as formReducer } from "redux-form";
import conversationsReducer from "./ducks/conversations";
import messagesReducer from "./ducks/messages";
import myExpiredOrdersReducer from "./ducks/myOrders"

export default combineReducers({
  form: formReducer,
  register: registerReducer,
  login: loginReducer,
  auth: authReducer,
  forgot: forgotReducer,
  resetPw: resetPWReducer,
  drawer: drawerReducer,
  search: searchOrdersReducer,
  myOrders: myOrdersReducer,
  companyProfile: companyProfileReducer,
  orderView: orderViewReducer,
  offerView: offerViewReducer,
  snackbar: snackbarReducer,
  addOrder: addOrderReducer,
  receivedOffers: receivedOffersReducer,
  sentOffers: sentOffersReducer,
  notifications: notificationsReducer,
  accountSettings: accountSettingsReducer,
  contact: contactReducer,
  cookieConsent: cookieConsentDialogReducer,
  verifyUser: verifyUserReducer,
  unsubscribed: unsubscribedReducer,
  stripeSuccess: stripeSuccessReducer,
  conversations: conversationsReducer,
  messages: messagesReducer,
  myExpiredOrders: myExpiredOrdersReducer
});
