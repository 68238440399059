export const materials = [
  "Aluminium",
  "Bustahl",
  "Composites",
  "Duroplaste",
  "Edelstahl",
  "Guss",
  "Kupferlegierung",
  "Magnesium",
  "Messing",
  "Stahl",
  "Stahlguss",
  "Ureol",
  "Werkzeugstahl",
];
export const technologies = [
  "3-Achs-CNC-Fräsen",
  "5-Achs-CNC-Fräsen",
  "Abkanten",
  "Additive Fertigung / 3D Druck",
  "Auftragschweißen",
  "Beschichten",
  "Binding Jetting (BJ)",
  "Bohren",
  "Bürsten",
  "Digital Light Processing (DLP)",
  "Direct Metal Laser Sintering (DMLS)",
  "Drechseln",
  "Drehen",
  "Drop on Demond (DOD)",
  "Einpressen",
  "Elektronenstrahl-Schmelzen",
  "Erodieren (EDM)",
  "Falzen",
  "Flachschleifen",
  "Fräsen",
  "Furnierbearbeitung",
  "Fused Deposition Modeling (FDM)",
  "Hobeln",
  "Hohlnieten",
  "Hybrid-Prozesse (HP)",
  "Kaltumformen",
  "Kantenbearbeitung",
  "Kantenleimen",
  "Kleben",
  "Laser",
  "Laser Melting (LM)",
  "Laserschneiden",
  "Laserschweißen",
  "Lasersintern (LS)",
  "Laserstrahl-Schmelzen",
  "Material Binder Jetting (MJ)",
  "Metal Binder Jetting",
  "Multi Jet Fusion (MJF)",
  "Multijet-Technologie",
  "Nieten",
  "Photopolymer Jetting (PJ)",
  "Plasmaschneiden",
  "Polyjet-Technologie",
  "Pressen",
  "Reiben",
  "Richten",
  "Runden/ Biegen",
  "Rundschleifen",
  "Sand Binder Jetting",
  "Scheren",
  "Schleifen",
  "Schweißen",
  "Selektives Lasersintern (SLS)",
  "Selektives Laserstrahl-Schmezlen",
  "Senken",
  "Sicken",
  "Sonstige",
  "Stanzen",
  "Stemmen",
  "Stereolithografie (STL / SLA)",
  "Sägen (Zuschnitt)",
  "Tiefziehen",
  "Ultraschall-Bearbeitung",
  "Voxeljet-Technologie",
  "Walzen",
  "Wasserstrahlschneiden",
  "Werkzeug Schleifen",
];
export const certificates = [
  "AS9100",
  "DIN 18800-7:2008 Klasse A-C (kl. Schweißnachweis)",
  "DIN 18800-7:2008 Klasse D/E (gr. Schweißnachweis)",
  "DIN 4113",
  "DIN 6701",
  "DIN EN 1090",
  "DIN EN ISO 3834",
  "EN 15085",
  "EN 9100",
  "EN ISO 13485",
  "FFL Class 10",
  "FFL Class 6",
  "FFL Class 7",
  "FSC",
  "IATF 16949",
  "ISO 14001",
  "ISO 9001",
  "ISO 9001:2008",
  "ISO 9001:2015",
  "ISO-TS 16949:2002",
  "ISO-TS 16949:2009",
  "JAR 145",
  "OHSAS 18000",
  "PART 145",
  "PEFC",
  "QSF-A",
  "QSF-B",
  "SA 8000",
  "VDA 6.1",
  "VDA 6.2",
];

export const languages = [
  "Bahasa Indonesia",
  "Bahasa Malaysia",
  "Bengali",
  "Dansk",
  "Deutsch",
  "English",
  "Español",
  "Français",
  "Hindi",
  "Italiano",
  "Magyar",
  "Nederlands",
  "Norsk",
  "Polski",
  "Português",
  "Punjabi",
  "Sign Language",
  "Suomi",
  "Svenska",
  "Tagalog",
  "Türkçe",
  "Čeština",
  "Ελληνικά",
  "Русский",
  "українська",
  "עברית",
  "العربية",
  "ภาษาไทย",
  "中文",
  "日本語",
  "한국어",
];
export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const countriesDE = [
  "Afghanistan",
  "Ägypten",
  "Åland-Inseln",
  "Albanien",
  "Algerien",
  "Amerikanisch-Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarktis",
  "Antigua und Barbuda",
  "Äquatorialguinea",
  "Argentinien",
  "Armenien",
  "Aruba",
  "Aserbaidschan",
  "Äthiopien",
  "Australien",
  "Bahamas (die)",
  "Bahrain",
  "Bangladesch",
  "Barbados",
  "Belgien",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivien (Plurinationaler Staat)",
  "Bonaire, Sint Eustatius und Saba",
  "Bosnien und Herzegowina",
  "Botswana",
  "Bouvetinsel",
  "Brasilien",
  "Britisches Territorium im Indischen Ozean (das)",
  "Brunei Darussalam",
  "Bulgarien",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Chile",
  "China",
  "Cookinseln (die)",
  "Costa Rica",
  "Côte d'Ivoire",
  "Curaçao",
  "Dänemark",
  "Demokratische Volksrepublik Laos (die)",
  "Deutschland",
  "Dominica",
  "Dominikanische Republik (die)",
  "Dschibuti",
  "Ecuador",
  "El Salvador",
  "Eritrea",
  "Estland",
  "Eswatini",
  "Falklandinseln (die) [Malwinen]",
  "Färöer Inseln (die)",
  "Fidschi",
  "Finnland",
  "Frankreich",
  "Französisch-Guayana",
  "Französisch-Polynesien",
  "Französische Südterritorien",
  "Gabun",
  "Gambia (die)",
  "Georgien",
  "Ghana",
  "Gibraltar",
  "Grenada",
  "Griechenland",
  "Grönland",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island und McDonald Islands",
  "Heiliger Stuhl (der)",
  "Honduras",
  "Hongkong",
  "Indien",
  "Indonesien",
  "Irak",
  "Iran (Islamische Republik)",
  "Irland",
  "Island",
  "Isle of Man",
  "Israel",
  "Italien",
  "Jamaika",
  "Japan",
  "Jemen",
  "Jersey",
  "Jordanien",
  "Jungferninseln (Britisch)",
  "Jungferninseln (U.S.)",
  "Kaimaninseln (die)",
  "Kambodscha",
  "Kamerun",
  "Kanada",
  "Kasachstan",
  "Katar",
  "Kenia",
  "Kirgisistan",
  "Kiribati",
  "Kokosinseln (Keeling) (die)",
  "Kolumbien",
  "Komoren (die)",
  "Kongo (Demokratische Republik)",
  "Kongo (die)",
  "Kongo (die)",
  "Korea (Demokratische Volksrepublik)",
  "Korea (die Republik)",
  "Kroatien",
  "Kuba",
  "Kuwait",
  "Lesotho",
  "Lettland",
  "Libanon",
  "Liberia",
  "Libyen",
  "Liechtenstein",
  "Litauen",
  "Luxemburg",
  "Macao",
  "Madagaskar",
  "Malawi",
  "Malaysia",
  "Malediven",
  "Mali",
  "Malta",
  "Marokko",
  "Marshallinseln (die)",
  "Martinique",
  "Mauretanien",
  "Mauritius",
  "Mayotte",
  "Mexiko",
  "Mikronesien (Föderierte Staaten von)",
  "Moldawien (die Republik)",
  "Monaco",
  "Mongolei",
  "Montenegro",
  "Montserrat",
  "Mosambik",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Neukaledonien",
  "Neuseeland",
  "Nicaragua",
  "Niederlande (die)",
  "Niger (der)",
  "Nigeria",
  "Niue",
  "Nördliche Marianen (die)",
  "Norfolkinsel",
  "Norwegen",
  "Oman",
  "Österreich",
  "Pakistan",
  "Palästina, Staat",
  "Palau",
  "Panama",
  "Papua-Neuguinea",
  "Paraguay",
  "Peru",
  "Philippinen (die)",
  "Pitcairn",
  "Polen",
  "Portugal",
  "Puerto Rico",
  "Republik Nordmazedonien",
  "Réunion",
  "Ruanda",
  "Rumänien",
  "Russische Föderation (die)",
  "Salomonen",
  "Sambia",
  "Samoa",
  "San Marino",
  "Sao Tome und Principe",
  "Saudi-Arabien",
  "Schweden",
  "Schweiz",
  "Senegal",
  "Serbien",
  "Seychellen",
  "Sierra Leone",
  "Simbabwe",
  "Singapur",
  "Sint Maarten (niederländischer Teil)",
  "Slowakei",
  "Slowenien",
  "Somalia",
  "Spanien",
  "Sri Lanka",
  "St. Barthélemy",
  "St. Helena, Ascension und Tristan da Cunha",
  "St. Kitts und Nevis",
  "St. Lucia",
  "St. Martin (französischer Teil)",
  "St. Pierre und Miquelon",
  "St. Vincent und die Grenadinen",
  "Südafrika",
  "Sudan (der)",
  "Südgeorgien und die Südlichen Sandwichinseln",
  "Südsudan",
  "Surinam",
  "Svalbard und Jan Mayen",
  "Syrische Arabische Republik",
  "Tadschikistan",
  "Taiwan",
  "Tansania, Vereinigte Republik",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad und Tobago",
  "Tschad",
  "Tschechien",
  "Tunesien",
  "Türkei",
  "Turkmenistan",
  "Turks- und Caicosinseln (die)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "Ungarn",
  "United States Minor Outlying Islands (the)",
  "Uruguay",
  "Usbekistan",
  "Vanuatu",
  "Venezuela (Bolivarische Republik)",
  "Vereinigte Arabische Emirate (die)",
  "Vereinigte Staaten von Amerika (die)",
  "Vereinigtes Königreich von Großbritannien und Nordirland (the)",
  "Vietnam",
  "Wallis und Futuna",
  "Weihnachtsinsel",
  "Weißrussland",
  "Westsahara",
  "Zentralafrikanische Republik (die)",
  "Zypern",
];
