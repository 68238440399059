import React from "react";
import { Field, reduxForm } from "redux-form";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";

import LoadingButton from "../../LoadingButton";
import { resetPW, cleanUp } from "../../../redux/ducks/resetPW";
import { openSnack } from "../../../redux/ducks/snackbar";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: "100%",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid",
    borderColor: "#cccccc",
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const renderTextField = ({
  label,
  input,
  meta,
  meta: { touched, invalid, error },
  ...custom
}) => {
  //   console.log(meta);
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );
};

const ResetPWForm = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, loaded, error } = useSelector((s) => s.resetPw);
  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, error]);
  React.useEffect(() => {
    if (!!loaded) {
      dispatch(
        openSnack(
          "Passwort erfolgreich geändert, eine Bestätigungs-E-Mail wurde an Ihre E-Mail-Adresse gesendet."
        )
      );
      dispatch(cleanUp());
    }
  }, [dispatch, loaded]);
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={props.handleSubmit((values) => {
        dispatch(resetPW(values));
      })}
      className={classes.form}
    >
      <div style={{ marginTop: "1em" }}>
        <div>
          <Field
            name="password"
            component={renderTextField}
            label="Passwort*"
            variant="outlined"
            className={classes.textField}
            type="password"
            autoComplete="new-password"
            // error={props.state.login.invalid}
            fullWidth
          />
        </div>
        <div>
          <Field
            name="confirm"
            component={renderTextField}
            label="Passwort bestätigen*"
            variant="outlined"
            className={classes.textField}
            type="password"
            autoComplete="new-password"
            // error={props.state.login.invalid}
            fullWidth
          />
        </div>
        <div className={classes.buttonSection}>
          <LoadingButton
            type="submit"
            success={loaded}
            loading={loading}
            label="Neues Passwort festlegen"
            successLabel="Das neue Passwort ist nun festgelegt."
            fullWidth
          />
        </div>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["password", "confirm"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Pflichtfeld";
    }
  });

  if (values["password"] !== values["confirm"]) {
    errors["confirm"] = "Die Passwörter stimmen nicht überein!";
  }

  if (!values.password?.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)) {
    errors.password =
      "Ihr Passwort muss 6-20 Zeichen, mindestens einen Großbuchstaben, mindestens einen Kleinbuchstaben, und mindestens eine Ziffer enthalten.";
  }

  // adds token to values
  const path = window.location.pathname.split("/");
  const token = path[path.length - 1];
  values.token = token;
  return errors;
};

export default reduxForm({
  form: "ResetPWForm", // a unique identifier for this form
  validate,
})(ResetPWForm);
