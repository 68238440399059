import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateCompany } from "../../../redux/ducks/stripeSuccess";
import Link from "../../shared/Link";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function StripeSuccess() {
  const dispatch = useDispatch();
  const { loading, loaded, error } = useSelector((s) => s.stripeSuccess);

  let query = useQuery();
  const sessionId = query.get("session_id");

  React.useEffect(() => {
    dispatch(updateCompany(sessionId));
  }, [dispatch, sessionId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
      }}
    >
      {loading && <CircularProgress />}

      {loaded && (
        <Alert severity="success" sx={{ maxWidth: "60em" }}>
          <Typography>
            Zahlung erfolgreich, willkommen bei Order-Scout! Sie können jetzt
            unsere Dienste im <Link to="/companies/my">Dashboard</Link> nutzen.
          </Typography>
        </Alert>
      )}
      {loaded && (
        <img
          src="/clipArt/happy_announcement.svg"
          style={{ maxWidth: "18em" }}
          alt=""
        />
      )}
      {error && (
        <Alert severity="error" sx={{ maxWidth: "60em" }}>
          <Typography>{error.message}</Typography>
        </Alert>
      )}
    </div>
  );
}

export default StripeSuccess;
