import React from "react";
import SubArticle from "./SubArticle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { openCookieDialog } from "../../redux/ducks/cookieConsentDialog";

function B3to4() {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <SubArticle title="(3) Zweck und Rechtsgrundlage der Datenverarbeitung">
        <Typography>
          Wir verarbeiten die vorstehend näher bezeichneten personenbezogenen
          Daten in Einklang mit den Vorschriften der DSGVO, den weiteren
          einschlägigen Datenschutzvorschriften und nur im erforderlichen
          Umfang. Soweit die Verarbeitung der personenbezogenen Daten auf Art. 6
          Abs. 1 S. 1 lit. f DSGVO beruht, stellen die genannten Zwecke zugleich
          unsere berechtigten Interessen dar. Die Verarbeitung der
          Protokolldaten dient statistischen Zwecken und der Verbesserung der
          Qualität unserer Webseite, insbesondere der Stabilität und der
          Sicherheit der Verbindung (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit.
          f DSGVO). Die Verarbeitung von Kontaktformulardaten erfolgt zur
          Bearbeitung von Kundenanfragen (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1
          lit. b oder lit. f DSGVO). Die Verarbeitung der Newsletterdaten
          erfolgt zum Zweck der Zusendung des Newsletters. Im Rahmen der
          Anmeldung zu unserem Newsletter willigen Sie in die Verarbeitung Ihrer
          personenbezogenen Daten ein (Rechtsgrundlage ist Art. 6 Abs. 1 lit. a
          DSGVO). Für die Anmeldung zu unserem Newsletter verwenden wir das sog.
          Double-Opt-In-Verfahren. Das heißt, dass wir Ihnen nach Ihrer
          Anmeldung eine E-Mail an die angegebene E-Mail-Adresse senden, in
          welcher wir Sie um Bestätigung bitten, dass Sie den Versand des
          Newsletters wünschen. Zweck dieses Verfahrens ist, Ihre Anmeldung
          nachweisen und ggf. einen möglichen Missbrauch Ihrer persönlichen
          Daten aufklären zu können. Ihre Einwilligung in die Übersendung des
          Newsletters können Sie jederzeit widerrufen und den Newsletter
          abbestellen. Den Widerruf können Sie durch Klick auf den in jeder
          Newsletter-E-Mail bereitgestellten Link, per E-Mail an [E-Mailadresse
          des Unternehmens] oder durch eine Nachricht an die im Impressum
          angegebenen Kontaktdaten erklären.
        </Typography>
      </SubArticle>
      <SubArticle title="(4) Dauer der Datenverarbeitung">
        <Typography>
          Ihre Daten werden nur so lange verarbeitet, wie dies für die
          Erreichung der oben genannten Verarbeitungszwecke erforderlich ist;
          hierfür gelten die im Rahmen der Verarbeitungszwecke angegebenen
          Rechtsgrundlagen entsprechend. Hinsichtlich der Nutzung und der
          Speicherdauer von Cookies beachten Sie bitte Punkt A.(5) sowie die{" "}
          <Button
            sx={{ padding: 0 }}
            onClick={() => {
              dispatch(openCookieDialog());
            }}
          >
            <Typography sx={{ textTransform: "none" }}>
              Cookie-Richtlinie
            </Typography>
          </Button>
          . Von uns eingesetzte Dritte werden Ihre Daten auf deren System so
          lange speichern, wie es im Zusammenhang mit der Erbringung der
          Leistungen für uns entsprechend dem jeweiligen Auftrag erforderlich
          ist. Näheres zur Speicherdauer finden Sie im Übrigen unter A.(5) und
          der{" "}
          <Button
            sx={{ padding: 0 }}
            onClick={() => {
              dispatch(openCookieDialog());
            }}
          >
            <Typography sx={{ textTransform: "none" }}>
              Cookie-Richtlinie
            </Typography>
          </Button>
          .
        </Typography>
      </SubArticle>
    </React.Fragment>
  );
}

export default B3to4;
