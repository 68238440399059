import React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

function InnerContainer(props) {
  return (
    <Grid container style={{ marginBottom: "3rem" }}>
      <Grid item md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={3} style={{ padding: "1rem" }}>
          {props.children}
        </Paper>
      </Grid>
      <Grid item md={2} />
    </Grid>
  );
}

export default InnerContainer;
