import { companiesAPI } from "../../apis/companies";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  company: null,
  editDialog: {
    open: false,
    loading: false,
    loaded: false,
    error: false,
  },
  updateLogoDialog: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const loading = "companies/profile/loading";
const loaded = "companies/profile/loaded";
const error = "companies/profile/error";
const editOpen = "companies/profile/editDialog/open";
const editClose = "companies/profile/editDialog/close";
const editLoading = "companies/profile/editDialog/loading";
const editLoaded = "companies/profile/editDialog/loaded";
const editError = "companies/profile/editDialog/error";
const cleanEdit = "companies/profile/editDialog/clean";
const updateLogoLoading = "companies/profile/updateLogoDialog/loading";
const updateLogoLoaded = "companies/profile/updateLogoDialog/loaded";
const updateLogoError = "companies/profile/updateLogoDialog/error";
const cleanUpdateLogo = "companies/profile/updateLogoDialog/clean";

export const getCompanyProfile = (id) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await companiesAPI.get(`/${id}`);
    if (response.data.status === "success") {
      dispatch({
        type: loaded,
        payload: { company: response.data.data.company },
      });
    } else {
      dispatch({
        type: error,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: error,
      payload: e.response?.data || e,
    });
  }
};

export const updateProfile = (values) => async (dispatch) => {
  dispatch({ type: editLoading });
  const formattedValues = {
    address: {
      street: {
        streetName: values.streetName,
        streetNum: values.streetNum,
      },
      province: values.province || null,
      city: values.city,
      zip: values.zip,
      country: values.country,
    },
    website: values.website || null,
    phoneNumber: values.phoneNumber || null,
    faxNumber: values.faxNumber || null,
  };

  try {
    const response = await companiesAPI.patch("/my", formattedValues);
    console.log(response);
    if (response.data.status === "success") {
      dispatch({
        type: editLoaded,
        payload: response.data.data.updatedCompany,
      });
      dispatch({
        type: "auth/update",
        payload: { company: response.data.data.updatedCompany },
      });
    } else {
      dispatch({ type: editError, payload: response.data.error });
    }
  } catch (error) {
    dispatch({ type: editError, payload: error.response.data });
  }
};
export const openEditDialog = () => ({ type: editOpen });
export const closeEditDialog = () => ({ type: editClose });
export const cleanUp = () => ({ type: cleanEdit });
export const cleanUpUpdateLogo = () => ({ type: cleanUpdateLogo });

export const updateLogo = (formValues) => async (dispatch) => {
  dispatch({ type: updateLogoLoading });
  const getFormData = (values) => {
    const formData = new FormData();

    const logoArr = values.newLogo;
    if (logoArr && logoArr.length) {
      formData.append("newLogo", logoArr[0]);
    }
    return formData;
  };
  const data = getFormData(formValues);
  try {
    const response = await companiesAPI.patch("/my/logo", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.data.status === "success") {
      dispatch({
        type: updateLogoLoaded,
        payload: { logo: response.data.data.newLogo },
      });
    } else {
      dispatch({ type: updateLogoError, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: updateLogoError, payload: e.response.data });
  }
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, loading: true };
    case loaded:
      const company = action.payload.company;
      return {
        ...state,
        loading: false,
        loaded: true,
        company: company,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    case editOpen:
      return {
        ...state,
        editDialog: {
          ...state.editDialog,
          open: true,
        },
      };
    case editClose:
      return {
        ...state,
        editDialog: {
          ...state.editDialog,
          open: false,
        },
      };
    case editLoading:
      return {
        ...state,
        editDialog: {
          ...state.editDialog,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case editLoaded:
      return {
        ...state,
        company: { ...state.company, ...action.payload },
        editDialog: {
          ...state.editDialog,
          loading: false,
          loaded: true,
          error: null,
          open: false,
        },
      };
    case editError:
      return {
        ...state,
        editDialog: {
          ...state.editDialog,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case cleanEdit:
      return {
        ...state,
        editDialog: {
          ...state.editDialog,
          ...initialState.editDialog,
        },
      };
    case updateLogoLoading:
      return {
        ...state,
        updateLogoDialog: {
          ...state.updateLogoDialog,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case updateLogoLoaded:
      return {
        ...state,
        company: { ...state.company, ...action.payload },
        updateLogoDialog: {
          ...state.updateLogoDialog,
          loading: false,
          loaded: true,
          error: null,
          open: false,
        },
      };
    case updateLogoError:
      return {
        ...state,
        updateLogoDialog: {
          ...state.updateLogoDialog,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };
    case cleanUpdateLogo:
      return {
        ...state,
        updateLogoDialog: {
          ...state.updateLogoDialog,
          ...initialState.updateLogoDialog,
        },
      };

    default:
      return state;
  }
};

export default profileReducer;
