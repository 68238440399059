import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import Link from "../../shared/Link";
import Divider from "@mui/material/Divider";
import FilesList from "../../OrderView/FilesList";

import ViewTable from "./ViewTable";
import {
  openAcceptDialog,
  openRejectDialog,
} from "../../../redux/ducks/offerView";
import RejectOfferDialog from "./RejectOfferDialog";
import AcceptDialog from "./AcceptDialog";
import ConditionalWrapper from "../../shared/ConditionalWrapper";
import formatNum from "../../../utils/formatNum";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
  paper: {
    padding: theme.spacing(2),
  },
  buttonsSection: {
    marginTop: theme.spacing(2),
  },
  offerSection: {
    marginBottom: theme.spacing(1),
  },
}));

const OfferDetails = ({ offer }) => {
  const classes = useStyles();
  let offerData = [];
  if (offer) {
    offerData = [
      ...offerData,
      {
        key: "Angebot Status",
        value: offer.status.charAt(0).toUpperCase() + offer.status.slice(1),
      },
      {
        key: "Preis",
        value: "€" + formatNum(offer.price),
      },
      {
        key: "Liefertermin",
        value: moment(offer.deliveryDate).format("DD/MM/YYYY"),
      },
      {
        key: "Nachricht",
        value: offer.message,
      },
      {
        key: "Beigefügte Dateien",
        value: <FilesList parent={offer} />,
      },
    ];
  }

  const handleAcceptDialogOpen = () => {
    dispatch(openAcceptDialog());
  };
  const handleRejectDialogOpen = () => {
    dispatch(openRejectDialog());
  };
  const dispatch = useDispatch();
  const offerState = useSelector((state) => state.offerView);
  React.useEffect(() => {}, [offerState.rejectDialog.loaded]);
  const { _id: authCompany } = useSelector((state) => state.auth.company);
  const receivingCompany = offerState.offer.receivingCompany._id;
  const isNotOpen = !(offer.status === "offen") || false;
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        direction="column"
        spacing={1}
        className={classes.offerSection}
      >
        <Grid item className={classes.title}>
          {"Angebotsdetails und Aktionen"}
        </Grid>
        <Grid item maxWidth={"100%"}>
          <ViewTable data={offerData} exists />
          {/* <ViewTableModified data={offerData} e/xists /> */}
        </Grid>
      </Grid>
      <Divider />
      {authCompany === receivingCompany && (
        <Grid
          item
          container
          justifyContent="flex-end"
          spacing={1}
          className={classes.buttonsSection}
        >
          <Grid item>
            <ConditionalWrapper
              condition={isNotOpen}
              wrapper={(children) => (
                <Tooltip title="Sie haben dieses Angebot bereits akzeptiert oder abgelehnt.">
                  <span>{children}</span>
                </Tooltip>
              )}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAcceptDialogOpen}
                disabled={!(offer.status === "offen")}
              >
                {"Akzeptieren"}
              </Button>
            </ConditionalWrapper>
          </Grid>
          <Grid item>
            <ConditionalWrapper
              condition={isNotOpen}
              wrapper={(children) => (
                <Tooltip title="Sie haben dieses Angebot bereits akzeptiert oder abgelehnt.">
                  <span>{children}</span>
                </Tooltip>
              )}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleRejectDialogOpen}
                disabled={!(offer.status === "offen")}
              >
                {"Ablehnen"}
              </Button>
            </ConditionalWrapper>
          </Grid>
        </Grid>
      )}
      <AcceptDialog offer={offer} />
      <RejectOfferDialog offer={offer} />
    </Paper>
  );
};

export default OfferDetails;
