import { io } from "socket.io-client";

const serverUrl =
  process.env.NODE_ENV === "production"
    ? window.location.origin
    : "http://localhost:5005";

const socket = io(serverUrl, { autoConnect: false, withCredentials: true });
// socket.on("new-notification", (n) => {
//   console.log("heard notif from helper");
// });

export default socket;
