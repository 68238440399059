import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import LogoWhite from "../LogoWhite";

import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { logout } from "../../redux/ducks/auth";

const useStyles = makeStyles((theme) => ({
  blackAppBar: {
    backgroundColor: theme.palette.common.black,
  },
  transparentAppBar: {
    boxShadow: "none",
    color: "white",
    backgroundColor: "transparent",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textDecoration: "none",
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
    // textDecoration:"none",
    color: "white",
    "&:hover": {
      color: "rgb(250,250,200)",
      textDecoration: "none",
    },
  },
  whiteButton: {
    color: "white",
    borderColor: "white",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  leftMenu: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
    marginLeft: theme.spacing(3),
  },
  leftMenuItem: {
    textDecoration: "none",
    marginLeft: theme.spacing(2),
    color: "white",
    minWidth: 10,
    // textTransform: "none",
    fontSize: "0.9rem",
  },
  mobileMenuItem: {
    textDecoration: "none",
    // marginLeft:theme.spacing(2),
    color: "black",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

const leftMenuTabs = [
  { label: "Einkäufer", to: "/" },
  { label: "Fertiger", to: "/manufacturer" },
  { label: "Kontakt", to: "/contact" },
  { label: "Über Uns", to: "/about" },
];

export const MUINav = (props) => {
  const [isTransparent, setTransparent] = React.useState(false);
  const [tabsValue, setTabsValue] = React.useState(0);
  let location = useLocation();
  React.useEffect(() => {
    const transparentPages = ["", "manufacturer"];
    // checks location every time page loads, then checks if it is static, then updates state
    if (
      transparentPages.includes(
        location.pathname.toLowerCase().toString().split("/")[1]
      )
    ) {
      setTransparent(true);
    } else {
      setTransparent(false);
    }
    switch (location.pathname.toLowerCase().toString().split("/")[1]) {
      case "":
        if (tabsValue !== 0) {
          setTabsValue(0);
        }
        break;
      case "manufacturer":
        if (tabsValue !== 1) {
          setTabsValue(1);
        }
        break;
      case "contact":
        if (tabsValue !== 2) {
          setTabsValue(2);
        }
        break;
      case "about":
        if (tabsValue !== 3) {
          setTabsValue(3);
        }
        break;
      default:
        if (tabsValue !== false) {
          setTabsValue(false);
        }
        return;
    }
  }, [location, isTransparent, tabsValue]);

  const { isAuthenticated } = props.auth;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleTabsChange = (e, value) => {
    setTabsValue(value);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link className={classes.mobileMenuItem} to="/manufacturer">
        <MenuItem>
          <Typography variant="body1">{"Fertiger"}</Typography>
        </MenuItem>
      </Link>

      <Link className={classes.mobileMenuItem} to="/contact">
        <MenuItem>
          <Typography variant="body1">{"Kontakt"}</Typography>
        </MenuItem>
      </Link>

      <Link className={classes.mobileMenuItem} to="/about">
        <MenuItem>
          <Typography variant="body1">{"Über Uns"}</Typography>
        </MenuItem>
      </Link>

      {!isAuthenticated && (
        <Link className={classes.mobileMenuItem} to="/login">
          <MenuItem>
            <Typography variant="body1">{"Einloggen"}</Typography>
          </MenuItem>
        </Link>
      )}
      {!isAuthenticated && (
        <Link className={classes.mobileMenuItem} to="/register">
          <MenuItem>
            <Typography variant="body1">{"Registrieren"}</Typography>
          </MenuItem>
        </Link>
      )}
      {isAuthenticated && (
        <MenuItem
          onClick={() => {
            props.logout();
          }}
        >
          <Typography variant="body1">{"Abmelden"}</Typography>
        </MenuItem>
      )}
      {isAuthenticated && (
        <Link className={classes.mobileMenuItem} to="/companies/my">
          <MenuItem>
            <Typography variant="body1">{"Dashboard"}</Typography>
          </MenuItem>
        </Link>
      )}
    </Menu>
  );
  return (
    <AppBar
      className={
        !isTransparent ? classes.blackAppBar : classes.transparentAppBar
      }
      position="static"
    >
      <Container>
        <Toolbar disableGutters>
          <Button to="/" className={classes.title} component={Link}>
            <LogoWhite />
          </Button>
          <Tabs
            value={tabsValue}
            onChange={handleTabsChange}
            className={classes.leftMenu}
            indicatorColor="primary"
          >
            {leftMenuTabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={tab.label}
                  className={classes.leftMenuItem}
                  component={Link}
                  to={tab.to}
                />
              );
            })}
          </Tabs>

          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {!isAuthenticated && (
              <Link className={classes.leftMenuItem} to="/login">
                <Button className={classes.whiteButton}>{"Einloggen"}</Button>
              </Link>
            )}
            {!isAuthenticated && (
              <Link className={classes.leftMenuItem} to="/register">
                <Button variant="outlined" className={classes.whiteButton}>
                  {"Registrieren"}
                </Button>
              </Link>
            )}
            {isAuthenticated && (
              <Button
                className={classes.whiteButton}
                onClick={() => {
                  props.logout();
                }}
              >
                {"Abmelden"}
              </Button>
            )}
            {isAuthenticated && (
              <Link className={classes.leftMenuItem} to="/companies/my">
                <Button variant="outlined" className={classes.whiteButton}>
                  Dashboard
                </Button>
              </Link>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </Container>
      {renderMobileMenu}
      {renderMenu}
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth;
  return { auth };
};

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MUINav);
