import React from "react";
import ChangePassForm from "./Form";

// MUI
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

function ChangePassSection() {
  return (
    <Paper
      sx={{
        p: 2,
        width: "100%",
      }}
    >
      <Typography variant={"h5"}>Passwort ändern</Typography>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "600px" }}>
          <ChangePassForm />
        </Box>
      </Box>{" "}
    </Paper>
  );
}

export default ChangePassSection;
