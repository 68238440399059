import React from "react";
import Form from "./Form";
import { openSnack } from "../../../redux/ducks/snackbar";
import { cleanUpUpdateLogo } from "../../../redux/ducks/companyProfile";
import { useSelector, useDispatch } from "react-redux";
// MUI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

const UpdateLogoDialog = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { loaded, loading, error } = useSelector(
    (s) => s.companyProfile.updateLogoDialog
  );
  React.useEffect(() => {
    if (error) {
      dispatch(
        openSnack(
          !!error.message
            ? error.message
            : "Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht bearbeitet werden",
          "error"
        )
      );
      dispatch(cleanUpUpdateLogo());
    }
  }, [dispatch, error]);
  React.useEffect(() => {
    if (!!loaded) {
      dispatch(openSnack("Ihr Firmenlogo wurde erfolgreich aktualisiert"));
      dispatch(cleanUpUpdateLogo());
      setOpen(false);
    }
  }, [loaded, dispatch, setOpen]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Logo aktualisieren</DialogTitle>
      <Form
        closeDialog={() => {
          setOpen(false);
        }}
        loading={loading}
      />
    </Dialog>
  );
};

export default UpdateLogoDialog;
