import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import ExternalLink from "../shared/ExternalLink";

const useStyles = makeStyles((theme) => ({
  pushRight: { paddingLeft: "1em" },
}));

const FilesList = ({ parent }) => {
  const classes = useStyles();
  const files = parent.files;
  return (
    <ul className={files.length ? classes.pushRight : null}>
      {!!files.length
        ? files.map((file, index) => {
            return (
              <li key={index}>
                <ExternalLink
                  to={
                    process.env.NODE_ENV === "production"
                      ? `/files/${file.name}`
                      : "http://localhost:5005/files/" + file.name
                  }
                  // download
                >
                  {file.originalName}
                </ExternalLink>
              </li>
            );
          })
        : "-"}
    </ul>
  );
};
export default FilesList;
