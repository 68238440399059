import { usersAPI } from "../../apis/users";
const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

const loading = "users/verify/loading";
const loaded = "users/verify/loaded";
const error = "users/verify/error";

export const verifyUser = (token) => async (dispatch) => {
  dispatch({ type: loading });
  try {
    const response = await usersAPI.post(`/verify/${token}`);
    if (response.data.status === "success") {
      dispatch({ type: loaded });
    } else {
      dispatch({ type: error, payload: response.data });
    }
  } catch (e) {
    dispatch({
      type: error,
      payload: e.response?.data || e,
    });
  }
};

const verifyUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case loaded:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default verifyUserReducer;
