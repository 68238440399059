import React from "react";
import StepBox from "./StepBox";

// MUI
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Icons
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import HandshakeIcon from "./HandshakeIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.seashell,
  },
  // h2: {
  //   fontWeight: "400",
  //   fontSize: "3rem",
  //   letterSpacing: "0.25rem",
  // },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  steps: {
    margin: "2em 0",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  bottomText: {
    // fontWeight: "bold",
    // fontSize: "1.5rem",
  },
}));

function HowItWorksSection({ manufacturer }) {
  let steps = [];

  if (!manufacturer) {
    steps = [
      {
        num: 1,
        icon: <DescriptionOutlinedIcon />,
        text: "Beschreiben Sie kurz Ihr Projekt.",
      },
      {
        num: 2,
        icon: <PublishOutlinedIcon />,
        text: "Geben Sie die Werkstückdaten ein und laden weitere Dokumente hoch.",
      },
      {
        num: 3,
        icon: <VisibilityOutlinedIcon />,
        text: "Sofort erscheint Ihre Anfrage auf Order-Scout – sichtbar in Echtzeit für alle Nutzer in den von Ihnen ausgewählten Regionen.",
      },
      {
        num: 4,
        icon: <PeopleOutlinedIcon />,
        text: "Anhand Ihrer Anforderungen werden die auf Order-Scout eingehenden Express-Angebote vorsortiert und gehen Ihnen vertraulich zu.",
      },
      {
        num: 5,
        icon: <HandshakeIcon />,
        text: "Sie vergeben den Auftrag an den favorisierten Anbieter oder orientieren sich anderweitig.",
      },
    ];
  } else {
    steps = [
      {
        num: 1,
        icon: <DescriptionOutlinedIcon />,
        text: "Registrieren Sie Ihr Konto bei Order-Scout als Fertiger.",
      },
      {
        num: 2,
        icon: <SearchIcon />,
        text: "Finden Sie die Fertigungsaufträge, die Ihren Bedürfnissen und Fähigkeiten entsprechen.",
      },
      {
        num: 3,
        icon: <SendIcon />,
        text: "Wenn Sie den Auftrag gefunden haben, schicken Sie dem Einkäufer ein Angebot auf der Order-Scout-Plattform.",
      },
      {
        num: 4,
        icon: <VisibilityOutlinedIcon />,
        text: "Der Einkäufer kann Ihr Angebot in Echtzeit einsehen und wenn Ihr Angebot ihm zusagt, stimmt der Einkäufer diesem zu.",
      },
      {
        num: 5,
        icon: <HandshakeIcon />,
        text: "Sobald die Vereinbarung mit dem Einkäufer abgeschlossen ist, führen Sie den Auftrag aus. In unserem internen CHAT System können Sie sich mit dem Einkäufer direkt austauschen um Details abzustimmen.",
      },
    ];
  }
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h2" color="primary">
          {"So funktioniert’s"}
        </Typography>

        <Grid
          container
          className={classes.steps}
          justifyContent="center"
          alignItems="center"
        >
          {steps.map((step) => {
            return (
              <StepBox
                key={step.num}
                num={step.num}
                icon={step.icon}
                text={step.text}
              />
            );
          })}
        </Grid>

        <Typography
          color="primary"
          variant="h5"
          component={"p"}
          className={classes.bottomText}
        >
          {!manufacturer &&
            "Und das Beste daran: Sie können alle Angebote in Ruhe vergleichen. Sie bleiben anonym und unabhängig. Ihnen entstehen keine versteckten Kosten."}
        </Typography>
      </Container>
    </section>
  );
}

export default HowItWorksSection;
