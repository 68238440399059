import { ordersAPI } from "../../apis/orders";

const loading = "orders/myOrders/loading";
const loaded = "orders/myOrders/loaded";
const error = "orders/myOrders/error";
const tabChange = "orders/myOrders/activeTab/changed";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  orders: {
    all: [],
    published: [],
    allocated: [],
    archived: [],
  },
  activeTab: 0,
  selectedOrders: [],
  // ordersAPI: [],
};

export const getOrders = (company) => async (dispatch) => {
  dispatch({ type: loading });
  const params = { company };
  try {
    const response = await ordersAPI.get("/", { params });
    if (response.data.status === "success") {
      dispatch({
        type: loaded,
        payload: response.data.orders,
      });
    } else {
      dispatch({
        type: error,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: error,
      payload: err.response.data,
    });
  }
};
export const getExpiredOrders = (company) => async (dispatch) => {
  dispatch({ type: loading });
  const params = { company };
  try {
    const response = await ordersAPI.get("/?status=expired", { params });
    if (response.data.status === "success") {
      dispatch({
        type: loaded,
        payload: response.data.orders,
      });
    } else {
      dispatch({
        type: error,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: error,
      payload: err.response.data,
    });
  }
};
export const setActiveTab = (tabNumber) => (dispatch) => {
  dispatch({ type: tabChange, payload: { tabNumber } });
};

const myOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, loading: true };
    case loaded:
      const allOrders = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        orders: {
          all: allOrders,
          published: allOrders.filter((order) => order.status === "published"),
          allocated: allOrders.filter((order) => order.status === "allocated"),
          archived: allOrders.filter((order) => order.status === "archived"),
        },
        selectedOrders: allOrders,
        // ordersAPI: action.payload,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    case tabChange:
      switch (action.payload.tabNumber) {
        case 1:
          state.selectedOrders = state.orders.published;
          break;
        case 2:
          state.selectedOrders = state.orders.allocated;
          break;
        case 3:
          state.selectedOrders = state.orders.archived;
          break;
        default:
          state.selectedOrders = state.orders.all;
      }
      return {
        ...state,
        activeTab: action.payload.tabNumber,
      };
    default:
      return state;
  }
};

const myExpiredOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case loading:
      return { ...state, loading: true };
    case loaded:
      const allOrders = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        orders: {
          all: allOrders,
        },
        selectedOrders: allOrders,
        // ordersAPI: action.payload,
      };
    case error:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default myOrdersReducer;
