import React from "react";

function NorthEastIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="rgba(0, 0, 0, 0.54)"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z" />
    </svg>
  );
}

export default NorthEastIcon;
