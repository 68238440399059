import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  rootFullwidth: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  wrapperFullwidth: {
    margin: theme.spacing(1),
    position: "relative",
    width: "100%",
  },
  buttonSuccess: {
    backgroundColor: "green !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonError: {
    backgroundColor: "#b71c1c !important",
    color: "white !important",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CircularIntegration(props) {
  const {
    loading,
    success,
    handleSubmitClick = () => {},
    type,
    label,
    successLabel,
    error = false,
    fullWidth,
  } = props;
  const classes = useStyles();
  const handleButtonClick = () => {
    handleSubmitClick();
  };

  return (
    <div className={fullWidth ? classes.rootFullwidth : classes.root}>
      <div className={fullWidth ? classes.wrapperFullwidth : classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || success}
          onClick={handleButtonClick}
          type={type}
          className={
            success ? classes.buttonSuccess : error ? classes.buttonError : null
          }
          fullWidth
        >
          {error ? (
            <div>{error || "Process failed, try again?"}</div>
          ) : success ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckIcon />
              {successLabel}
            </div>
          ) : (
            <span>{label} </span>
          )}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
}
