import Box from "@mui/material/Box";
import React from "react";
import ChatBoxBottom from "./ChatBoxBottom";
import ChatBoxTop from "./ChatBoxTop";

function Chatbox() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        m: 1,
        mt: 0,
        p: 1,
      }}
    >
      <ChatBoxTop />
      <ChatBoxBottom />
    </Box>
  );
}

export default Chatbox;
