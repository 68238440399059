import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { sendMessage } from "../../../redux/ducks/messages";

const ChatBoxBottom = () => {
  const dispatch = useDispatch();
  const currentConvId = useSelector(
    (s) => s.conversations.currentConversationId
  );
  const [message, setMessage] = React.useState("");
  const handleMessageChange = (e) => {
    const msg = e.target.value;
    setMessage(msg);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: don't send if msg is empty
    if (message.trim()) {
      dispatch(
        sendMessage({
          conversation: currentConvId,
          type: "text",
          body: message,
        })
      );
      setMessage("");
    }
  };

  return (
    <form onSubmit={handleSubmit} autoComplete={"off-no-autofill"}>
      <Grid container alignItems="center" spacing={1}>
        <Grid xs={9} lg={10} item>
          <TextField
            autoComplete="off"
            id="new-message"
            name="new-message"
            value={message}
            onChange={handleMessageChange}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid xs={3} lg={2} item>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "100%" }}
            disabled={!message.trim() || !currentConvId}
          >
            Senden
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChatBoxBottom;
