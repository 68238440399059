import { usersAPI } from "../../apis/users";
import { reset } from "redux-form";

const initialState = {
  changePass: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const cpLoading = "users/accountSettings/changePass/loading";
const cpLoaded = "users/accountSettings/changePass/loaded";
const cpError = "users/accountSettings/changePass/error";
const cpClean = "users/accountSettings/changePass/cleanUp";

export const changePass = (values) => async (dispatch) => {
  dispatch({ type: cpLoading });
  try {
    const response = await usersAPI.post("/changePass", values);
    if (response.data.status === "success") {
      dispatch({ type: cpLoaded });
      dispatch(reset("ChangePassForm"));
    } else {
      dispatch({ type: cpError, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: cpError, payload: e.response?.data || e });
  }
};

export const cleanUp = (form) => {
  if (form === "cp") {
    return { type: cpClean };
  }
};

const accountSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case cpLoading:
      return {
        ...state,
        changePass: {
          ...state.changePass,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case cpLoaded:
      return {
        ...state,
        changePass: {
          ...changePass,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case cpError:
      return {
        ...state,
        changePass: {
          ...changePass,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };

    case cpClean:
      return { ...state, changePass: { ...initialState.changePass } };

    default:
      return state;
  }
};

export default accountSettingsReducer;
